<script setup>
import UsersDataTable from "@/components/investment_companies/InvestmentCompanyDataTable.vue";
import { onMounted, ref, toRaw } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
const $loading = useLoading(loaderData);
import { useRouter } from "vue-router";
const router = useRouter();

const errors = ref([]);
const headers = ref([
  {
    title: "Title",
    key: "title",
    sortable: true,
  },
  {
    title: "Description",
    key: "description",
    sortable: true,
  },
  {
    title: "Notes",
    key: "notes",
    sortable: true,
  },
  {
    title: "Address",
    key: "address",
    sortable: true,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const investment_companies = ref([]);
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const filterInfo = ref({});
const editedItem = ref({});

onMounted(async () => {
  canSeeCreateBtn();
});

const getInvestmentCompanies = async (payload = null) => {
  let url = `/investment_companies?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (payload.role && payload.role.trim() !== "") {
    url += `&role=${payload.role}`;
  }
  if (payload.status == 1 || payload.status == 0) {
    url += `&status=${payload.status}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        investment_companies.value = response.data.investment_companies;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getInvestmentCompanies(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getInvestmentCompanies(filterInfo.value);
};

const editItem = async (item) => {
  console.log('item', item)
  router.push({ name: "investment-companies-id", params: { id: item.id } });
};

const deleteItem = (item) => {
  const { ...newItem } = item;
  editedItem.value = {
    ...newItem
  };
  deleteDialog.value = true;
};

const viewItem = (item) => {
  router.push({ name: "investment-companies-view-id", params: { id: item.id } });
};

const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/investment_companies/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        getInvestmentCompanies(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const openCreateModel = async () => {
  router.push({ name: "investment-companies-create"});
};

const canSeeCreateBtn = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Add Investment Company")
  );
};

// expose errors and getInvestmentCompanies
defineExpose({
  errors,
  getInvestmentCompanies,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">
      <InvestmentCompanyDataTable
        :headers="headers"
        :table-data="investment_companies"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @viewItem="viewItem"
        @openCustomFieldsModal="openCustomFieldsModal"
      />
    </VCardText>
  </VCard>

  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />
</template>
