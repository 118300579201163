<script setup>
import RepresentativeDataTable from "@/components/representatives/RepresentativeDataTable.vue";
import { onMounted, ref } from "vue";
import axios from "@/utils/axios";
import RepresentativesFilters from "@/components/representatives/RepresentativesFilters.vue"
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";

const $loading = useLoading(loaderData);

const headers = ref([
  {
    title: "Rep Name",
    key: "rep_name",
    sortable: true,
  },
  {
    title: "Rep Code",
    key: "rep_code",
    sortable: true,
  },
  {
    title: "Branch Code",
    key: "branch_code",
    sortable: true,
  },
  {
    title: "Branch",
    key: "branch",
    sortable: true,
  },
  {
    title: "Investment Company",
    key: "investment_company",
    sortable: true,
  },
]);

const representatives = ref([]);
const filterInfo = ref({});

onMounted(async () => {
});

const getRepresentatives = async (payload = null) => {
  let url = `/representatives?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (payload.role && payload.role.trim() !== "") {
    url += `&role=${payload.role}`;
  }
  if (payload.status == 1 || payload.status == 0) {
    url += `&status=${payload.status}`;
  }
  if (payload.rep_filter) {
    url += `&rep_filter=${payload.rep_filter}`;
  }
  if (payload.rep_code_filter) {
    url += `&rep_code_filter=${payload.rep_code_filter}`;
  }
  if (payload.branch_code_filter) {
    url += `&branch_code_filter=${payload.branch_code_filter}`;
  }
  if (payload.company_filter) {
    url += `&company_filter=${payload.company_filter}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      console.log('response', response);
      if (response.status == "success") {
        representatives.value = response.data.representatives;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getRepresentatives(filterInfo.value);
};

const repChanged = async payload => {
  filterInfo.value.rep_filter = payload
  getRepresentatives(filterInfo.value)
}

const repCodeChanged = async payload => {
  filterInfo.value.rep_code_filter = payload
  getRepresentatives(filterInfo.value)
}

const branchCodeChanged = async payload => {
  filterInfo.value.branch_code_filter = payload
  getRepresentatives(filterInfo.value)
}

const companyChanged = async payload => {
  filterInfo.value.company_filter = payload
  getRepresentatives(filterInfo.value)
}

const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getRepresentatives(filterInfo.value);
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important;">
      <RepresentativesFilters
        :width="3"
        :status="status"
        :roles="roles"
        @rep-changed="repChanged"
        @rep-code-changed="repCodeChanged"
        @branch-code-changed="branchCodeChanged"
        @company-changed="companyChanged"
      />

      <VDivider />

      <RepresentativeDataTable
        :headers="headers"
        :table-data="representatives"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
      />
    </VCardText>
  </VCard>

</template>
