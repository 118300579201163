<script setup>
import ContactForm from "@/components/contacts/ContactForm.vue";
import ContactsDataTable from "@/components/contacts/ContactsDataTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import ContactFilters from "@/components/contacts/ContactFilters.vue";
import ContactCustomFieldsForm from "@/components/contacts/ContactCustomFieldsForm.vue";
const $loading = useLoading(loaderData);

const errors = ref([]);
const headers = ref([
  {
    title: "user",
    key: "name",
  },
  {
    title: "Phone",
    key: "phone",
    sortable: false,
  },
  {
    title: "DOB",
    key: "date_of_birth",
    sortable: false,
  },
  {
    title: "SSN",
    key: "social_security_number",
    sortable: false,
  },
  {
    title: "Notes",
    key: "notes",
    sortable: false,
  },
  {
    title: "Organization",
    key: "organization",
  },
  {
    title: "Branch",
    key: "branch",
  },
  {
    title: "Household",
    key: "household",
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);
const contacts = ref([]);
const organizations = ref([]);
const branches = ref([]);
const households = ref([]);
const isCustomFieldsDialogVisible = ref(false)
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");
const customFormFields = ref([])
const isCanSeeManageCustomFieldsBtn = ref(false);
const system_form_field_types = ref([])
const filterInfo = ref({});
const editedItem = ref({});
const contactsAll = ref([]);

const getBranches = (search_query = "") => {
  let currentUser = useCookie("userData").value;
  if (
    currentUser.branch_id
  ) {
    // do not pass any branch id, backend will figure out
    branches.value = [];
  } else {
    axios
      .get(`/branches?search_query=${search_query}&all=1`)
      .then((response) => {
        if (response.status == "success") {
          branches.value = response.data.branches.map((branch) => {
            return {
              title: branch.branch_name,
              value: branch.id,
            };
          });
        }
      })
      .catch((error) => {})
      .finally(() => {});
  }
};

const getAllContacts = (search_query = "") => {
  axios
    .get(`/contacts?search_query=${search_query}&all=1`)
    .then((response) => {
      if (response.status == "success") {
        contactsAll.value = response.data.contacts.map(
          (contact) => {
            return {
              title: contact.first_name + ' ' + contact.last_name,
              value: contact.id,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getOrganizations = (search_query = "") => {
  axios
    .get(`/organizations?search_query=${search_query}&all=1`)
    .then((response) => {
      if (response.status == "success") {
        organizations.value = response.data.organizations.map(
          (organization) => {
            return {
              title: organization.name,
              value: organization.id,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getHouseholds = (search_query = "") => {
  axios
    .get(`/households?search_query=${search_query}&all=1`)
    .then((response) => {
      if (response.status == "success") {
        households.value = response.data.households.map(
          (household) => {
            return {
              title: household.name,
              value: household.id,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/contacts`)
    .then((response) => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch((error) => {})
    .finally(() => {});
}

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};


const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Contacts")
  );
};

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const getContacts = async (payload = null) => {
  let url = `/contacts?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (
    payload?.organization != "" &&
    (payload?.organization != undefined || payload?.organization != null)
  ) {
    url += `&organization=${payload.organization}`;
  }
  if (
    payload?.branch != "" &&
    (payload?.branch != undefined || payload?.branch != null)
  ) {
    url += `&branch=${payload.branch}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        contacts.value = response.data.contacts;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getContacts(filterInfo.value);
};

const branchChanged = async (payload) => {
  filterInfo.value.branch = payload;
  getContacts(filterInfo.value);
};

const organizationChanged = async (payload) => {
  filterInfo.value.organization = payload;
  getContacts(filterInfo.value);
};
const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getContacts(filterInfo.value);
};
const customFieldsUpdated = async () => {
  getCustomFormFields();
}
const refreshContacts = async () => {
  getContacts(filterInfo.value);
};

const editItem = (item) => {
  const { branch, household, organization, custom_fields, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    branch_id: item.branch_id ? parseInt(newItem.branch_id) : "",
    organization_id: item.organization_id
      ? parseInt(newItem.organization_id)
      : "",
    values: toRaw(item?.custom_fields)
  };
  console.log('editedItem.value', editedItem.value);

  modeModal.value = "Update";
  openModal.value = true;
};

const deleteItem = (item) => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/contacts/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getContacts(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};
// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Contact Custom Fields")
  );
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true;
  } else {
    isCanSeeManageCustomFieldsBtn.value = false;
  }
};
// load organizations when page loads
onMounted(async () => {
  getBranches();
  getAllContacts();
  getOrganizations();
  getHouseholds();
  canSeeCreateBtn();

  getSystemFormFieldTypes();
  canSeeManageCustomFieldsButton();
  getCustomFormFields();

});

// expose errors and getOrganizations
defineExpose({
  errors,
  getContacts,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">
      <!-- Contact Ftilers Section -->
      <ContactFilters
        :filters="['organizations', 'branches']"
        :width="6"
        :branches="branches"
        :organizations="organizations"
        @branchChanged="branchChanged"
        @organizationChanged="organizationChanged"
      />
      <VDivider />

      <!-- Contacts Table Section -->
      <ContactsDataTable
        :headers="headers"
        :table-data="contacts"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :branches="branches"
        :canSeeManageCustomFieldsBtn="isCanSeeManageCustomFieldsBtn"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @openCustomFieldsModal="openCustomFieldsModal"
      />
    </VCardText>
  </VCard>

  <!-- Contact Create/Edit Modal -->
  <ContactForm
    v-model:isDialogVisible="openModal"
    :contact-data="editedItem"
    :branches="branches"
    :organizations="organizations"
    :mode="modeModal"
    @refreshContacts="refreshContacts"
    :households="households"
    :custom_fields="customFormFields"
  />

  <!-- Contact Delete Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />

  <!-- Custom Fields Form -->
  <ContactCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :system_form_field_types="system_form_field_types"
    :contactsAll="contactsAll"
    :organizationsAll="organizations"
    :householdsAll="households"
    @customFieldsUpdated="customFieldsUpdated"
  />
</template>
