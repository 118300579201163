<script setup>
import { useGenerateImageVariant } from "@core/composable/useGenerateImageVariant";
import authV2ForgotPasswordIllustrationDark from "@images/pages/auth-v2-forgot-password-illustration-dark.png";
import authV2ForgotPasswordIllustrationLight from "@images/pages/auth-v2-forgot-password-illustration-light.png";
import authV2MaskDark from "@images/pages/misc-mask-dark.png";
import authV2MaskLight from "@images/pages/misc-mask-light.png";
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { themeConfig } from "@themeConfig";
import { useRouter } from "vue-router";

import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import axios from "@/utils/axios"

const router = useRouter();

const form = ref({
  password_reset_token: "",
  password: "",
  password_confirmation: "",
});

const error = ref();
const success = ref();
const updateBtn = ref(1);

const authThemeImg = useGenerateImageVariant(
  authV2ForgotPasswordIllustrationLight,
  authV2ForgotPasswordIllustrationDark
);
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark);

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

const updatePassword = async () => {
  updateBtn.value = 0;
  axios
    .post("/auth/password/update_password", {
      password: form.value.password,
      password_confirmation: form.value.password_confirmation,
      password_reset_user_token: useCookie("password_reset_user_token").value,
    })
    .then(response => {
      if (response.status == 'success') {
        useCookie("password_reset_user_token").value = false;
        toast.success(response.message, {
          autoClose: 2000,
        })
        router.push('/login')
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
        updateBtn.value = 1;
      }
    })
    .catch(error => {
      console.log(error)
      updateBtn.value = 1;
    })
    .finally(() => {
      updateBtn.value = 1;
    })
    
};
</script>

<template>
  <VRow class="auth-wrapper bg-surface" no-gutters>
    <VCol lg="8" class="d-none d-lg-flex">
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="368"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg class="auth-footer-mask" :src="authThemeMask" />
      </div>
    </VCol>

    <VCol cols="12" lg="4" class="d-flex align-center justify-center">
      <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-4">
        <VCardText>
          <VNodeRenderer :nodes="themeConfig.app.logo" class="mb-6" />
          <h4 class="text-h4 mb-1">Update Password</h4>
          <p class="mb-0">Set a new password</p>
        </VCardText>

        <VCardText >

          <VRow v-if="success">
            <VCol cols="12">
              <VAlert color="light-success" class="text-success">
                  <span class="text-lg font-weight-medium"
                    >Updated</span
                  >
                  <p class="mb-0">
                    {{ success }}
                  </p>
                </VAlert>
            </VCol>
          </VRow>

          <VForm  @submit.prevent="updatePassword" v-else>
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  autofocus
                  label="Password"
                  type="password"
                  :rules="[requiredValidator]"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.password_confirmation"
                  autofocus
                  label="Password Confirmation"
                  type="password"
                  :rules="[requiredValidator]"
                />
              </VCol>

              <VCol cols="12" v-if="error">
                <VAlert color="light-warning" class="text-warning">
                  <span class="text-lg font-weight-medium"
                    >Invalid Request</span
                  >
                  <p class="mb-0">
                    {{ error }}
                  </p>
                </VAlert>
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
                <VBtn block :disabled="updateBtn == 0" type="submit">
                  Update Password
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>

        <RouterLink
          class="d-flex align-center justify-center"
          :to="{ name: 'login' }"
        >
          <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
          <span>Back to login</span>
        </RouterLink>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
