<script setup>
import { onMounted, ref, watch, nextTick, onUnmounted, computed } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";
import interact from "interactjs";
import AppDateTimePicker from "@/@core/components/app-form-elements/AppDateTimePicker.vue";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";

const router = useRouter();
const $loading = useLoading(loaderData);

const contactData = ref({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    notes: "",
    organization_id: "",
    branch_id: '',
    user_id: '',
    household_id: "",
    social_security_number: "",
    date_of_birth: '',
    type: "individual",
    values: [],
    fields_temp_id: ''
});


const lookup_fields = ref([]);
const modules = ref([])
const getLookupFields = () => {
  axios
    .get("lookup_fields")
    .then(async (response) => {
      if (response.status == "success") {
        lookup_fields.value = response.data.lookup_fields
        modules.value = Object.keys(response.data.lookup_fields).map(key => ({
          title: response.data.lookup_fields[key].name,
          value: key
        }));
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const lookupFieldAttributes = computed(() => {

    let attributes = [];
      if(customFormFieldInstance.value.table){
        let lookup_field_columns = lookup_fields.value[customFormFieldInstance.value.table].columns
        console.log('lookup_field_columns', lookup_field_columns)
        attributes = Object.keys(lookup_field_columns).map(key => ({
          title: lookup_field_columns[key],
          value: key
        }));
        let attribute_keys = Object.keys(lookup_field_columns);
        if(!attribute_keys.includes(customFormFieldInstance.value.column)){
          customFormFieldInstance.value.column = null
        }
      }else{
        attributes = [];
      }
      return attributes;

})

const fieldLookupSettingChanged = () => {
  customFormFieldInstance.value.options = [];
}


const route = useRoute("contacts-view-id");

const branches = ref([]);
const organizations = ref([]);
const households = ref([]);
const submitBtn = ref(1);
const contactList = ref([]);
const searchContacts = (event) => {
    let contacts = customFormFieldInstance.value.contacts.map(item => item.value ?? item)
    fetchContacts(event.target.value, contacts)
}
const fetchContacts = (search = '', includes=[]) => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/contacts?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                contactList.value = response.data.contacts.data.map((item) => {
                    return {
                        title: item.first_name + ' ' + item.last_name,
                        value: item.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const searchContactsOnChange = () => {
    let contacts = [];
    customFormFieldInstance.value.contacts.forEach((user) => {
        if(user.value){
            contacts.push(user.value)
        }else{
            contacts.push(user)
        }
    })
    fetchContacts('', contacts)
}

const organizationList = ref([]);
const searchOrganizations = (event) => {
    let organizations = [customFormFieldInstance.value.meta.organization]
    fetchOrganizationsForField(event.target.value, organizations)
}
const fetchOrganizationsForField = (search = '', includes=[]) => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/organizations?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                organizationList.value = response.data.organizations.data.map((item) => {
                    return {
                        title: item.name,
                        value: item.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}
const searchOrganizationsOnChange = () => {
    let organizations = [customFormFieldInstance.value.meta.organization]
    fetchOrganizationsForField('', organizations)
}

const householdList = ref([]);
const searchHouseholds = (event) => {
    let households = [customFormFieldInstance.value.meta.household]
    fetchHouseholdsForField(event.target.value, households)
}
const fetchHouseholdsForField = (search = '', includes=[]) => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/households?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                householdList.value = response.data.households.data.map((item) => {
                    return {
                        title: item.name,
                        value: item.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}
const searchHouseholdsOnChange = () => {
    let households = [customFormFieldInstance.value.meta.household]
    fetchHouseholdsForField('', households)
}

const branchList = ref([]);
const searchBranches = (event) => {
    let branches = [customFormFieldInstance.value.meta.branch]
    fetchBranchesForField(event.target.value, branches)
}
const fetchBranchesForField = (search = '', includes=[]) => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/branches?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                branchList.value = response.data.branches.data.map((item) => {
                    return {
                        title: item.branch_name,
                        value: item.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}
const searchBranchesOnChange = () => {
    let branches = [customFormFieldInstance.value.meta.branch]
    fetchBranchesForField('', branches)
}

const fetchBranches = (search = '') => {
    axios
        .get(`/branches?search_query=${search}`)
        .then((response) => {
            if (response.status == "success") {
                branches.value = response.data.branches.data.map((branch) => {
                    return {
                        title: branch.branch_name,
                        value: branch.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}
const reps = ref([])
const fetchReps = (search = '') => {
    axios
        .get(`/users_rep?branch_id=${contactData?.value?.branch_id ?? ''}`)
        .then((response) => {
            if (response.status == "success") {
                reps.value = response.data.users.map((rep) => {
                    return {
                        title: rep.first_name + ' ' + rep.last_name,
                        value: rep.id,
                    };
                });

                // Check if the selected rep is in the list
                if (!reps.value.some(rep => parseInt(rep.value) === parseInt(contactData.value.user_id))) {
                    contactData.value.user_id = '';
                }
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const userData = useCookie("userData").value;
const repDisabled = computed(() => {
    return (userData.role === 'representative');
});
watch(
    () => contactData.value.branch_id,
    async (newBranchId, oldBranchId) => {
        if (newBranchId !== oldBranchId) {
            fetchReps();
        }
    }
);
const fetchOrganizations = (search = '') => {
    axios
        .get(`/organizations?search_query=${search}`)
        .then((response) => {
            if (response.status == "success") {
                organizations.value = response.data.organizations.data.map(
                    (organization) => {
                        return {
                            title: organization.name,
                            value: organization.id,
                        };
                    }
                );
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const fetchHouseholds = (search = '') => {
    axios
        .get(`/households?search_query=${search}`)
        .then((response) => {
            if (response.status == "success") {
                households.value = response.data.households.data.map(
                    (household) => {
                        return {
                            title: household.name,
                            value: household.id,
                        };
                    }
                );
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const system_form_field_types = ref([])
const getSystemFormFieldTypes = (search_query = "") => {
    axios
        .get(`/system_form_field_types`)
        .then((response) => {
            if (response.status == "success") {
                system_form_field_types.value = response.data.field_types
            }
        })
        .catch((error) => { })
        .finally(() => { });
};

const makeFieldsDraggable = () => {
    interact(".form_field_create_contact").draggable({
        autoScroll: true,
        onstart: (event) => { },
        onmove: (event) => {
            var target = event.target;
            var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
            var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
            target.style.transform = "translate(" + x + "px, " + y + "px)";
            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
        },
        onend: (event) => {
            const target = event.target;
            const dropzone = event.dropzone;

            if (!dropzone) {
                target.style.transform = `translate(0px, 0px)`;
                target.setAttribute("data-x", 0);
                target.setAttribute("data-y", 0);
            }
        },
    });
}

const initializeDropZone = () => {
    interact(".form_fields_dropzone_create_contact").dropzone({
        accept: ".form_field_create_contact",
        overlap: 0.75,
        ondragenter: function (event) {
            event.target.classList.add("drop-area-bg");
        },
        ondragleave: function (event) {
            event.target.classList.remove("drop-area-bg");
        },
        ondrop: (event) => {
            event.target.classList.remove("drop-area-bg");
            let droppedElement = event.relatedTarget;
            let type = droppedElement.getAttribute("type");
            if (type) {
                const field_type = system_form_field_types.value.find(
                    (item) => item.type === type
                );
                if (field_type) {
                    addCustomFormField(field_type);
                }
            }
            droppedElement.style.transform = `translate(0px, 0px)`;
            droppedElement.setAttribute("data-x", "0px");
            droppedElement.setAttribute("data-y", "0px");
        },
    });
}

const canManageCustomFields = ref(false)

onUnmounted(() => {
  interact('.form_field_create_contact').unset();
  interact('.form_fields_dropzone_create_contact').unset();
  interact('.draggable-col-contacts-create').unset();
});

const isFieldsReady = ref(false)
const fieldOrders = ref([])


const currentTab = ref(0)
const updateCurrentTab = (tabId) => {
  currentTab.value = tabId
}

const formFieldsByTab = (tabId) => {
  if (tabId == 0) {
    return customFormFields.value
      .map((field, index) => ({ ...field, originalIndex: index }))
      .filter(field => field.form_tab_id == null);
  }
  return customFormFields.value
    .map((field, index) => ({ ...field, originalIndex: index }))
    .filter(field => field.form_tab_id === tabId);
}

const formTabs = ref([]);
const customFieldActiveTab = ref(0);

onMounted(async () => {

    try {

        fetchReps();

        const userData = useCookie("userData").value;
        const userPermissionsByRoles = userData.permissions_by_roles;
        let hasPermission = Object.values(userPermissionsByRoles).some(
            (permissions) => permissions.includes("Contact Custom Fields")
        );
        if (hasPermission) {
            canManageCustomFields.value = true;
        } else {
            canManageCustomFields.value = false;
        }

        const [fieldOrdersResponse, customFieldsResponse, formTabsResponse] = await Promise.all([
            axios.get('/module-fields/contacts'),
            axios.get('/custom_fields/contacts'),
            axios.get('/form_tabs/contact')
        ])

        if (fieldOrdersResponse.status === 'success' && customFieldsResponse.status === 'success') {
            fieldOrders.value = fieldOrdersResponse.data.field_orders
            customFormFields.value = customFieldsResponse.data.form_fields
            isFieldsReady.value = true

            await nextTick()
            reorderFields()
        }

        if (formTabsResponse.status === 'success') {
            formTabs.value = formTabsResponse.data
        }
    } catch (error) {
        console.error('Error initializing fields:', error)
    }


    getLookupFields()

    getSystemFormFieldTypes();

    if(canManageCustomFields.value){
        makeFieldsDraggable();
        initializeDropZone();
    }

    fetchBranches();
    fetchOrganizations();
    fetchHouseholds();

    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    const id = `${timestamp}-${randomNum}`;
    uniqueCreationID.value = id;

    if(canManageCustomFields.value){

        interact('.draggable-col-contacts-create')
        .draggable({
            autoScroll: true,
            onstart: (event) => { },
            onmove: (event) => {
                var target = event.target;
                var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                target.style.transform = "translate(" + x + "px, " + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
            },
            onend: (event) => {
                const target = event.target;
                const dropzone = event.dropzone;

                if (!dropzone) {
                    target.style.transform = `translate(0px, 0px)`;
                    target.setAttribute("data-x", 0);
                    target.setAttribute("data-y", 0);
                }
            },
        })
        .dropzone({
            accept: '.draggable-col-contacts-create',
            overlap: 0.1,
            ondragenter: function (event) {
                event.target.classList.add('drop-area-bg');
            },
            ondragleave: function (event) {
                event.target.classList.remove('drop-area-bg');
            },
            ondrop: (event) => {
                event.target.classList.remove('drop-area-bg');
                const draggedElement = event.relatedTarget;
                const targetElement = event.target;

                const draggedOrder = draggedElement.getAttribute('data-order');
                const targetOrder = targetElement.getAttribute('data-order');

                axios.post('/module-fields/contacts/reorder', {
                    dragged_field: draggedOrder,
                    target_field: targetOrder
                })
                .then((response) => {
                    if (response.status == "success") {
                        console.log('Field order updated successfully');
                        fieldOrders.value = response.data.field_orders
                        nextTick(() => {
                            reorderFields();
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error updating field order:', error);
                });

                draggedElement.style.transform = `translate(0px, 0px)`;
                draggedElement.setAttribute("data-x", "0px");
                draggedElement.setAttribute("data-y", "0px");
           }
        });

    }
    

});

const createContact = async () => {
    let loader = $loading.show();
    submitBtn.value = 0;
    contactData.value.fields_temp_id = uniqueCreationID.value;
    axios
        .post("/contacts", contactData.value)
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                router.push({ name: "contacts-list"});
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
            submitBtn.value = 1;
        });
};

const discardChanges = () => {
    router.push({ name: "contacts-list" });
}

const customFormFields = ref([])
const getCustomFormFields = () => {
    axios
        .get(`/custom_fields/contacts`)
        .then((response) => {
            if (response.status == "success") {
                customFormFields.value = response.data.form_fields
                nextTick(() => {
                    reorderFields();
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const resolveIconForFieldType = (system_form_field) => {
    if (system_form_field.type == "single_line") {
        return "tabler-cursor-text";
    } else if (system_form_field.type == "multi_line") {
        return "tabler-forms";
    } else if (system_form_field.type == "integer") {
        return "tabler-123";
    } else if (system_form_field.type == "percent") {
        return "tabler-percentage";
    } else if (system_form_field.type == "decimal") {
        return "tabler-decimal";
    } else if (system_form_field.type == "currency") {
        return "tabler-cash";
    } else if (system_form_field.type == "date") {
        return "tabler-calendar-month";
    } else if (system_form_field.type == "date_time") {
        return "tabler-calendar-clock";
    } else if (system_form_field.type == "email") {
        return "tabler-mail";
    } else if (system_form_field.type == "phone") {
        return "tabler-phone";
    } else if (system_form_field.type == "pick_list") {
        return "tabler-checkup-list";
    } else if (system_form_field.type == "multi_select") {
        return "tabler-list-check";
    } else {
        return "";
    }
};

const findFormFieldValue = (fieldId) => {
    return contactData.value.values.find(field => field.form_field_id === fieldId) || { value: '' };
}

const transformedOptions = (form_field) => {
    return form_field.options.map((option) => ({
        title: option.name,
        value: option.id,
    }));
};

const showfield = (form_field) => {
    if(form_field.meta.temp_id){
        if(uniqueCreationID.value != form_field.meta.temp_id){
            return false;
        }
    }
    
    let conditions = form_field.conditions;
    let condition_status = false;
    if (conditions.length > 0) {
        conditions.forEach((condition) => {
            let parentField = contactData.value.values.find(field => field.form_field_id === condition.parent_form_field_id);
            if (parentField) {
                let parent_value = parentField.value;
                let parent_options = customFormFields.value.find(field => field.id === condition.parent_form_field_id).options;
                if (parent_value) {
                    let parent_selected_option = parent_options.find(option => option.id == parent_value);
                    if (parent_selected_option && parent_selected_option.name == condition.value) {
                        condition_status = true;
                    }
                }
            }
        });
    } else {
        condition_status = true;
    }

    let layers_status = false;

    let form_field_layer_option_contact = form_field.meta.layer_option.contacts;
    if (form_field_layer_option_contact == 'all') {
        layers_status = true;
    }
    else if (form_field_layer_option_contact == 'custom') {
        if(form_field.meta.current_contact){
            layers_status = true;
        }
    }

    let form_field_layer_option_organization = form_field.meta.layer_option.organization;
    let form_field_organization = form_field.meta.organization;
    let selected_organization = contactData.value.organization_id;
    if (form_field_layer_option_organization == 'all' && selected_organization) {
        layers_status = true;
    }
    else if (form_field_layer_option_organization == 'custom' && form_field_organization == selected_organization) {
        layers_status = true;
    }

    let form_field_layer_option_household = form_field.meta.layer_option.household;
    let form_field_household = form_field.meta.household;
    let selected_household = contactData.value.household_id;
    if (form_field_layer_option_household == 'all' && selected_household) {

        layers_status = true;
    }
    else if (form_field_layer_option_household == 'custom' && form_field_household == selected_household) {
        
        layers_status = true;
    }

    let form_field_layer_option_branch = form_field.meta.layer_option.branch;
    let form_field_branch = form_field.meta.branch;
    let selected_branch = contactData.value.branch_id;
    if (form_field_layer_option_branch == 'all' && selected_branch) {

        layers_status = true;
    }
    else if (form_field_layer_option_branch == 'custom' && form_field_branch == selected_branch) {

        layers_status = true;
    }

    return condition_status && layers_status;
};

const deleteFieldButton = (form_field) => {
    return true;
}

const uniqueCreationID = ref('')
const customFieldDialog = ref(false)
const addCustomFormField = (type) => {
    customFieldDialog.value = true;
    customFieldDialogTab.value = 0;
    customFormFieldInstance.value = {
        name: '',
        type: type.type,
        meta: {
            layer_option: {
                branch: 'none',
                organization: 'none',
                household: 'none',
                contacts: 'custom',
            },
            organization: '',
            household: '',
            branch: '',
            temp_id: uniqueCreationID.value,
            current_contact: true,
        },
        form_tab_id: customFieldActiveTab.value == 0 ? null : currentTab.value,
        conditions: [],
        options: [],
        contacts: [],
    }
}

const customFieldDialogTab = ref(1)
const customFormFieldInstance = ref({})
const saveFormFieldChanges = async () => {

    let url = '/custom_fields/contacts/create_update_field';

    let loader = $loading.show();
    axios
        .post(url, {
            form_field: customFormFieldInstance.value
        })
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });

                customFieldDialog.value = false;
                customFieldDialogTab.value = 0;

                getCustomFormFields();

                nextTick(() => {
                    reorderFields();
                });

            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

const customFieldPickListUpdatedExecute = (form_field) => {

if(form_field.is_lookup_field && (form_field.parent_lookup_field_id == 0 || form_field.act_as_parent)){
    
    let parentField = contactData.value.values.find((value) => value.form_field_id == form_field.id);
    let parent_value = parentField ? parentField.value : null;
    if(parent_value){
        axios
            .get(
                "/custom_fields/get_lookup_field_record" + `?table=${form_field.table}&id=${findFormFieldValue(form_field.id).value}`
            )
            .then((response) => {
                if (response.status == "success") {
                    if(form_field.act_as_parent){
                        const parentField = customFormFields.value.filter(f => f.id === form_field.parent_lookup_field_id)
                        parentField.forEach(element => {
                            contactData.value.values.forEach((val) => {
                                if(val.form_field_id == element.id){
                                    val.value = response.data.model['id']
                                }
                            })
                        });
                        const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.parent_lookup_field_id)
                        matchingFields.forEach((field) => {
                            contactData.value.values.forEach((val) => {
                                if(val.form_field_id == field.id){
                                    if(field.act_as_parent){
                                        val.value = response.data.model['id']
                                    }else{
                                        val.value = response.data.model[field.column]
                                    }
                                }
                            })
                        })
                    }else{
                        const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.id)
                        matchingFields.forEach((field) => {
                            contactData.value.values.forEach((val) => {
                                if(val.form_field_id == field.id){
                                    if(field.act_as_parent){
                                        val.value = response.data.model['id']
                                    }else{
                                        val.value = response.data.model[field.column]
                                    }
                                }
                            })
                        })
                    }
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }
    
}
}
const customFieldPickListUpdated = (form_field) => {
    setTimeout(() => {
        customFieldPickListUpdatedExecute(form_field);
    }, 50);
}


const reorderFields = () => {
    const defaultTabRow = document.querySelector('.fields_row');
    if (!defaultTabRow) return;

    const sortedFields = [...fieldOrders.value.fields].sort((a, b) => a.display_order - b.display_order);
    
    const allTabRows = document.querySelectorAll('.fields_row');
    
    allTabRows.forEach(formRow => {
        const tabFieldElements = formRow.querySelectorAll('.draggable-col-contacts-create');
        
        const orderedElements = new Set();
        
        sortedFields.forEach((fieldData) => {
            const fieldElement = formRow.querySelector(`[data-order="${fieldData.field}"]`);
            if (fieldElement && fieldElement.parentElement === formRow) {
                formRow.appendChild(fieldElement);
                orderedElements.add(fieldElement);
            }
        });
        
        tabFieldElements.forEach(element => {
            if (!orderedElements.has(element) && element.parentElement === formRow) {
                formRow.appendChild(element);
            }
        });
    });
};


watch(
    fieldOrders,
    () => {
        if (isFieldsReady.value) {
            nextTick(() => {
                reorderFields()
            })
        }
    },
    { deep: true }
)

const currentFieldOptionValue = ref('')
const addFormFieldOption = () => {
    if (currentFieldOptionValue.value != "") {
        let currentFormFieldOptions = customFormFieldInstance.value.options;
        currentFormFieldOptions.push(currentFieldOptionValue.value);
        customFormFieldInstance.value.options = currentFormFieldOptions;
        currentFieldOptionValue.value = "";
    }
};

const removeFormFieldOption = (index) => {
    if (customFormFieldInstance.value.options[index].id) {
        axios
            .delete(
                "/helpdesk/form_field_options/" +
                `${customFormFieldInstance.value.options[index]
                    .id
                }`
            )
            .then((response) => {
                if (response.status == "success") {
                    customFormFieldInstance.value.options.splice(
                        index,
                        1
                    );
                }
            })
            .catch((error) => { })
            .finally(() => { });
    } else {
        customFormFieldInstance.value.options.splice(
            index,
            1
        );
    }
};

const deleteFormFieldDialog = ref(false);
const deleteFormFieldId = ref('')
const removeFormField = (form_field_id) => {
    deleteFormFieldId.value = form_field_id
    deleteFormFieldDialog.value = true;
};

const removeFormFieldSubmit = () => {
    let loader = $loading.show();

    axios
        .delete(
            "/helpdesk/form_fields/" + `${deleteFormFieldId.value}`
        )
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                getCustomFormFields();
                deleteFormFieldDialog.value = false;
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
};

const editFieldSettings = (form_field) => {
    customFormFieldInstance.value = form_field;
    customFieldDialogTab.value = 0;
    customFieldDialog.value = true;
};

const updateContactCustomFields = () => {
    let custom_fields = customFormFields.value;
    let contactDataValues = contactData.value.values;
    custom_fields.forEach((custom_field) => {
        if (showfield(custom_field)) {
            let exists = false;
            contactDataValues.forEach((contactDataValue) => {
                if (contactDataValue.form_field_id == custom_field.id) {
                    exists = true;
                }
            });

            if (!exists) {
                if(custom_field.type == 'multi_select'){
                    contactData.value.values.push({
                        form_field_id: custom_field.id,
                        value: []
                    });
                }
                else{
                    contactData.value.values.push({
                        form_field_id: custom_field.id,
                        value: ''
                    });
                }
            }else{
                if(custom_field.type == 'multi_select'){
                    let allOptionsAreValid = true;
                    let newcontactDataValues = contactDataValues.map((contactDataValue) => {
                        if (contactDataValue.form_field_id == custom_field.id) {
                            let selected_options = contactDataValue.value.map((item) => item);
                            let available_options = custom_field.options.map((item) => item.id);
                            let valid_selected_options = selected_options.filter(option => available_options.includes(option));
                            if(valid_selected_options.length != selected_options.length){
                                allOptionsAreValid = false;
                            }
                            return {
                                form_field_id: contactDataValue.form_field_id,
                                value: valid_selected_options
                            }
                        }
                        return contactDataValue;
                    });
                    if(!allOptionsAreValid){
                        contactData.value.values = newcontactDataValues
                    }
                }
            }
        }
    });
};

watch(
    customFormFieldInstance,
    async (newCustomFormFieldInstance, oldCustomFormFieldInstance) => {
        searchContactsOnChange();
        searchBranchesOnChange();
        searchHouseholdsOnChange();
        searchOrganizationsOnChange();
    }
);

watch(
  customFormFields,
  async (newCustomFields) => {
    updateContactCustomFields();
  },
  { deep: true }
);

watch(
  contactData,
  async (newContactData) => {
    updateContactCustomFields();
  },
  { deep: true }
);

const onPhoneInput = (event) => {
  let value = event.target.value.replace(/\D/g, '');

  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  if (value.length > 3 && value.length <= 6) {
    value = value.replace(/^(\d{3})(\d{0,3})/, '$1-$2');
  } else if (value.length > 6) {
    value = value.replace(/^(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
  }

  contactData.value.phone = value;
};

const onSSNInput = (event) => {
  let value = event.target.value.replace(/\D/g, '');

  if (value.length > 9) {
    value = value.slice(0, 9);
  }

  if (value.length > 3 && value.length <= 5) {
    value = value.replace(/^(\d{3})(\d{0,2})/, '$1-$2');
  } else if (value.length > 5) {
    value = value.replace(/^(\d{3})(\d{2})(\d{0,4})/, '$1-$2-$3');
  }

  contactData.value.social_security_number = value;
};

const ssnValidator = [(v) => v.length === 11 || v === '' || 'SSN must be 9 digits'];

definePage({ meta: { requiresAuth: true } });

const swapArrayElements = (arr, fromIndex, toIndex) => {
    arr.forEach((field) => {
        if (field.parent_lookup_field_id) {
            const parentIndex = arr.findIndex(f => f.id === field.parent_lookup_field_id);
            if (parentIndex === fromIndex) {
                field.parent_lookup_field_id = arr[toIndex].id;
            } else if (parentIndex === toIndex) {
                field.parent_lookup_field_id = arr[fromIndex].id;
            }
        }

        if (field.conditions && field.conditions.length > 0) {
            field.conditions.forEach((condition) => {
                if (condition.parent_form_field_id === arr[fromIndex].id) {
                    condition.parent_form_field_id = arr[toIndex].id;
                } else if (condition.parent_form_field_id === arr[toIndex].id) {
                    condition.parent_form_field_id = arr[fromIndex].id;
                }
            });
        }
    });

    const temp = arr[fromIndex];
    arr[fromIndex] = arr[toIndex];
    arr[toIndex] = temp;

    let fromDisplayOrder = arr[fromIndex].display_order;
    arr[fromIndex].display_order = arr[toIndex].display_order;
    arr[toIndex].display_order = fromDisplayOrder;

    updateField(arr[fromIndex]);
    updateField(arr[toIndex]);
};

const updateField = async (field) => {
    try {
        await axios.post("/custom_fields/contacts/create_update_field", {
            form_field: field
        });
    } catch (error) {
        getCustomFormFields();
    }
};

const quickHouseholdDialog = ref(false)
const quickHouseholdSubmitBtn = ref(1)
const quickHouseholdData = ref({
    name: '',
    description: '',
    contacts: []
})

const openQuickHouseholdDialog = () => {
    quickHouseholdData.value = {
        name: '',
        description: '',
        contacts: []
    }
    quickHouseholdDialog.value = true
}

const createQuickHousehold = async () => {
    let loader = $loading.show()
    quickHouseholdSubmitBtn.value = 0

    try {
        const response = await axios.post("/households", quickHouseholdData.value)
        
        if (response.status === "success") {
            toast.success(response.message, {
                autoClose: 5000,
            })
            
            await fetchHouseholds()
            
            const newHousehold = response.data.household
            if (newHousehold) {
                contactData.value.household_id = newHousehold.id
            }
            
            quickHouseholdDialog.value = false
        } else {
            toast.error(response.message, {
                autoClose: 2000,
            })
        }
    } catch (error) {
        toast.error("Failed to create household. Please try again.", {
            autoClose: 2000,
        })
    } finally {
        loader.hide()
        quickHouseholdSubmitBtn.value = 1
    }
}

const dateTimePickerConfig = {
  dateFormat: 'm/d/Y',
  allowInput: true,
  maxDate: new Date().toLocaleDateString('en-US'),
};
</script>

<template>
    <div>
        <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
            
            <div class="d-flex flex-column justify-center">
                <h2>
                    <strong class="oswald">Add New Contact</strong>
                </h2>
                <span class="lato-light">You can add new contact on this page</span>
            </div>

            <div class="d-flex gap-4 align-center flex-wrap">
                <VBtn @click.prevent="discardChanges" variant="tonal" color="dark">
                    Discard
                </VBtn>
                <VBtn @click.prevent="createContact">Create</VBtn>
            </div>
        </div>

        <VRow>
            <VCol cols="12" :md="canManageCustomFields ? 9 : 12" >
                <VCard class="mb-6 form_fields_dropzone_create_contact " title="Contact Information">
                    <VCardText>

                        <VForm class="mt-6" @submit.prevent="createContact()">

                            <VTabs
                                v-model="customFieldActiveTab"
                                class=" "
                            >
                                <VTab @click="updateCurrentTab(0)">Default </VTab>
                                <VTab v-for="formTab in formTabs" :key="formTab.id" @click="updateCurrentTab(formTab.id)" >
                                    {{ formTab.name }}
                                </VTab>
                                
                            </VTabs>

                            <VWindow v-model="customFieldActiveTab">
                                <VWindowItem
                                    :key="0"
                                    :value="`item-${0}`"
                                > 
                                    <div class="py-5 px-3">

                                        <VRow class="fields_row">
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="type"
                                            >
                                                <AppSelect
                                                    v-model="contactData.type"
                                                    label="Contact Type"
                                                    :items="[
                                                        { title: 'Individual', value: 'individual' },
                                                        { title: 'Trust', value: 'trust' },
                                                        { title: 'Entity', value: 'entity' }
                                                    ]"
                                                />
                                            </VCol>
                                        </VRow>
                                        
                                        <VRow class="fields_row">
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="first_name"
                                            >
                                                <AppTextField v-model="contactData.first_name" label="First Name *"
                                                    placeholder="First Name" :rules="[requiredValidator]" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="last_name"
                                            >
                                                <AppTextField v-model="contactData.last_name" label="Last Name *"
                                                    placeholder="Last Name" :rules="[requiredValidator]" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="phone"
                                            >
                                                <AppTextField v-model="contactData.phone" label="Phone *" placeholder="Phone"
                                                    @input="onPhoneInput"
                                                    :rules="[requiredValidator]" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="email"
                                            >
                                                <AppTextField v-model="contactData.email" label="Email *" placeholder="Email"
                                                    :rules="[requiredValidator]" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="social_security_number"
                                            >
                                                <AppTextField v-model="contactData.social_security_number"
                                                    @input="onSSNInput"
                                                    :rules="[ssnValidator]"
                                                    label="Social Security Number" placeholder="Social Security Number" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="date_of_birth"
                                            >
                                                <AppDateTimePicker v-model="contactData.date_of_birth" label="Date Of Birth"
                                                    placeholder="Date Of Birth" :config="{
                                                        dateFormat: 'm/d/Y', // MM/DD/YYYY format
                                                        allowInput: true, // Allow manual input
                                                        maxDate: new Date().toLocaleDateString('en-US'),
                                                    }" />
                                            </VCol>
                                            
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="branch_id"
                                            >
                                                <AppAutocomplete @update:search="fetchBranches" v-model="contactData.branch_id"
                                                    :items="branches" label="Select Branch" clearable clear-icon="tabler-x" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="user_id"
                                            >
                                            <AppAutocomplete :disabled="repDisabled" v-model="contactData.user_id"
                                                    :items="reps" label="Select Rep" clearable clear-icon="tabler-x" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="organization_id"
                                            >
                                                <AppAutocomplete @update:search="fetchOrganizations" v-model="contactData.organization_id" :items="organizations"
                                                    label="Select Organization" clearable clear-icon="tabler-x" />
                                            </VCol>
                                            <VCol cols="12" md="6"
                                                class="draggable-col-contacts-create"
                                                data-order="household_id"
                                            >
                                                <AppAutocomplete 
                                                    @update:search="fetchHouseholds" 
                                                    v-model="contactData.household_id" 
                                                    :items="households"
                                                    label="Select Household" 
                                                    clearable 
                                                    clear-icon="tabler-x">
                                                    <template #append>
                                                        <VBtn
                                                            icon="tabler-plus"
                                                            size="small"
                                                            variant="text"
                                                            @click.prevent="openQuickHouseholdDialog"
                                                        />
                                                    </template>
                                                </AppAutocomplete>
                                            </VCol>
                                            <VCol cols="12" md="12"
                                                class="draggable-col-contacts-create"
                                                data-order="notes"
                                            >
                                                <AppTextarea rows="3" v-model="contactData.notes" label="Notes"
                                                    placeholder="Notes" />

                                            </VCol>

                                            <VCol 
                                                v-for="(form_field) in formFieldsByTab(0)"
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-contacts-create"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, phoneValidator]
                                                                    : [phoneValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect @update:modelValue="customFieldPickListUpdated(form_field)" v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " chips multiple closable-chips />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>
                                        </VRow>

                                    </div>
                                </VWindowItem>

                                <VWindowItem
                                    v-for="formTab in formTabs"
                                    :key="formTab.id"
                                    :value="`item-${formTab.id}`"
                                >
                                    <div class="py-5 px-3">
                                        <VRow class="fields_row">

                                            <VCol 
                                                v-for="(form_field) in formFieldsByTab(formTab.id)"
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-contacts-create"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, phoneValidator]
                                                                    : [phoneValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect @update:modelValue="customFieldPickListUpdated(form_field)" v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " />
                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " chips multiple closable-chips />

                                                    <div style="position: absolute; top: -10px; right: 10px;" v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>
                                                        
                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>

                                        </VRow>
                                    </div>
                                </VWindowItem>
                            </VWindow>    

                            
                        </VForm>

                    </VCardText>
                </VCard>
            </VCol>

            <VCol md="3" cols="12" v-if="canManageCustomFields">
                <div class="system_fields sticky">
                    <div class="d-flex flex-column justify-center mb-5">
                        <h2 class="oswald">
                            <strong>Custom Fields</strong>
                        </h2>
                        <div class="lato-light">Drag & drop fields</div>
                    </div>

                    <div v-for="(
                        system_form_field_type, index
                        ) of system_form_field_types" :key="system_form_field_type.type">
                        <div class="form_field_create_contact bg-primary rounded mb-1 px-2 py-2" :type="system_form_field_type.type" style="-ms-touch-action: none;touch-action: none;">
                            <div>
                                <VIcon size="20" :icon="resolveIconForFieldType(system_form_field_type)
                                    " class="mr-3" />
                                {{ system_form_field_type.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </VCol>
        </VRow>
    </div>

    <VDialog v-model="customFieldDialog" max-width="600">
        <DialogCloseBtn @click="
            customFieldDialog = !customFieldDialog
            " />
        <VCard>
            <VCardText>
                <VTabs v-model="customFieldDialogTab">
                    <VTab>General Settings</VTab>
                    <VTab v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'">
                        Field
                        Options</VTab>
                    <VTab>Layer Settings</VTab>
                </VTabs>
                <VWindow v-model="customFieldDialogTab">
                    <VWindowItem :key="1" :value="`item-${1}`">
                        <div class="py-5">
                            <AppTextField v-model="customFormFieldInstance.name" label="Field Name"
                                placeholder="Field Name" />
                            <VSwitch class="mt-3" v-model="customFormFieldInstance.is_required" label="Is required" />
                        </div>
                    </VWindowItem>

                    <VWindowItem class="py-5"
                        v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'"
                        :key="2" :value="`item-${2}`">
                        <div class="border rounded pa-2 mt-3 mb-5">
                            <VSwitch
                              @change="fieldLookupSettingChanged"
                              v-model="customFormFieldInstance.is_lookup_field"
                              label="Is Lookup Field"
                            />
                            <AppSelect
                              class="mt-2"
                              v-if="customFormFieldInstance.is_lookup_field"
                              v-model="customFormFieldInstance.table"
                              :items="modules"
                              placeholder="Select Module"
                              label="Module *"
                              density="compact"
                            />
                            <AppSelect
                              class="mt-2"
                              v-if="customFormFieldInstance.is_lookup_field"
                              v-model="customFormFieldInstance.column"
                              :items="lookupFieldAttributes"
                              placeholder="Select Attribute"
                              label="Attribute *"
                              density="compact"
                            />
                        </div>
                        <div style="width: 100%; text-align: left" v-if="!customFormFieldInstance.is_lookup_field">
                            <AppTextField v-model="currentFieldOptionValue" label="Option Name"
                                placeholder="Option Name">
                                <template #append>
                                    <VBtn @click.prevent="addFormFieldOption" class="mr-1" size="40" icon="tabler-plus"
                                        rounded></VBtn>
                                </template>
                            </AppTextField>
                            <div class="mt-5 text-sm" v-if="customFormFieldInstance.options.length > 0">
                                Current Options:
                            </div>
                            <VList style="padding: 0px !important" class="mt-1"
                                v-if="customFormFieldInstance.options.length > 0">
                                <template v-for="(form_field_option, index) of customFormFieldInstance.options" :key="index">
                                    <VListItem class="px-1 ma-1 my-2 rounded" border>
                                        <VListItemTitle class="pl-3">
                                            {{
                                                form_field_option.id
                                                    ? form_field_option.name
                                                    : form_field_option
                                            }}
                                        </VListItemTitle>
                                        <template #append>
                                            <VBtn color="dark" @click.prevent="removeFormFieldOption(index)" size="30"
                                                icon="tabler-minus" rounded variant="tonal"></VBtn>
                                        </template>
                                    </VListItem>
                                </template>
                            </VList>
                        </div>
                    </VWindowItem>

                    <VWindowItem :key="1" :value="`item-${1}`">
                        <div class="">
                            <div class="mb-2 mt-5 border rounded py-3 px-5">

                                <label for="">Which contacts this field is for?</label>
                                <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.contacts">
                                    <VRadio label="All" value="all" />
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <VCheckbox v-if="customFormFieldInstance.meta.layer_option.contacts == 'custom'" class="my-2" v-model="customFormFieldInstance.meta.current_contact" label="Include current contact" />

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.contacts == 'custom'"
                                    @keyup="searchContacts"
                                    v-model="customFormFieldInstance.contacts"
                                    :items="contactList"
                                    label="Select Contacts"
                                    placeholder="Type to search.."
                                    :return-object="false"
                                    clearable multiple
                                    chips closable-chips
                                    clear-icon="tabler-x"
                                />
                            </div>

                            <div class="my-2 border rounded py-3 px-5" v-if="customFormFieldInstance.meta.layer_option.contacts != 'all'">
                                <label for="">Which organization contacts this field is for?</label>
                                <VRadioGroup inline
                                    v-model="customFormFieldInstance.meta.layer_option.organization">
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.organization == 'custom'"
                                    @keyup="searchOrganizations"
                                    v-model="customFormFieldInstance.meta.organization"
                                    :items="organizationList"
                                    label="Select Organization"
                                    placeholder="Type to search.."
                                    autocomplete="no"
                                    :return-object="false"
                                    clearable
                                    clear-icon="tabler-x"
                                />
                            </div>

                            <div class="my-2 border rounded py-3 px-5" v-if="customFormFieldInstance.meta.layer_option.contacts != 'all'">
                                <label for="">Which household contacts this field is for?</label>
                                <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.household">
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.household == 'custom'"
                                    @keyup="searchHouseholds"
                                    v-model="customFormFieldInstance.meta.household"
                                    :items="householdList"
                                    label="Select Household"
                                    placeholder="Type to search.."
                                    autocomplete="no"
                                    :return-object="false"
                                    clearable 
                                    clear-icon="tabler-x"
                                />

                            </div>

                            <div class="my-2 border rounded py-3 px-5" v-if="customFormFieldInstance.meta.layer_option.contacts != 'all'">
                                <label for="">Which branch contacts this field is for?</label>
                                <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.branch">
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.branch == 'custom'"
                                    @keyup="searchBranches"
                                    v-model="customFormFieldInstance.meta.branch"
                                    :items="branchList"
                                    label="Select Branch"
                                    :return-object="false"
                                    placeholder="Type to search.."
                                    clearable 
                                    clear-icon="tabler-x"
                                />
                            </div>

                        </div>
                    </VWindowItem>


                </VWindow>


                <div class="d-flex flex-wrap justify-center gap-4 mt-5">
                    <VBtn type="button" @click="saveFormFieldChanges">
                        Save Changes
                    </VBtn>
                </div>

            </VCardText>
        </VCard>
    </VDialog>

    <VDialog
        :width="$vuetify.display.smAndDown ? 'auto' : 677"
        v-model="quickHouseholdDialog"
    >
        <DialogCloseBtn @click="quickHouseholdDialog = false" />

        <VCard class="pa-sm-8 pa-5">
            <VCardItem class="text-center">
                <VCardTitle class="mb-3">
                    <h2>
                        <strong class="oswald">Add Household</strong>
                    </h2>
                </VCardTitle>
                <p class="mb-0 lato-light">You can create a new household here</p>
            </VCardItem>

            <VCardText>
                <VForm 
                    ref="quickHouseholdForm" 
                    class="mt-6"
                    @submit.prevent="createQuickHousehold"
                >
                    <VRow>
                        <VCol cols="12" md="6">
                            <AppTextField
                                v-model="quickHouseholdData.name"
                                label="Name *"
                                placeholder="Name"
                                :rules="[requiredValidator]"
                            />
                        </VCol>

                        <VCol cols="12" md="12">
                            <AppTextarea
                                v-model="quickHouseholdData.description"
                                rows="3"
                                label="Description"
                                placeholder="Description"
                            />
                        </VCol>

                        <VCol cols="12" class="d-flex flex-wrap justify-center gap-4">
                            <VBtn
                                type="submit"
                                :disabled="quickHouseholdSubmitBtn === 0"
                            >
                                Create
                            </VBtn>

                            <VBtn 
                                color="dark" 
                                variant="tonal" 
                                @click="quickHouseholdDialog = false"
                            >
                                Cancel
                            </VBtn>
                        </VCol>
                    </VRow>
                </VForm>
            </VCardText>
        </VCard>
    </VDialog>


    <DeleteConfirm v-model:deleteDialog="deleteFormFieldDialog" @closeDelete="deleteFormFieldDialog = false"
        @deleteItemConfirm="removeFormFieldSubmit" />


</template>

<style scoped>
.form_field_types_div {
    sup {
        inset-block-start: 9px;
    }
}

.form_field_types_div_bg {
    position: relative;
    background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
}

.drop-area-bg {
    background-color: rgba(var(--v-theme-on-background),
            var(--v-hover-opacity)) !important;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 5rem;
    z-index: 1;

}

.draggable-col-contacts-create {
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.draggable-col-contacts-create:active {
    cursor: grabbing;
}

.draggable-col-contacts-create * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.drop-area-bg {
    border: 2px dotted #ccc !important;
    background-color: rgba(var(--v-theme-on-background), var(--v-hover-opacity)) !important;
}
</style>