// js/stores/navigation.jg (use pinia)

import { defineStore } from 'pinia'

export const useNavigationStore = defineStore({
    id: 'navigation',
    state: () => ({
        routeActiveName: null,
    }),
    actions: {
        setRouteActiveName(name) {
            this.routeActiveName = name
        },
    },
})
