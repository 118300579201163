import { createApp } from 'vue'
import App from '@/App.vue'
import { registerPlugins } from '@core/utils/plugins'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
// Styles
import '@core-scss/template/index.scss'
import '@styles/styles.scss'

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Set Pusher as a global variable
window.Pusher = Pusher;
// Create Echo instance with Pusher configuration
if (useCookie('accessToken').value && !window.Echo) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY, // Use environment variable for security
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || 'mt1',
    wsHost: import.meta.env.VITE_PUSHER_HOST || `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT || 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT || 443,
    forceTLS: import.meta.env.VITE_PUSHER_SCHEME === 'https',
    enabledTransports: ['ws', 'wss'],
    auth: {
      headers: {
        Authorization: 'Bearer ' + useCookie('accessToken').value
      },
    },
  });
}

// Create vue app
const app = createApp(App)


// Register plugins
registerPlugins(app)
app.use(LoadingPlugin);

// Mount vue app
app.mount('#app')
