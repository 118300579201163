<script setup>
import TeamForm from "@/components/teams/TeamForm.vue";
import TeamsDataTable from "@/components/teams/TeamsDataTable.vue";
import { onMounted, ref } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import TeamCustomFieldsForm from "@/components/teams/TeamCustomFieldsForm.vue";

const $loading = useLoading(loaderData);

const errors = ref([]);
const headers = ref([
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Description",
    key: "description",
    sortable: true,
  },
  {
    title: "Users",
    key: "users",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const teams = ref([]);
const users = ref([]);
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");

const filterInfo = ref({});
const editedItem = ref({});

const isCustomFieldsDialogVisible = ref(false)
const customFormFields = ref([])
const isCanSeeManageCustomFieldsBtn = ref(false);
const system_form_field_types = ref([])

const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/teams`)
    .then((response) => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch((error) => {})
    .finally(() => {});
}

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const customFieldsUpdated = async () => {
  getCustomFormFields();
}
const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};
// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Team Custom Fields")
  );
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true;
  } else {
    isCanSeeManageCustomFieldsBtn.value = false;
  }
};
onMounted(async () => {
  canSeeCreateBtn();
  // get all users
  axios
    .get("get-all-users?teams=1")
    .then((response) => {
      if (response.status == "success") {
        users.value = Object.entries(response.data.users).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.first_name + " " + value.last_name,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});

    getSystemFormFieldTypes();
    canSeeManageCustomFieldsButton();
    getCustomFormFields();

});

const getTeams = async (payload = null) => {
  let url = `/teams?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (payload.role && payload.role.trim() !== "") {
    url += `&role=${payload.role}`;
  }
  if (payload.status == 1 || payload.status == 0) {
    url += `&status=${payload.status}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        teams.value = response.data.teams;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getTeams(filterInfo.value);
};

const roleChanged = async (payload) => {
  filterInfo.value.role = payload;
  getTeams(filterInfo.value);
};

const statusChanged = async (payload) => {
  filterInfo.value.status = payload;
  getTeams(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getTeams(filterInfo.value);
};

// Page changed from pagination
const refreshTeams = async () => {
  getTeams(filterInfo.value);
};

const editItem = async (item) => {
  const { users, custom_fields, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    users: users.map(loopItem => loopItem.id), // Convert branch_id from string to integer
    values: toRaw(item?.custom_fields)
  };
  modeModal.value = "Update";
  openModal.value = true;
};

const deleteItem = (item) => {
  const { users, custom_fields, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    users: users.map(loopItem => loopItem.id) // Convert branch_id from string to integer
  };
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/teams/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getTeams(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Team")
  );
  if (hasPermission) {
    isCanSeeCreateBtn.value = true;
  } else {
    isCanSeeCreateBtn.value = false;
  }
};

// expose errors and getTeams
defineExpose({
  errors,
  getTeams,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important;">
      <!-- Team Table Section -->
      <TeamsDataTable
        :headers="headers"
        :table-data="teams"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :canSeeManageCustomFieldsBtn="isCanSeeManageCustomFieldsBtn"
        @openCustomFieldsModal="openCustomFieldsModal"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @editItem="editItem"
        @deleteItem="deleteItem"
        @viewItem="viewItem"
      />
    </VCardText>
  </VCard>

  <!-- Modals Below -->

  <!-- User Edit/Create Modal -->
  <TeamForm
    v-model:isDialogVisible="openModal"
    :team-data="editedItem"
    @refreshTeams="refreshTeams"
    :mode="modeModal"
    :users="users"
    :status="status"
    :custom_fields="customFormFields"
  />
  <!-- User Delete Confirmation Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />

  <TeamCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :system_form_field_types="system_form_field_types"
    @customFieldsUpdated="customFieldsUpdated"
  />

</template>
