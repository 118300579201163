<script setup>
import WorkflowsTab from "@/components/workflows/WorkflowsTab.vue";
import DocumentTemplatesTab from "@/components/workflows/DocumentTemplatesTab.vue";
import FieldTemplatesTab from "@/components/workflows/FieldTemplatesTab.vue";
import { onMounted, ref } from "vue";
import "vue3-toastify/dist/index.css";

const hasViewWorkflowsPermission = ref(false)
const hasViewDocumentTemplatesPermission = ref(false)
const hasViewFieldTemplatesPermission = ref(false)

onMounted(() => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  
  const viewWorkflowsPermission = Object.values(
    userPermissionsByRoles
  ).some((permissions) => permissions.includes("View Workflows"));
  
  const viewDocumentTemplatesPermission = Object.values(
    userPermissionsByRoles
  ).some((permissions) => permissions.includes("View Document Templates"));

  const viewFieldTemplatesPermission = Object.values(
    userPermissionsByRoles
  ).some((permissions) => permissions.includes("View Field Templates"));
  
  if (viewDocumentTemplatesPermission) {
    hasViewDocumentTemplatesPermission.value = true 
  }

  if (viewWorkflowsPermission) {
    hasViewWorkflowsPermission.value = true 
  }

  if (viewFieldTemplatesPermission) {
    hasViewFieldTemplatesPermission.value = true 
  }

});

const currentTab = ref(0)

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <div>
    <VTabs v-model="currentTab" class="mb-1">
      <VTab v-if="hasViewWorkflowsPermission">Workflows</VTab>
      <VTab v-if="hasViewDocumentTemplatesPermission">Document Templates</VTab>
      <VTab v-if="hasViewFieldTemplatesPermission">Field Templates</VTab>
    </VTabs>
    <VCard title="" class="">
      <VCardText class="p-0" style="padding: 0 !important">
        <VWindow v-model="currentTab">
          <VWindowItem :value="0" v-if="hasViewWorkflowsPermission" >
            <WorkflowsTab />
          </VWindowItem>
          <VWindowItem v-if="hasViewDocumentTemplatesPermission" :value="1" >
            <DocumentTemplatesTab />
          </VWindowItem>
          <VWindowItem v-if="hasViewFieldTemplatesPermission" :value="1" >
            <FieldTemplatesTab />
          </VWindowItem>
        </VWindow>
      </VCardText>
    </VCard>
  </div>
</template>
