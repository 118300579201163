<script setup>
import { useRouter } from 'vue-router'
import NavbarThemeSwitcher from '@/layouts/components/NavbarThemeSwitcher.vue'
import { themeConfig } from '@themeConfig'
import { useConfigStore } from '@core/stores/config'

const router = useRouter()
const btnText = ref('Login')
const configStore = useConfigStore()

const goToLoginPage = () => {
  // Use the router's push method to navigate to the login page
  let token = useCookie("accessToken").value;
  if(token){
    router.push('/dashboard')
  }else{
    router.push('/login')
  }
}
const getToken = async () => {
    let token = useCookie("accessToken").value;
    if(token){
      btnText.value = 'Dashboard'
    }

}

onMounted(getToken);

definePage({ meta: { layout: 'blank' } });
</script>

<template>
  <div>
    <VRow>
    <VCol cols="4" md="4"> <!-- On medium screens and above, take 9 columns, on smaller screens take all 12 columns -->
      <img v-if="configStore.theme == 'light'" v-bind="themeConfig.app.logo.props" class="mx-5 my-5">
      <img v-else-if="configStore.theme == 'dark'" v-bind="themeConfig.app.darkLogo.props" class="mx-5 my-5">
    </VCol>
    <VCol cols="8" md="8"> <!-- On medium screens and above, take 3 columns, on smaller screens take all 12 columns -->
      <VBtn
        color="primary"
        variant="tonal"
        class="ma-5 float-end"
        @click="goToLoginPage"
      >
      {{ btnText }}
      </VBtn>
      <NavbarThemeSwitcher class="my-5 float-end" />
    </VCol>
  </VRow>
  </div>
</template>
