<script setup>
import OrganizationForm from "@/components/organizations/OrganizationForm.vue";
// import OrganizationsTable from "@/components/organizations/OrganizationsTable.vue";
import OrganizationsDataTable from "@/components/organizations/OrganizationsDataTable.vue";
import { onMounted, ref, watch } from "vue";
import { debounce } from "lodash";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import OrganizationFilters from "@/components/organizations/OrganizationFilters.vue";
import OrganizationCustomFieldsForm from "@/components/organizations/OrganizationCustomFieldsForm.vue";
const $loading = useLoading(loaderData);
const errors = ref([]);
const headers = ref([
  {
    title: "Name",
    key: "organization_name",
  },
  {
    title: "Description",
    key: "description",
  },
  {
    title: "Phone",
    key: "phone",
    sortable: false,
  },
  {
    title: "Branch",
    key: "branch",
  },
  {
    title: "Address Line 1",
    key: "address_line_1",
  },
  {
    title: "Address Line 2",
    key: "address_line_2",
  },
  {
    title: "Country",
    key: "country",
  },
  {
    title: "State",
    key: "state",
  },
  {
    title: "Zip Code",
    key: "zip_code",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);
const organizations = ref([]);
const branches = ref([]);
const states = ref([]);
const countries = ref([]);
const openModal = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");
const editedItem = ref({});
const filterInfo = ref({});
const deleteDialog = ref(false);
const isCustomFieldsDialogVisible = ref(false)
const customFormFields = ref([])
const isCanSeeManageCustomFieldsBtn = ref(false);
const system_form_field_types = ref([])

const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/organizations`)
    .then((response) => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch((error) => {})
    .finally(() => {});
}

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const customFieldsUpdated = async () => {
  getCustomFormFields();
}
const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};
// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Organization Custom Fields")
  );
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true;
  } else {
    isCanSeeManageCustomFieldsBtn.value = false;
  }
};
const getCountries = (search_query = "") => {
  axios
    .get(`/countries?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        countries.value = response.data.countries.map((country) => {
          return {
            title: country.name,
            value: country.id,
          };
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getBranches = (search_query = "") => {
  let current_user = useCookie("userData").value;
  if (
    current_user.branch_id
  ) {
    branches.value = [
      {
        title: current_user.branch.branch_name,
        value: current_user.branch.id,
      },
    ];
  } else {
    axios
      .get(`/branches?search_query=${search_query}&all=1`)
      .then((response) => {
        if (response.status == "success") {
          branches.value = response.data.branches.map((branch) => {
            return {
              title: branch.branch_name,
              value: branch.id,
            };
          });
        }
      })
      .catch((error) => {})
      .finally(() => {});
  }
};

const getStates = (country_id, search_query = "") => {
  axios
    .get(`get/states/${country_id}?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        states.value = response.data.states.map((state) => {
          return {
            title: state.name,
            value: state.id,
          };
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const canSeeCreateBtn = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Organizations")
  );
};

const getOrganizations = async (payload = null) => {
  let url = `/organizations?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (
    payload?.country != "" &&
    (payload?.country != undefined || payload?.country != null)
  ) {
    url += `&country=${payload.country}`;
  }
  if (
    payload?.state != "" &&
    (payload?.state != undefined || payload?.state != null)
  ) {
    url += `&state=${payload.state}`;
  }
  if (
    payload?.branch != "" &&
    (payload?.branch != undefined || payload?.branch != null)
  ) {
    url += `&branch=${payload.branch}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        organizations.value = response.data.organizations;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getOrganizations(filterInfo.value);
};

const countryChanged = async (payload) => {
  filterInfo.value.country = payload;
  getStates(payload)
  getOrganizations(filterInfo.value);
};

const stateChanged = async (payload) => {
  filterInfo.value.state = payload;
  getOrganizations(filterInfo.value);
};

const branchChanged = async (payload) => {
  filterInfo.value.branch = payload;
  getOrganizations(filterInfo.value);
};

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getOrganizations(filterInfo.value);
};

const editItem = (item) => {
  const { branch, custom_fields, country, state, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    country_id: parseInt(newItem.country_id),
    state_id: item.state ? parseInt(newItem.state_id) : "",
    branch_id: item.branch_id ? parseInt(newItem.branch_id) : "",
    country_id: (item.country_id) ? parseInt(item.country_id) : '',
    values: toRaw(item?.custom_fields)
  };
  modeModal.value = "Update";
  openModal.value = true;
};

const deleteItem = (item) => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/organizations/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        getOrganizations(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// Page changed from pagination
const refreshOrganizations = async () => {
  getOrganizations(filterInfo.value);
};

// load organizations when page loads
onMounted(async () => {
  getCountries();
  getBranches();
  canSeeCreateBtn();

  getSystemFormFieldTypes();
  canSeeManageCustomFieldsButton();
  getCustomFormFields();
});

// expose errors and getOrganizations
defineExpose({
  errors,
  getOrganizations,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">
      <!-- Organization Filters Section -->
      <OrganizationFilters
        :countries="countries"
        :states="states"
        :branches="branches"
        :width="4"
        @countryChanged="countryChanged"
        @stateChanged="stateChanged"
        @branchChanged="branchChanged"
      />
      <VDivider />

      <!-- Organization Table Section -->
      <OrganizationsDataTable
        :headers="headers"
        :table-data="organizations"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :canSeeManageCustomFieldsBtn="isCanSeeManageCustomFieldsBtn"
        @openCustomFieldsModal="openCustomFieldsModal"
        @isDialogVisible="openCreateModel"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @editItem="editItem"
        @deleteItem="deleteItem"
      />
    </VCardText>
  </VCard>

  <!-- Organization Create/Edit Modal -->
  <OrganizationForm
    v-model:isDialogVisible="openModal"
    :organization-data="editedItem"
    :branches="branches"
    :mode="modeModal"
    :custom_fields="customFormFields"
    @refreshOrganizations="refreshOrganizations"
  />
  <!-- Organization Delete Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />

  <OrganizationCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :system_form_field_types="system_form_field_types"
    @customFieldsUpdated="customFieldsUpdated"
  />
</template>
