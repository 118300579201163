<script setup>
import { onMounted, ref, watch, nextTick, onUnmounted } from "vue"
import axios from "@/utils/axios"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { useLoading } from "vue-loading-overlay"
import loaderData from "@/utils/loader-data"
import { useRouter } from "vue-router"
import interact from "interactjs"
import AppDateTimePicker from "@/@core/components/app-form-elements/AppDateTimePicker.vue"
import DeleteConfirm from "@/components/common/DeleteConfirm.vue"

const router = useRouter()
const $loading = useLoading(loaderData)

const companyData = ref({
  title: "",
  description: "",
  notes: "",
  address: "",
  address_2: "",
  contact_name: "",
  contact_phone: "",
  contact_email: "",
  general_phone: "",
  general_email: "",
  branch_codes: [],
  rep_codes: [],
})

const route = useRoute("investment-companies-id")
const submitBtn = ref(1)
const companyActiveTab = ref(0)

const branchCodes = ref([
  {
    title: '',
    description: '',
    branch_code: '',
    branches: [],
    temp_id: Math.random().toString(36).substr(2, 9),
  },
]) 

const repCodes = ref([
  {
    title: '',
    description: '',
    rep_code: '',
    users: [],
    branch_code_temp_id: '',
    branch_id: '',
  },
]) 

onUnmounted(() => {
  interact('.draggable-col-investment-companies-create').unset()
})
const isFieldsReady = ref(false)
const fieldOrders = ref([])
onMounted(async () => {
  try {
        // Fetch both field orders and custom fields
        const fieldOrdersResponse = await axios.get('/module-fields/investment_companies')

        if (fieldOrdersResponse.status === 'success') {
            fieldOrders.value = fieldOrdersResponse.data.field_orders
            isFieldsReady.value = true

            // Wait for DOM to update before reordering
            await nextTick()
            reorderFields()
        }
    } catch (error) {
        console.error('Error initializing fields:', error)
    }


    interact('.draggable-col-investment-companies-create')
        .draggable({
            autoScroll: true,
            onstart: (event) => { },
            onmove: (event) => {
                var target = event.target;
                var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                target.style.transform = "translate(" + x + "px, " + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
            },
            onend: (event) => {
                const target = event.target;
                const dropzone = event.dropzone;

                if (!dropzone) {
                    target.style.transform = `translate(0px, 0px)`;
                    target.setAttribute("data-x", 0);
                    target.setAttribute("data-y", 0);
                }
            },
        })
        .dropzone({
            accept: '.draggable-col-investment-companies-create',
            overlap: 0.1,
            ondragenter: function (event) {
                event.target.classList.add('drop-area-bg');
            },
            ondragleave: function (event) {
                event.target.classList.remove('drop-area-bg');
            },
            ondropp: (event) => {
                event.target.classList.remove('drop-area-bg');
                const draggedElement = event.relatedTarget;
                const targetElement = event.target;

                const draggedOrder = draggedElement.getAttribute('data-order');
                const targetOrder = targetElement.getAttribute('data-order');

                // update the field order
                axios.post('/module-fields/investment_companies/reorder', {
                    dragged_field: draggedOrder,
                    target_field: targetOrder
                })
                .then((response) => {
                    if (response.status == "success") {
                        console.log('Field order updated successfully');
                        fieldOrders.value = response.data.field_orders
                        nextTick(() => {
                            reorderFields();
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error updating field order:', error);
                });

                draggedElement.style.transform = `translate(0px, 0px)`;
                draggedElement.setAttribute("data-x", "0px");
                draggedElement.setAttribute("data-y", "0px");
           }
        });

})

const reorderFields = () => {
    const formRow = document.querySelector('.fields_row')
    if (!formRow || !fieldOrders.value?.fields) return

    // Get all field elements
    const allFieldElements = formRow.querySelectorAll('.draggable-col-investment-companies-create')
    if (!allFieldElements.length) return
    
    // Sort fields array by display_order
    const sortedFields = [...fieldOrders.value.fields].sort((a, b) => a.display_order - b.display_order)
    
    // Track which elements have been ordered
    const orderedElements = new Set()
    
    // First reorder elements that exist in fieldOrders
    sortedFields.forEach((fieldData) => {
        const fieldElement = formRow.querySelector(`[data-order="${fieldData.field}"]`)
        if (fieldElement) {
            formRow.appendChild(fieldElement)
            orderedElements.add(fieldElement)
        }
    })
    
    // Append any remaining elements (new fields) to the end
    allFieldElements.forEach(element => {
        if (!orderedElements.has(element)) {
            formRow.appendChild(element)
        }
    })
}

// Add a watch for fieldOrders to ensure reordering happens when data changes
watch(
    fieldOrders,
    () => {
        if (isFieldsReady.value) {
            nextTick(() => {
                reorderFields()
            })
        }
    },
    { deep: true }
)


const createInvestmentCompany = async () => {
  let loader = $loading.show()
  submitBtn.value = 0
  let formData = companyData.value;
  formData.branch_codes = branchCodes.value;
  formData.rep_codes = repCodes.value;

  axios
    .post("/investment_companies", formData)
    .then(response => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 5000,
        })

        discardChanges()

      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {
      loader.hide()
    })
    .finally(() => {
      loader.hide()
      submitBtn.value = 1
    })
}

const branchList = ref([])
const userList = ref([])

const searchBranches = event => {
  let branches = []
  branchCodes.value.forEach(item => {
    item.branches.forEach(itemBranch => {
        branches.push(itemBranch)
    })
  })

  repCodes.value.forEach(item => {
    branches.push(item.branch_id)
  })

  fetchBranchesForField(event.target.value, branches)
}

const searchUsers = event => {
  let include_users = []
  repCodes.value.forEach(item => {
    item.users.forEach(itemUser => {
        include_users.push(itemUser)
    })
  })
  fetchUsersForField(event.target.value, include_users)
}

const fetchBranchesForField = (search = '', includes=[]) => {
  let includes_to_arr = includes.join(',')
  axios
    .get(`/branches?search_query=${search}&includes=${includes_to_arr}`)
    .then(response => {
      if (response.status == "success") {
        branchList.value = response.data.branches.data.map(item => {
          return {
            title: item.branch_name,
            value: item.id,
          }
        })
      }
    })
    .catch(error => { })
    .finally(() => { })
}

const fetchUsersForField = (search = '', includes=[]) => {
  let includes_to_arr = includes.join(',')
  axios
    .get(`/users?search_query=${search}&includes=${includes_to_arr}&roles=representative_manager,representative`)
    .then(response => {
      if (response.status == "success") {
        userList.value = response.data.users.data.map(item => {
          return {
            title: item.first_name + ' ' + item.last_name,
            value: item.id,
          }
        })
      }
    })
    .catch(error => { })
    .finally(() => { })
}

const validateUsers = (index) => {
  let newlist = repCodes.value[index].users.filter(instanceItem => 
    userList.value.some(userItem => userItem.value === instanceItem),
  )
  repCodes.value[index].users = newlist
}

const validateBranches = (index) => {
  let newlist = branchCodes.value[index].branches.filter(instanceItem => 
    branchList.value.some(branchItem => branchItem.value === instanceItem),
  )
  branchCodes.value[index].branches = newlist
}

const discardChanges = () => {
  router.push({ name: "investment-companies-list" })
}

const addBranchCode = () => {
  branchCodes.value.push({
    title: '',
    description: '',
    branch_code: '',
    branches: [],
    temp_id: Math.random().toString(36).substr(2, 9),
  })
}

const removeBranchCode = index => {
  branchCodes.value.splice(index, 1)
}

const addRepCode = () => {
  repCodes.value.push({
    title: '',
    description: '',
    rep_code: '',
    users: [],
    branch_code_temp_id: '',
  })
}

const removeRepCode = index => {
  repCodes.value.splice(index, 1)
}

// on change of branch codes, update the rep code branch data temp ids
watch(branchCodes, (newBranchCodes, oldBranchCodes) => {
  // When branchCodes change, update the repCodes
  repCodes.value.forEach(repCode => {
    if (!newBranchCodes.find(branch => branch.temp_id === repCode.branch_code_temp_id)) {
      // If the branch_code_temp_id in repCodes doesn't exist in newBranchCodes, reset it
      repCode.branch_code_temp_id = newBranchCodes.length > 0 ? newBranchCodes[0].temp_id : '';
    }
  });
}, { deep: true });

const onPhoneInput = (event) => {
  let value = event.target.value.replace(/[^\d]/g, '');
  value = value.slice(0, 10);
  if (value.length >= 3) {
    value = value.slice(0, 3) + '-' + value.slice(3);
    if (value.length >= 7) {
      value = value.slice(0, 7) + '-' + value.slice(7);
    }
  }
  companyData.value[event.target.name] = value;
};

const phoneValidator = (value) => {
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  return phoneRegex.test(value) || !value || 'Please enter a valid phone number (XXX-XXX-XXXX)';
};

definePage({ meta: { requiresAuth: true } })
</script>

<template>
  <div>
    <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
      <div class="d-flex flex-column justify-center">
          <h2>
              <strong class="oswald">Add Investment Company</strong>
          </h2>
          <span class="lato-light">You can add investment company on this page</span>
      </div>
      
      <div class="d-flex gap-4 align-center flex-wrap">
        <VBtn
          variant="tonal"
          color="dark"
          @click.prevent="discardChanges"
        >
          Discard
        </VBtn>
        <VBtn @click.prevent="createInvestmentCompany">
          Create
        </VBtn>
      </div>
    </div>

    <VRow>
      <VCol cols="12">
        <VCard
          class="mb-6 "
          title=""
        >
          <VCardText>
            <VForm
              class=""
              @submit.prevent="createInvestmentCompany"
            >
              <VTabs
                v-model="companyActiveTab"
                class=" "
              >
                <VTab>Company Information</VTab>
                <VTab>Branch Codes</VTab>
                <VTab>Rep Codes</VTab>
              </VTabs>

              <VWindow v-model="companyActiveTab">
                <VWindowItem
                  :key="0"
                  :value="`item-${0}`"
                >
                  <div class="py-5">
                    <VRow class="fields_row pa-1 ma-1">
                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="title"
                      >
                        <AppTextField
                          v-model="companyData.title"
                          label="Title *"
                          placeholder="Title"
                          :rules="[requiredValidator]"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="12"
                        data-order="description"
                      >
                        <AppTextarea
                          v-model="companyData.description"
                          rows="2"
                          label="Description *"
                          placeholder="Description"
                          :rules="[requiredValidator]"
                        />
                      </VCol>

                      <!-- <VCol
                        cols="12"
                        md="6"
                      >
                        <AppCombobox
                          v-model="companyData.branches"
                          :items="branchList"
                          label="Select Branches"
                          placeholder="Type to search.."
                          :return-object="false"
                          clearable
                          multiple
                          chips
                          closable-chips
                          clear-icon="tabler-x"
                          @keyup="searchBranches"
                          @blur="validateBranches"
                        />
                      </VCol> -->

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="contact_name"
                      >
                        <AppTextField
                          v-model="companyData.contact_name"
                          label="Contact Name"
                          placeholder="Contact Name"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="contact_phone"
                      >
                        <AppTextField
                          v-model="companyData.contact_phone"
                          name="contact_phone"
                          label="Contact Phone"
                          placeholder="Contact Phone"
                          @input="onPhoneInput"
                          :rules="[phoneValidator]"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="contact_email"
                      >
                        <AppTextField
                          v-model="companyData.contact_email"
                          label="Contact Email"
                          placeholder="Contact Email"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="general_phone"
                      >
                        <AppTextField
                          v-model="companyData.general_phone"
                          name="general_phone"
                          label="General Phone"
                          placeholder="General Phone"
                          @input="onPhoneInput"
                          :rules="[phoneValidator]"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="6"
                        data-order="general_email"
                      >
                        <AppTextField
                          v-model="companyData.general_email"
                          label="General Email"
                          placeholder="General Email"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="12"
                        data-order="address"
                      >
                        <AppTextField
                          v-model="companyData.address"
                          label="Address Line 1"
                          placeholder="Address Line 1"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="12"
                        data-order="address_2"
                      >
                        <AppTextField
                          v-model="companyData.address_2"
                          label="Address Line 2"
                          placeholder="Address Line 2"
                        />
                      </VCol>

                      <VCol
                        class="draggable-col-investment-companies-create"
                        cols="12"
                        md="12"
                        data-order="notes"
                      >
                        <AppTextarea
                          v-model="companyData.notes"
                          rows="2"
                          label="Notes"
                          placeholder="Notes"
                        />
                      </VCol>
                    </VRow>
                  </div>
                </VWindowItem>

                <VWindowItem
                  :key="1"
                  :value="`item-${1}`"
                >
                  <div class="py-5">
                    <div style="text-align: right;">
                      <VBtn
                        size="small"
                        class="mb-2 "
                        @click="addBranchCode"
                      >
                        Add
                      </VBtn>
                    </div>

                    <VList lines="2">
                      <VListItem
                        v-for="(branch_code, index) in branchCodes"
                        :key="index"
                        style="
                                              background-color: rgba(
                                                var(--v-theme-on-background),
                                                var(--v-hover-opacity)
                                              ) !important;
                                            "
                        class="rounded pa-3 px-10 mb-3"
                      >
                        <VListItemTitle>
                          <VRow>
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <AppTextField
                                rows="1"
                                row-height="15"
                                v-model="branchCodes[index].title"
                                label="Title"
                                placeholder="Title"
                              />
                            </VCol> 
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <AppTextField
                                rows="1"
                                row-height="15"
                                v-model="branchCodes[index].branch_code"
                                label="Branch Code"
                                placeholder="Branch Code"
                              />
                            </VCol> 
                            <VCol
                              cols="12"
                              md="12"
                            >
                              <AppTextField
                                rows="1"
                                row-height="15"
                                v-model="branchCodes[index].description"
                                label="Description"
                                placeholder="Description"
                              />
                            </VCol> 

                            <VCol
                              cols="12"
                              md="12"
                            >
                              <AppCombobox
                                v-model="branchCodes[index].branches"
                                :items="branchList"
                                label="Select Branch Offices"
                                placeholder="Type to search.."
                                :return-object="false"
                                clearable
                                multiple
                                chips
                                closable-chips
                                clear-icon="tabler-x"
                                @keyup="searchBranches"
                                @blur="validateBranches(index)"
                              />
                            </VCol>

                          </VRow>
                        </VListItemTitle>
                        <div style="position:absolute; top:0; right:0; margin-top: -5px; margin-right: 10px;">
                          <VBtn
                            color="secondary"
                            icon
                            size="x-small"
                            @click="removeBranchCode(index)"
                          >
                            <VIcon icon="tabler-trash" />
                          </VBtn>
                        </div>
                      </VListItem>
                    </VList>
                  </div>
                </VWindowItem>

                <VWindowItem
                  :key="2"
                  :value="`item-${2}`"
                >
                  <div class="py-5">
                    <div style="text-align: right;">
                      <VBtn
                        size="small"
                        class="mb-2 "
                        @click="addRepCode"
                      >
                        Add
                      </VBtn>
                    </div>

                    <VList lines="2">
                      <VListItem
                        v-for="(rep_code, index) in repCodes"
                        :key="index"
                        style="
                                              background-color: rgba(
                                                var(--v-theme-on-background),
                                                var(--v-hover-opacity)
                                              ) !important;
                                            "
                        class="rounded pa-3 px-10 mb-3"
                      >
                        <VListItemTitle>
                          <VRow>
                            <VCol
                              class=""
                              cols="12"
                              md="4"
                            >
                              <AppTextField
                                v-model="repCodes[index].title"
                                label="Title"
                                placeholder="Title"
                              />
                            </VCol> 
                            <VCol
                              class=""
                              cols="12"
                              md="4"
                            >
                              <AppTextField
                                v-model="repCodes[index].rep_code"
                                label="Rep Code"
                                placeholder="Rep Code"
                              />
                            </VCol> 

                            <VCol
                              class=""
                              cols="12"
                              md="4"
                            >
                              <AppSelect
                                v-model="repCodes[index].branch_code_temp_id"
                                :items="branchCodes.map((branch_code) => {
                                    return {
                                        title: branch_code.title,
                                        value: branch_code.temp_id
                                    }
                                })"
                                label="Branch Code"
                                placeholder="Branch Code"
                                clearable
                            />
                            </VCol> 
                            
                            <VCol
                              class=""
                              cols="12"
                              md="4"
                            >
                              <AppCombobox
                                v-model="repCodes[index].branch_id"
                                :items="branchList"
                                label="Select Branch Office"
                                placeholder="Type to search.."
                                :return-object="false"
                                clearable
                                clear-icon="tabler-x"
                                @keyup="searchBranches"
                                @blur="validateBranches(index)"
                              />

                            </VCol> 

                            <VCol
                              class=""
                              cols="12"
                              md="12"
                            >
                              <AppCombobox
                                v-model="repCodes[index].users"
                                :items="userList"
                                label="Select Rep Users"
                                placeholder="Type to search.."
                                :return-object="false"
                                clearable
                                multiple
                                chips
                                closable-chips
                                clear-icon="tabler-x"
                                @keyup="searchUsers"
                                @blur="validateUsers(index)"
                              />
                            </VCol> 
                            <VCol
                              class=""
                              cols="12"
                              md="12"
                            >
                              <AppTextField
                                v-model="repCodes[index].description"
                                label="Description"
                                placeholder="Description"
                              />
                            </VCol>
                          </VRow>
                        </VListItemTitle>
                        <div style="position:absolute; top:0; right:0; margin-top: -5px; margin-right: 10px;">
                          <VBtn
                            color="secondary"
                            icon
                            size="x-small"
                            @click="removeRepCode(index)"
                          >
                            <VIcon icon="tabler-trash" />
                          </VBtn>
                        </div>
                      </VListItem>
                    </VList>
                  </div>
                </VWindowItem>
              </VWindow>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </div>
</template>

<style scoped>
.draggable-col-investment-companies-create:active {
    cursor: grabbing;
}

.draggable-col-investment-companies-create * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.drop-area-bg {
    border: 2px dotted #ccc !important;
    background-color: rgba(var(--v-theme-on-background), var(--v-hover-opacity)) !important;
}
</style>