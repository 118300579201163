<script setup>
import { onMounted, ref, watch } from "vue";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import { useRouter } from "vue-router";
import TicketChatLog from "@/components/helpdesk/tickets/TicketChatLog.vue";
import DynamicCustomField from "@/components/helpdesk/forms/DynamicCustomField.vue";

const router = useRouter();
const $loading = useLoading(loaderData);
const route = useRoute("helpdesk-ticket-id");

const ticket = ref({});
const form = ref({});
const messages = ref([]);
const ticket_values = ref([]);
const assignments = ref({
  users: [],
  teams: [],
  roles: [],
});
const users = ref([]);
const teams = ref([]);
const roles = ref([]);
const editAssignmentsModal = ref(false);
const ticketStatus = ref("submitted");
const ticketStatusList = [
  {
    title: "Submitted",
    value: "submitted",
  },
  {
    title: "Awaiting Response",
    value: "awaiting_response",
  },
  {
    title: "Closed",
    value: "closed",
  },
];

const filesInputEl = ref();

const sendMessage = async () => {
  if (
    !newMessage.value.message.trim() &&
    newMessage.value.medias.length === 0
  ) {
    return; // Prevent sending empty messages
  }

  const formData = new FormData();
  formData.append("message", newMessage.value.message);
  formData.append("form_submission_id", ticket.value.id);

  // Append files to FormData
  newMessage.value.medias.forEach((file) => {
    formData.append("medias[]", file);
  });

  newMessage.value.message = "";
  newMessage.value.medias = [];

  axios
    .post(`/helpdesk/ticket_responses`, formData)
    .then((response) => {
      if (response.status == "success") {
        let message = response.data.message;
        messages.value.push(message); // adding a new message in the chat
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const handleFileChange = (event) => {
  const files = event.target.files;

  if (!files || files.length === 0) {
    return;
  }
  newMessage.value.medias = Array.from(files);
};

const editFieldDialog = ref(false);
const currentEditField = ref({});
const updateFieldValue = async () => {
  try {
    const response = await axios.post(
      `/helpdesk/tickets/${ticket.value.id}/fields/${currentEditField.value.id}`,
      {
        field: currentEditField.value
      }
    );

    if (response.status === "success") {
      toast.success("Field value updated successfully", {
        autoClose: 3000,
      });
      editFieldDialog.value = false;
      axios
        .get(
          `/helpdesk/ticket_responses?form_submission_id=${id.value}`
        )
        .then((response) => {
          if (response.status == "success") {
            ticket_values.value = Object.values(
              response.data.ticket_responses.ticket_values
            ).sort((a, b) => a.display_order - b.display_order);
          }
        })
    } else {
      toast.error(response.message || "Failed to update field value", {
        autoClose: 3000,
      });
    }
  } catch (error) {
    toast.error("Failed to update field value", {
      autoClose: 3000,
    });
  }
};

const openFilePicker = () => {
  if (filesInputEl.value) {
    filesInputEl.value.click();
  } else {
    console.warn("Files input element is not yet available");
  }
};

const previousMessages = (param) => {
  getTicket(param);
};
const user = useCookie("userData").value;
const id = ref(route.params.id);
const total = ref();
const ticketUser = ref({});
const getTicket = async (param = {}) => {
  let loader = $loading.show();
  
  try {
    const response = await axios.get(
      `/helpdesk/ticket_responses`, {
        params: {
          form_submission_id: id.value,
          first_message_id: param.first_message_id,
          limit: param.limit
        }
      }
    );

    if (response.status == "success") {
      ticket.value = response.data.ticket_responses.ticket;
      form.value = response.data.ticket_responses.form;
      
      // If loading previous messages, prepend them to existing messages
      if (param.first_message_id) {
        messages.value = [...response.data.ticket_responses.messages, ...messages.value];
      } else {
        messages.value = response.data.ticket_responses.messages;
      }

      ticket_values.value = Object.values(
        response.data.ticket_responses.ticket_values
      ).sort((a, b) => a.display_order - b.display_order);
      total.value = response.data.ticket_responses.total;
      assignments.value = {
        users: response.data.ticket_responses.ticket.assignments.users || [],
        teams: response.data.ticket_responses.ticket.assignments.teams || [],
        roles: response.data.ticket_responses.ticket.assignments.roles || [],
      };
      ticketStatus.value = response.data.ticket_responses.ticket.status;
      ticketUser.value = response.data.ticket_responses.ticket.user;
    }
  } catch (error) {
    toast.error("Failed to load messages");
  } finally {
    loader.hide();
  }
};

const updateAssignments = function () {
  let loader = $loading.show();

  const payload = {
    assignments: {
      users: assignments.value.users || [],
      teams: assignments.value.teams || [],
      roles: assignments.value.roles || [],
    },
  };

  axios
    .post(`helpdesk/ticket_assignments/${ticket.value.id}`, payload)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 3000,
        });
        editAssignmentsModal.value = false;
      } else {
        toast.error(response.message, {
          autoClose: 3000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
      toast.error("Failed to update assignments", {
        autoClose: 3000,
      });
    })
    .finally(() => {
      loader.hide();
    });
};

const updateTicket = () => {

  axios
    .patch(`helpdesk/tickets/${ticket.value.id}`, {
      status: ticketStatus.value,
    })
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 3000,
        });
        // loader.hide();
      } else {
        toast.error(response.message, {
          autoClose: 3000,
        });
      }
    })
    .catch((error) => {
      // loader.hide();
    })
    .finally(() => {
      // loader.hide();
    });
};

const getUsers = async () => {
  axios
    .get("get-all-users")
    .then((response) => {
      if (response.status == "success") {
        users.value = Object.entries(response.data.users).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.first_name + " " + value.last_name,
              user_profile: value.user_profile,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getRoles = (search_query = "") => {
  axios
    .get("get-all-roles")
    .then(async (response) => {
      if (response.status == "success") {
        roles.value = Object.entries(response.data.roles).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const fileInput = ref(null);
const ticketFieldValue = ref();
const uploadFile = (ticket_value) => {
  ticketFieldValue.value = ticket_value;
  if (fileInput.value[0]) {
    fileInput.value[0].click();
  }
}

const uploadingFile = ref(false);
const uploadingFileProgress = ref(0);
const handleFieldFileChange = (event) => {
  uploadingFile.value = true;
  uploadingFileProgress.value = 0;
  const files = event.target.files;
  if (!files || files.length === 0) {
    return;
  }

  const formData = new FormData();
  formData.append('file', files[0]);

  axios.post(`/helpdesk/tickets/${ticket.value.id}/fields/${ticketFieldValue.value.id}/upload_file`, 
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        uploadingFileProgress.value = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      }
    }
  ).then(response => {
    uploadingFile.value = false;

    axios
      .get(
        `/helpdesk/ticket_responses?form_submission_id=${id.value}`
      )
      .then((response) => {
        if (response.status == "success") {
          ticket_values.value = Object.values(
            response.data.ticket_responses.ticket_values
          ).sort((a, b) => a.display_order - b.display_order);
          // success message
          toast.success('File has been uploaded successfully.', {
            autoClose: 3000,
          });
        }else{
          toast.error(response.message, {
            autoClose: 3000,
          });
        }
      })
      
  }).catch(error => {
    console.error('error', error);
    uploadingFile.value = false;
  });
}


const getTeams = (search_query = "") => {
  axios
    .get("get-all-teams")
    .then(async (response) => {
      if (response.status == "success") {
        teams.value = Object.entries(response.data.teams).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.name,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const newMessage = ref({
  message: "",
  medias: [],
});

const removeSelectedFile = (index) => {
  newMessage.value.medias.splice(index, 1);
};

const getFieldValue = (ticket_value) => {
  if (ticket_value.values) {
    // if value is array, return comma separated values
    if (Array.isArray(ticket_value.values)) {
      return ticket_value.values.join(", ");
    }
    return ticket_value.values;
  }
  return ticket_value.values;
};

const showTicketValue = (ticket_value) => {
  return true;
};

const canEditField = (ticket_value) => {
  console.log('ticket_value.has_edit_permission', ticket_value.has_edit_permission);
  console.log('ticket_value.has_edit_permission === true', ticket_value.has_edit_permission === true);
  return ticket_value.has_edit_permission === true;
};

const loadingFieldOptions = ref(false);
const openEditFieldDialog = (ticket_value) => {

  if (['pick_list', 'multi_select'].includes(ticket_value.type)) {
    axios.get(`/helpdesk/get-field-options?form_field_id=${ticket_value.id}`)
      .then(response => {
        if (response.data.options) {
          const transformedOptions = response.data.options.map(option => ({
            value: option.id,
            title: option.name
          }));
          currentEditField.value = {
            ...ticket_value,
            options: transformedOptions
          };
          editFieldDialog.value = true;
        }
      })
      .catch(error => {
        console.error('Error loading options:', error);
        toast.error("Failed to load field options", {
          autoClose: 3000,
        });
      });
  } else {
    // For non-select fields, just open the dialog
    currentEditField.value = {
      ...ticket_value,
    };
    editFieldDialog.value = true;
  }
};

onMounted(async () => {
  getTicket();
  const userData = useCookie("userData").value;
  if (userData.role == "super_admin") {
    getUsers();
    getTeams();
    getRoles();
  }

  if (window.Echo) {
    let alredy_registered = Object.keys(
      window.Echo.connector.channels
    ).includes("private-ticket_responses." + id.value);
    if (!alredy_registered) {
      window.Echo.private("ticket_responses." + id.value).listen(
        ".new.ticket_response",
        (e) => {
          if (e.user.id != user.id) {
            messages.value.push(e); // adding a new message in the chat
          }
        }
      );
    }
  }
});

const goBack = () => {
  router.push({ name: "helpdesk-tickets", params: {} });
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VRow>
    <VCol cols="12" md="12">
      <VCard title="" class="">
        <VCardText class="p-0" style="padding: 0 !important">
          <div class="mb-5 mt-5 d-flex justify-space-between px-3">
            <VBtn variant="plain" @click.prevent="goBack">
              <VIcon start icon="tabler-arrow-left" />
              Back
            </VBtn>
            <div>
              <VBtn
                v-if="user.role == 'super_admin'"
                variant="plain"
                @click="editAssignmentsModal = !editAssignmentsModal"
              >
                <VIcon start icon="tabler-edit" />
                Edit Assignments
              </VBtn>
            </div>
          </div>

          <VRow class="px-7 mb-3">
            <VCol cols="12" md="12">
              <div class="rounded border px-10 py-5">
                <VRow class="">
                  <VCol
                    cols="12"
                    md="2"
                    :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
                  >
                    <VCardText
                      class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                    >
                      <h3 class="oswald">Ticket ID</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          ticket.ticket_id
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    cols="12"
                    md="2"
                    :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
                  >
                    <VCardText
                      class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                    >
                      <h3 class="oswald">Date</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          ticket.date
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    cols="12"
                    md="2"
                    :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
                  >
                    <VCardText
                      class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                    >
                      <h3 class="oswald">Time</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          ticket.time
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    cols="12"
                    md="3"
                    :class="$vuetify.display.mdAndUp ? 'border-e' : 'border-b'"
                  >
                    <div class="">
                      <div v-if="user.role == 'super_admin' || ticket.assigned">
                        <VCardText
                          class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                        >
                          <h3 class="oswald">Status</h3>
                          <div>
                            <AppSelect
                              v-model="ticketStatus"
                              :items="ticketStatusList"
                              placeholder="Status"
                              density="compact"
                              @update:modelValue="updateTicket"
                            />
                          </div>
                        </VCardText>
                      </div>
                      <div v-else class="">
                        <VCardText
                          class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                        >
                          <h3 class="oswald">Status</h3>
                          <div>
                            <strong>
                              <VChip
                                color="success"
                                v-if="ticket.status == 'submitted'"
                              >
                                <VIcon start icon="tabler-circle-check" />
                                {{ ticket.status_readable }}
                              </VChip>

                              <VChip
                                color="error"
                                v-else-if="ticket.status == 'closed'"
                              >
                                <VIcon start icon="tabler-browser-x" />
                                {{ ticket.status_readable }}
                              </VChip>

                              <VChip
                                v-else-if="ticket.status == 'awaiting_response'"
                              >
                                <VIcon start icon="tabler-reload" />
                                {{ ticket.status_readable }}
                              </VChip>
                            </strong>
                          </div>
                        </VCardText>
                      </div>
                    </div>
                  </VCol>
                  <VCol cols="12" md="3">
                    <VCardText
                      class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                    >
                      <h3 class="oswald">Created By</h3>
                      <div>
                        <div class="d-flex align-center">
                          <VAvatar
                            size="34"
                            :variant="
                              !ticketUser.user_profile ? 'tonal' : undefined
                            "
                            :color="
                              !ticketUser.user_profile
                                ? resolveUserRoleVariant(ticketUser.role).color
                                : undefined
                            "
                            class="me-3"
                          >
                            <VImg
                              v-if="ticketUser.user_profile"
                              :src="ticketUser.user_profile"
                            />
                            <span v-else>{{
                              avatarText(ticketUser.first_name)
                            }}</span>
                          </VAvatar>
                          <div class="d-flex flex-column">
                            <h4 class="oswald font-weight-medium">
                              {{ ticketUser.first_name }}
                              {{ ticketUser.last_name }}
                            </h4>
                            <span
                              class="text-sm text-medium-emphasis lato-light"
                              >{{ ticketUser.email }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </VCardText>
                  </VCol>
                </VRow>
              </div>
            </VCol>
          </VRow>

          <VRow class="px-7 my-1">
            <VCol cols="12" md="3">
              <div
                v-for="(ticket_value, index) of ticket_values"
                :key="index"
                class="mt-2"
              >
                <div v-if="showTicketValue(ticket_value)">
                  <VCardText
                    class="d-flex gap-y-1 flex-column ma-0 pa-0 text-body-1 pb-1"
                  >
                    <h3 class="oswald">
                      {{ ticket_value.form_field_name }}
                    </h3>
                    <div>
                      <div v-if="ticket_value.type == 'file_upload'">
                        <a
                          :download="
                            JSON.parse(ticket_value.values[0]).file_name
                          "
                          target="_blank"
                          style="text-align: center"
                          rel="noopener noreferrer"
                          :href="JSON.parse(ticket_value.values[0]).file_url"
                        >
                          <VBtn variant="tonal" size="small">
                            {{ JSON.parse(ticket_value.values[0]).file_name }}
                            <VIcon end icon="tabler-download" />
                          </VBtn>
                        </a>
                        <VBtn
                          v-if="canEditField(ticket_value) && !uploadingFile"
                          class="ml-2"
                          variant="tonal"
                          size="x-small"
                          color="light"
                          icon="tabler-upload"
                          @click="uploadFile(ticket_value)"
                        />
                        <VProgressCircular
                          v-if="uploadingFile"
                          :model-value="uploadingFileProgress"
                          color="primary"
                          class="ml-2"
                        />
                        <input type="file" ref="fileInput" style="display: none" @change="handleFieldFileChange" />
                      </div>
                      <div v-else class="d-flex align-center justify-space-between field-value-container"> 
                        <span class="text-primary lato-light field-value">{{
                          getFieldValue(ticket_value)
                        }}</span>
                        <VBtn
                          v-if="canEditField(ticket_value)"
                          variant="text"
                          size="small"
                          @click="openEditFieldDialog(ticket_value)"
                        >
                          <VIcon v-if="!loadingFieldOptions" start icon="tabler-pencil" />
                          <VProgressCircular
                            v-if="loadingFieldOptions"
                            indeterminate
                            size="16"
                            color="primary"
                          />
                          <span v-else>Edit</span>
                        </VBtn>
                      </div>
                    </div>
                  </VCardText>

                  <VDivider />
                </div>
              </div>
            </VCol>
            <VCol cols="12" md="9">
              <div
                class="rounded mt-3"
                style="
                  background: rgb(var(--v-theme-background));
                  height: 600px;
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                "
              >
                <!-- Chat log -->
                <TicketChatLog
                  @previousMessages="previousMessages"
                  :total="total"
                  :messageList="messages"
                />

                <div class="mb-1 text-right mr-5">
                  <div class="selected-files">
                    <div
                      v-for="(file, index) of newMessage.medias"
                      :key="index"
                      class="selected-file"
                      style="display: inline-block"
                    >
                      <VChip :label="false" class="mx-1 mt-1">
                        <span class="mr-2">{{ file.name }}</span>
                        <VBtn
                          size="25"
                          variant="plain"
                          icon="tabler-x"
                          class=""
                          @click="removeSelectedFile(index)"
                        />
                      </VChip>
                    </div>
                  </div>
                </div>

                <VForm
                  @submit.prevent="sendMessage"
                  class="mb-5 mx-5"
                  v-if="ticketStatus != 'closed'"
                >
                  <VTextField
                    v-model="newMessage.message"
                    class="chat-message-input"
                    placeholder="Type your message..."
                    density="default"
                  >
                    <template #append-inner>
                      <IconBtn class="me-2" @click="openFilePicker">
                        <VIcon icon="tabler-paperclip" />
                      </IconBtn>

                      <div>
                        <VBtn type="button" @click.prevent="sendMessage">
                          Send
                          <VIcon class="ml-2 mr-0" start icon="tabler-send-2" />
                        </VBtn>
                      </div>
                    </template>
                  </VTextField>
                  <input
                    ref="filesInputEl"
                    type="file"
                    name="file"
                    multiple
                    style="display: none"
                    @change="handleFileChange"
                  />
                </VForm>
              </div>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>

  <VDialog v-model="editAssignmentsModal" max-width="500">
    <DialogCloseBtn @click="editAssignmentsModal = !editAssignmentsModal" />
    <VCard :title="`Edit Assignments`">
      <VCardText>
        <div>
          Assigned Users
          <AppSelect
            v-model="assignments.users"
            :items="users"
            placeholder="Assigned Users"
            variant="plain"
            chips
            multiple
            closable-chips
          />
        </div>

        <div class="mt-3">
          Assigned Teams
          <AppSelect
            v-model="assignments.teams"
            :items="teams"
            placeholder="Assigned Teams"
            variant="plain"
            chips
            multiple
            closable-chips
          />
        </div>

        <div class="mt-3">
          Assigned Roles
          <AppSelect
            v-model="assignments.roles"
            :items="roles"
            placeholder="Assigned Roles"
            variant="plain"
            chips
            multiple
            closable-chips
          />
        </div>

        <div class="text-center mt-10">
          <VBtn color="primary" @click.prevent="updateAssignments()">
            Update
          </VBtn>
        </div>
      </VCardText>
    </VCard>
  </VDialog>

  <VDialog v-model="editFieldDialog" max-width="500">
    <DialogCloseBtn @click="editFieldDialog = false" />
    <VCard :title="`Edit ${currentEditField.form_field_name}`">
      <VCardText>
        <DynamicCustomField
          v-if="currentEditField"
          :form_field="currentEditField"
          v-model="currentEditField.value"
          :options="currentEditField.options"
          @pickListUpdated="handlePickListUpdate"
        />
        <div class="text-center mt-5">
          <VBtn color="primary" @click="updateFieldValue">
            Save
          </VBtn>
        </div>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style scoped>
.head-01 {
  display: flex;
  justify-content: right;
}

.field-value-container {
  overflow: hidden;
  width: 100%;
  gap: 8px; /* Space between value and edit button */
}

.field-value {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  flex: 1;
  min-width: 0; /* Important for flex items to allow wrapping */
  padding-right: 8px; /* Space before edit button */
}
</style>
