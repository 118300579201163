<script setup>
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { useGenerateImageVariant } from "@core/composable/useGenerateImageVariant"
import authV2LoginIllustrationBorderedDark from "@images/pages/auth-v2-login-illustration-bordered-dark.png"
import authV2LoginIllustrationBorderedLight from "@images/pages/auth-v2-login-illustration-bordered-light.png"
import authV2LoginIllustrationDark from "@images/pages/auth-v2-login-illustration-dark.png"
import authV2LoginIllustrationLight from "@images/pages/auth-v2-login-illustration-light.png"
import authV2MaskDark from "@images/pages/misc-mask-dark.png"
import authV2MaskLight from "@images/pages/misc-mask-light.png"
import { VNodeRenderer } from "@layouts/components/VNodeRenderer"
import { themeConfig } from "@themeConfig"
import axios from "@/utils/axios"
import { useConfigStore } from '@core/stores/config'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { useRouter } from "vue-router"

const router = useRouter()

definePage({ meta: { layout: "blank" } })

const form = ref({
  email: "",
  password: "",
  remember: false,
  two_factor_code: '',
})

const pageView = ref('login') // 2fa or login

const configStore = useConfigStore()
const isPasswordVisible = ref(false)

const authThemeImg = useGenerateImageVariant(
  authV2LoginIllustrationLight,
  authV2LoginIllustrationDark,
  authV2LoginIllustrationBorderedLight,
  authV2LoginIllustrationBorderedDark,
  true,
)

const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark)
const errors = ref([])
const isLoading = ref(false)

const doLogin = async () => {
  if (form.value.email == "" || form.value.password == "") {
    toast.error("Email and password is required!", {
      autoClose: 2000,
    })
    
    return
  }
  isLoading.value = true
  axios
    .post("/auth/login", {
      email: form.value.email,
      password: form.value.password,
      remember: form.value.remember,
      two_factor_code: form.value.two_factor_code,
      action: pageView.value,
    })
    .then(response => {
      if (response.status == 'success') {

        toast.success(response.message, {
          autoClose: 2000,
        })

        if(response.data.twoFactor == 1){
          pageView.value = '2fa'
        }else{

          useCookie("userData").value = response.data.userData
          useCookie("accessToken").value = response.data.accessToken
          localStorage.setItem("token", response.data.accessToken)

          // Set Pusher as a global variable
          window.Pusher = Pusher

          // Create Echo instance with Pusher configuration
          if (!window.Echo) {
            try {
              window.Echo = new Echo({
                broadcaster: 'pusher',
                key: import.meta.env.VITE_PUSHER_APP_KEY, // Use environment variable for security
                cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || 'mt1',
                wsHost: import.meta.env.VITE_PUSHER_HOST || `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
                wsPort: import.meta.env.VITE_PUSHER_PORT || 80,
                wssPort: import.meta.env.VITE_PUSHER_PORT || 443,
                forceTLS: import.meta.env.VITE_PUSHER_SCHEME === 'https',
                enabledTransports: ['ws', 'wss'],
                auth: {
                  headers: {
                    Authorization: 'Bearer ' + response.data.accessToken,
                  },
                },
              })
            } catch (error) {
            }
          } else {
          }
          router.push("/dashboard")
        }
        
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      isLoading.value = false
    })
}
</script>

<template>
  <VRow
    no-gutters
    class="auth-wrapper bg-surface"
  >
    <VCol
      md="8"
      class="d-none d-md-flex"
    >
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="505"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg
          class="auth-footer-mask"
          :src="authThemeMask"
        />
      </div>
    </VCol>

    <VCol
      cols="12"
      md="4"
      class="auth-card-v2 d-flex align-center justify-center"
    >
      <VCard
        flat
        :width="500"
        class="mt-12 mt-sm-0 pa-4"
      >
        <VCardText>
          <VNodeRenderer
            :nodes="configStore.theme == 'dark' ? themeConfig.app.darkLogo : themeConfig.app.logo"
            class="mb-6"
          />
          <h2 class="">
            <strong class="oswald">Welcome to KWC! 👋🏻</strong>
          </h2>
          <p class="mb-0 ">
            <span v-if="pageView == 'login' " class="lato-light">Please sign-in to your account below</span>
            <span v-else>Confirm 2FA code to proceed</span>
          </p>
        </VCardText>
        <VCardText>
          <VForm @submit.prevent="doLogin">
            <VRow>
              <!-- email -->
              <VCol
                v-show="pageView == 'login' "
                cols="12"
              >
                <AppTextField
                  v-model="form.email"
                  autofocus
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- password -->
              <VCol
                v-show="pageView == 'login' "
                cols="12"
              >
                <AppTextField
                  v-model="form.password"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
                <div class="d-flex align-center flex-wrap justify-space-between mt-2 mb-4">
                  <VCheckbox
                    v-show="pageView == 'login' "
                    v-model="form.remember"
                    label="Remember me"
                  />
                  <a
                    class="text-primary ms-2 mb-1"
                    href="auth/password-reset/forgot-password"
                  >
                    Forgot Password?
                  </a>
                </div>
              </VCol>
              <VCol
                v-show="pageView == '2fa' "
                cols="12"
              >
                <AppTextField
                  v-model="form.two_factor_code"
                  label="2FA Code"
                  placeholder="Enter 2FA Code"
                />
              </VCol>

              <VCol cols="12">
                <VBtn
                  block
                  type="submit"
                  :disabled="isLoading"
                >
                  <span v-if="!isLoading" > {{ (pageView == 'login') ? "Login" : "Submit" }} </span>
                  <VProgressCircular
                    v-if="isLoading"
                    indeterminate
                    color="white"
                  />
                </VBtn>
              </VCol>
            </VRow>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
