<script setup>
    import { onMounted, ref, watch, nextTick, onUnmounted } from "vue";
    import axios from "@/utils/axios";
    import { toast } from "vue3-toastify";
    import "vue3-toastify/dist/index.css";
    import { useLoading } from "vue-loading-overlay";
    import loaderData from "@/utils/loader-data";
    import { useRouter } from "vue-router";
    import interact from "interactjs";
    import AppDateTimePicker from "@/@core/components/app-form-elements/AppDateTimePicker.vue";
    import DeleteConfirm from "@/components/common/DeleteConfirm.vue";

    const router = useRouter();
    const $loading = useLoading(loaderData);

    const userData = ref({
        first_name: "",
        last_name: "",
        role: "",
        email: "",
        password: "",
        phone: "",
        title: "",
        branch_id: "",
        status: "",
        internal_rep_code: "",
        is_assistant: false,
        values: [],
    });

    const route = useRoute("user-management-id");
    const allowed_roles = ref([]);
    const roles = ref([]);
    const branches = ref([]);
    const submitBtn = ref(1);
    const isPasswordVisible = ref(false);

    const statusList = ref([
        {
            title: "Active",
            value: 1,
        },
        {
            title: "Suspend",
            value: 0,
        },
    ]);

    const lookup_fields = ref([]);
    const modules = ref([])
    const getLookupFields = () => {
      axios
        .get("lookup_fields")
        .then(async (response) => {
          if (response.status == "success") {
            lookup_fields.value = response.data.lookup_fields
            modules.value = Object.keys(response.data.lookup_fields).map(key => ({
              title: response.data.lookup_fields[key].name,
              value: key
            }));
          }
        })
        .catch((error) => {})
        .finally(() => {});
    };

    const lookupFieldAttributes = computed(() => {

        let attributes = [];
          if(customFormFieldInstance.value.table){
            let lookup_field_columns = lookup_fields.value[customFormFieldInstance.value.table].columns
            console.log('lookup_field_columns', lookup_field_columns)
            attributes = Object.keys(lookup_field_columns).map(key => ({
              title: lookup_field_columns[key],
              value: key
            }));
            let attribute_keys = Object.keys(lookup_field_columns);
            if(!attribute_keys.includes(customFormFieldInstance.value.column)){
              customFormFieldInstance.value.column = null
            }
          }else{
            attributes = [];
          }
          return attributes;

    })

    const fieldLookupSettingChanged = () => {
      customFormFieldInstance.value.options = [];
    }


    const customFieldPickListUpdatedExecute = (form_field) => {

        if(form_field.is_lookup_field && (form_field.parent_lookup_field_id == 0 || form_field.act_as_parent)){
            
            let parentField = userData.value.values.find((value) => value.form_field_id == form_field.id);
            let parent_value = parentField ? parentField.value : null;
            if(parent_value){
                axios
                    .get(
                        "/custom_fields/get_lookup_field_record" + `?table=${form_field.table}&id=${findFormFieldValue(form_field.id).value}`
                    )
                    .then((response) => {
                        if (response.status == "success") {
                            // if child who act as parent is changed
                            if(form_field.act_as_parent){
                                // update parent field
                                const parentField = customFormFields.value.filter(f => f.id === form_field.parent_lookup_field_id)
                                parentField.forEach(element => {
                                    userData.value.values.forEach((val) => {
                                        if(val.form_field_id == element.id){
                                            val.value = response.data.model['id']
                                        }
                                    })
                                });
                                // update fields that are child of parent
                                const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.parent_lookup_field_id)
                                matchingFields.forEach((field) => {
                                    userData.value.values.forEach((val) => {
                                        if(val.form_field_id == field.id){
                                            if(field.act_as_parent){
                                                val.value = response.data.model['id']
                                            }else{
                                                val.value = response.data.model[field.column]
                                            }
                                        }
                                    })
                                })
                            }else{
                                // if parent is changed
                                const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.id)
                                matchingFields.forEach((field) => {
                                    userData.value.values.forEach((val) => {
                                        if(val.form_field_id == field.id){
                                            if(field.act_as_parent){
                                                val.value = response.data.model['id']
                                            }else{
                                                val.value = response.data.model[field.column]
                                            }
                                        }
                                    })
                                })
                            }
                        }
                    })
                    .catch((error) => { })
                    .finally(() => { });
            }
            
        }
    }

    const customFieldPickListUpdated = (form_field) => {
        setTimeout(() => {
            customFieldPickListUpdatedExecute(form_field);
        }, 50);
    }


    const rolesForField = ref([])

    const fetchRoles = () => {
        axios
            .get(`/get-all-roles-list`)
            .then((response) => {
                if (response.status == "success") {
                    rolesForField.value = response.data.roles.map((role) => {
                        return {
                            title: role.readable_name,
                            value: role.id,
                        };
                    });
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }


    const teamsForField = ref([]);
    const searchTeamsForField = (event) => {
        let teams = customFormFieldInstance.value.teams.map(item => item.value ?? item)
        fetchTeams(event.target.value, teams)
    }
    const searchTeamsForFieldOnChange = () => {
        let teams = [];
        let instanceTeams = customFormFieldInstance.value.teams ?? []
        if(!Array.isArray(instanceTeams)){
            instanceTeams = [instanceTeams]
        }
        instanceTeams.forEach((team) => {
            if(team.value){
                teams.push(team.value)
            }else{
                teams.push(team)
            }
        })
        fetchTeams('', teams)
    }
    const fetchTeams = (search = '', includes=[]) => {
        let includes_to_arr = includes.join(',');
        axios
            .get(`/teams?search_query=${search}&includes=${includes_to_arr}`)
            .then((response) => {
                if (response.status == "success") {
                    teamsForField.value = response.data.teams.data.map((team) => {
                        return {
                            title: team.name,
                            value: team.id,
                        };
                    });
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }

    const usersForField = ref([]);
    const searchUsersForFieldOnChange = () => {
        let users = [];
        customFormFieldInstance.value.users.forEach((user) => {
            if(user.value){
                users.push(user.value)
            }else{
                users.push(user)
            }
        })
        fetchUsers('', users)
    }
    const searchUsersForField = (event) => {
        let users = customFormFieldInstance.value.users.map(item => item.value ?? item)
        fetchUsers(event.target.value, users)
    }
    const fetchUsers = (search = '', includes=[]) => {
        let includes_to_arr = includes.join(',');
        axios
            .get(`/users?search_query=${search}&includes=${includes_to_arr}`)
            .then((response) => {
                if (response.status == "success") {
                    usersForField.value = response.data.users.data.map((user) => {
                        return {
                            title: user.first_name + ' ' + user.last_name,
                            value: user.id,
                        };
                    });
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }

    const branchesForField = ref([]);
    const searchBranches = (event) => {
        fetchBranches(event.target.value, [userData.value.branch_id])
    }
    const searchBranchesForField = (event) => {

        let branches = [];
        let instanceBranches = customFormFieldInstance.value.branches ?? []
        if(!Array.isArray(instanceBranches)){
            instanceBranches = [instanceBranches]
        }
        instanceBranches.forEach((branch) => {
            if(branch.value){
                branches.push(branch.value)
            }else{
                branches.push(branch)
            }
        })

        // let branches = customFormFieldInstance.value.branches.map(item => item.value ?? item)
        fetchBranches(event.target.value, branches, 'field_data')
    }
    const searchBranchesForFieldOnChange = () => {
        let branches = [];
        let instanceBranches = customFormFieldInstance.value.branches ?? []
        if(!Array.isArray(instanceBranches)){
            instanceBranches = [instanceBranches]
        }
        instanceBranches.forEach((branch) => {
            if(branch.value){
                branches.push(branch.value)
            }else{
                branches.push(branch)
            }
        })
        
        fetchBranches('', branches, 'field_data')
    }
    const fetchBranches = (search = '', includes=[], fetch_for='user_data') => {
        let includes_to_arr = includes.join(',');
        axios
            .get(`/branches?search_query=${search}&includes=${includes_to_arr}`)
            .then((response) => {
                if (response.status == "success") {
                    if(fetch_for == 'user_data'){
                        branches.value = response.data.branches.data.map((branch) => {
                            return {
                                title: branch.branch_name,
                                value: branch.id,
                            };
                        });
                    }
                    else if(fetch_for == 'field_data'){
                        branchesForField.value = response.data.branches.data.map((branch) => {
                            return {
                                title: branch.branch_name,
                                value: branch.id,
                            };
                        });
                    }
                    
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }

    const getUserData = function () {
        let id = route.params.id;
        let loader = $loading.show();
        let url = `/users/${id}`;
        axios
            .get(url)
            .then((response) => {
                if (response.status == "success") {
                    userData.value = response.data.user;
                    userData.value.values = response.data.user.custom_fields;
                    fetchBranches('', [userData.value.branch_id]);
                } else {
                    toast.error(response.message, {
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                loader.hide();
            })
            .finally(() => {
                loader.hide();
            });
    }

    const system_form_field_types = ref([])
    const getSystemFormFieldTypes = (search_query = "") => {
        axios
            .get(`/system_form_field_types`)
            .then((response) => {
                if (response.status == "success") {
                    system_form_field_types.value = response.data.field_types
                }
            })
            .catch((error) => { })
            .finally(() => { });
    };

    onUnmounted(() => {
      interact('.form_field_edit_user').unset();
      interact('.form_fields_dropzone_edit_user').unset();
      interact('.draggable-col-users-edit').unset();
    });

    const makeFieldsDraggable = () => {
        interact(".form_field_edit_user").draggable({
            // inertia: true, // Add inertia for a smoother dragging experience
            autoScroll: true,
            onstart: (event) => { },
            onmove: (event) => {
                var target = event.target;
                var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                target.style.transform = "translate(" + x + "px, " + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
            },
            onend: (event) => {
                const target = event.target;
                const dropzone = event.dropzone;

                if (!dropzone) {
                    // If not dropped inside a dropzone, move back to original position
                    target.style.transform = `translate(0px, 0px)`;
                    target.setAttribute("data-x", 0);
                    target.setAttribute("data-y", 0);
                }
            },
        });
    }

    const initializeDropZone = () => {
        interact(".form_fields_dropzone_edit_user").dropzone({
            accept: ".form_field_edit_user",
            overlap: 0.75,
            ondragenter: function (event) {
                event.target.classList.add("drop-area-bg");


            },
            ondragleave: function (event) {
                event.target.classList.remove("drop-area-bg");
            },
            ondrop: (event) => {
                event.target.classList.remove("drop-area-bg");
                let droppedElement = event.relatedTarget;
                let type = droppedElement.getAttribute("type");
                if (type) {
                    const field_type = system_form_field_types.value.find(
                        (item) => item.type === type
                    );
                    if (field_type) {
                        addCustomFormField(field_type);
                    }
                }
                droppedElement.style.transform = `translate(0px, 0px)`;
                droppedElement.setAttribute("data-x", "0px");
                droppedElement.setAttribute("data-y", "0px");
            },
        });
    }

    const canManageCustomFields = ref(false)

    // Add new refs for tracking initialization
    const isFieldsReady = ref(false)
    const fieldOrders = ref([])

    const currentTab = ref(0)
    const updateCurrentTab = (tabId) => {
    currentTab.value = tabId
    }

    const formFieldsByTab = (tabId) => {
    if (tabId == 0) {
        return customFormFields.value
        .map((field, index) => ({ ...field, originalIndex: index }))
        .filter(field => field.form_tab_id == null);
    }
    return customFormFields.value
        .map((field, index) => ({ ...field, originalIndex: index }))
        .filter(field => field.form_tab_id === tabId);
    }

    const formTabs = ref([]);
    const customFieldActiveTab = ref(0);

    // Modify onMounted to track when both APIs are complete
    onMounted(async () => {
        try {

            const userData = useCookie("userData").value;
            const userPermissionsByRoles = userData.permissions_by_roles;
            let hasPermission = Object.values(userPermissionsByRoles).some(
                (permissions) => permissions.includes("User Custom Fields")
            );
            if (hasPermission) {
                canManageCustomFields.value = true;
            } else {
                canManageCustomFields.value = false;
            }
            
        // Fetch both field orders and custom fields
        const [fieldOrdersResponse, customFieldsResponse, customFieldsOrderResponse] = await Promise.all([
            axios.get('/module-fields/users'),
            axios.get('/custom_fields/users'),
            axios.get('/form_tabs/user'),
        ])

        if (fieldOrdersResponse.status === 'success' && customFieldsResponse.status === 'success') {
            customFormFields.value = customFieldsResponse.data.form_fields
            fieldOrders.value = fieldOrdersResponse.data.field_orders
            isFieldsReady.value = true

            nextTick(() => {
                reorderFields();
            });
        }
        if(customFieldsOrderResponse.status === 'success'){
            formTabs.value = customFieldsOrderResponse.data
        }
        } catch (error) {
            console.error('Error initializing fields:', error)
        }

        getUserData();
        getCustomFormFields();
        getSystemFormFieldTypes();
        getLookupFields()
        fetchRoles();

        if(canManageCustomFields.value){
            makeFieldsDraggable();
            initializeDropZone();
        }

        axios
            .get("users/allowed/roles_list")
            .then(async (response) => {
                if (response.status == "success") {
                    allowed_roles.value = Object.keys(response.data.roles).map((key) => ({
                        key,
                        value: response.data.roles[key],
                    }));
                }
            })
            .catch((error) => { })
            .finally(() => { });

        axios
            .get("get-all-roles-by-id")
            .then(async (response) => {
                if (response.status == "success") {
                    rolesMapping.value = response.data.roles;
                }
            })
            .catch((error) => { })
            .finally(() => { });

            if(canManageCustomFields.value){
                interact('.draggable-col-users-edit')
                .draggable({
                    autoScroll: true,
                    onstart: (event) => { },
                    onmove: (event) => {
                        var target = event.target;
                        var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                        var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                        target.style.transform = "translate(" + x + "px, " + y + "px)";
                        target.setAttribute("data-x", x);
                        target.setAttribute("data-y", y);
                    },
                    onend: (event) => {
                        const target = event.target;
                        const dropzone = event.dropzone;

                        if (!dropzone) {
                            target.style.transform = `translate(0px, 0px)`;
                            target.setAttribute("data-x", 0);
                            target.setAttribute("data-y", 0);
                        }
                    },
                })
                .dropzone({
                    accept: '.draggable-col-users-edit',
                    overlap: 0.1,
                    ondragenter: function (event) {
                        event.target.classList.add('drop-area-bg');
                    },
                    ondragleave: function (event) {
                        event.target.classList.remove('drop-area-bg');
                    },
                    ondrop: (event) => {
                        event.target.classList.remove('drop-area-bg');
                        const draggedElement = event.relatedTarget;
                        const targetElement = event.target;

                        const draggedOrder = draggedElement.getAttribute('data-order');
                        const targetOrder = targetElement.getAttribute('data-order');

                        // update the field order
                        axios.post('/module-fields/users/reorder', {
                            dragged_field: draggedOrder,
                            target_field: targetOrder
                        })
                        .then((response) => {
                            if (response.status == "success") {
                                console.log('Field order updated successfully');
                                fieldOrders.value = response.data.field_orders
                                nextTick(() => {
                                    reorderFields();
                                });
                            }
                        })
                        .catch((error) => {
                            console.error('Error updating field order:', error);
                        });

                        draggedElement.style.transform = `translate(0px, 0px)`;
                        draggedElement.setAttribute("data-x", "0px");
                        draggedElement.setAttribute("data-y", "0px");
                    }
                });

            }
    
        });

    // Add phone formatter function
    const onPhoneInput = (event) => {
      let value = event.target.value.replace(/\D/g, '');

      // Limit to 10 digits
      if (value.length > 10) {
        value = value.slice(0, 10);
      }

      // Apply formatting: XXX-XXX-XXXX
      if (value.length > 3 && value.length <= 6) {
        value = value.replace(/^(\d{3})(\d{0,3})/, '$1-$2');
      } else if (value.length > 6) {
        value = value.replace(/^(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
      }

      userData.value.phone = value;
    };

    const ssnValidator = [(v) => v.length === 9 || v === '' || 'SSN must be 9 digits'];

    const onSSNInput = (event) => {
        let value = event.target.value.replace(/\D/g, '');

        if (value.length > 9) {
            value = value.slice(0, 9);
        }

        userData.value.social_security_number = value;
    };

    const userCanSeeSSN = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin'
    const userCanSeeDOB = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin' || useCookie("userData").value.role == 'representative_manager'
    const userCanSeeAddress = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin'

    // Add this helper function
    const formatCustomPhoneField = (value) => {
      let numericValue = value.replace(/\D/g, '');
      
      // Limit to 10 digits
      if (numericValue.length > 10) {
        numericValue = numericValue.slice(0, 10);
      }

      // Apply formatting: XXX-XXX-XXXX
      if (numericValue.length > 3 && numericValue.length <= 6) {
        numericValue = numericValue.replace(/^(\d{3})(\d{0,3})/, '$1-$2');
      } else if (numericValue.length > 6) {
        numericValue = numericValue.replace(/^(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
      }
      
      return numericValue;
    };

    // Update phone field event handler to handle custom fields
    const onCustomPhoneInput = (event, fieldId) => {
      const formattedValue = formatCustomPhoneField(event.target.value);
      const fieldValue = userData.value.values.find(field => field.form_field_id === fieldId);
      if (fieldValue) {
        fieldValue.value = formattedValue;
      }
    };

    const updateUser = async () => {
        let loader = $loading.show();
        submitBtn.value = 0;
        let form_data = userData.value;
        form_data.branch_id = form_data?.branch_id?.value ? form_data?.branch_id?.value : form_data?.branch_id;

        axios
            .patch("/users/" + `${userData.value.id}`, form_data)
            .then((response) => {
                if (response.status == "success") {
                    toast.success(response.message, {
                        autoClose: 5000,
                    });
                    router.push({ name: "user-management-list" });
                } else {
                    toast.error(response.message, {
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                loader.hide();
            })
            .finally(() => {
                loader.hide();
                submitBtn.value = 1;
            });
    };

    const discardChanges = () => {
        router.push({ name: "user-management-list" });
    }

    const customFormFields = ref([])
    const getCustomFormFields = () => {
        axios
            .get(`/custom_fields/users`)
            .then((response) => {
                if (response.status == "success") {
                    customFormFields.value = response.data.form_fields
                }
            })
            .catch((error) => { })
            .finally(() => { });
    }

    const resolveIconForFieldType = (system_form_field) => {
        if (system_form_field.type == "single_line") {
            return "tabler-cursor-text";
        } else if (system_form_field.type == "multi_line") {
            return "tabler-forms";
        } else if (system_form_field.type == "integer") {
            return "tabler-123";
        } else if (system_form_field.type == "percent") {
            return "tabler-percentage";
        } else if (system_form_field.type == "decimal") {
            return "tabler-decimal";
        } else if (system_form_field.type == "currency") {
            return "tabler-cash";
        } else if (system_form_field.type == "date") {
            return "tabler-calendar-month";
        } else if (system_form_field.type == "date_time") {
            return "tabler-calendar-clock";
        } else if (system_form_field.type == "email") {
            return "tabler-mail";
        } else if (system_form_field.type == "phone") {
            return "tabler-phone";
        } else if (system_form_field.type == "pick_list") {
            return "tabler-checkup-list";
        } else if (system_form_field.type == "multi_select") {
            return "tabler-list-check";
        } else {
            return "";
        }
    };

    const findFormFieldValue = (fieldId) => {
        return userData.value.values.find(field => field.form_field_id === fieldId) || { value: '' };
    }

    const transformedOptions = (form_field) => {
        return form_field.options.map((option) => ({
            title: option.name,
            value: option.id,
        }));
    };

    const rolesMapping = ref([])

    const showfield = (form_field) => {
        let conditions = form_field.conditions;
        let condition_status = false;
        if (conditions.length > 0) {
            conditions.forEach((condition) => {
                let parentField = userData.value.values.find(field => field.form_field_id === condition.parent_form_field_id);
                if (parentField) {
                    let parent_value = parentField.value;
                    let parent_options = customFormFields.value.find(field => field.id === condition.parent_form_field_id).options;
                    if (parent_value) {
                        let parent_selected_option = parent_options.find(option => option.id == parent_value);
                        if (parent_selected_option && parent_selected_option.name == condition.value) {
                            condition_status = true;
                        }
                    }
                }
            });
        } else {
            condition_status = true;
        }

        // check for the field layers
        let layers_status = false;

        let form_field_layer_option_user = form_field.meta.layer_option.users;
        let selected_user = userData.value.id;
        if (form_field_layer_option_user == 'all') {
            layers_status = true;
        }
        else if (form_field_layer_option_user == 'custom' && form_field.users.includes(selected_user)) {
            layers_status = true;
        }

        let form_field_layer_option_branch = form_field.meta.layer_option.branches;
        let selected_branch = userData.value?.branch_id?.value ? userData.value?.branch_id.value : userData.value?.branch_id;
        let form_field_branches = form_field.branches;
        if(!Array.isArray(form_field_branches)){
            form_field_branches = [form_field_branches]
        }
        if (form_field_layer_option_branch == 'custom' && form_field_branches.includes(selected_branch)) {
            layers_status = true;
        }

        let form_field_layer_option_role = form_field.meta.layer_option.roles;
        let selected_role = rolesMapping.value[userData.value.role]; // userData.value.role has role_name, we need id
        if (form_field_layer_option_role == 'all' && selected_role) {
            layers_status = true;
        }
        else if (form_field_layer_option_role == 'custom' && form_field.roles.includes(selected_role)) {
            layers_status = true;
        }

        let form_field_layer_option_team = form_field.meta.layer_option.teams;
        let user_teams = userData.value.teams ?? []; // user belongs to teams 1,2,3
        let form_field_teams = form_field.teams;
        if(!Array.isArray(form_field_teams)){
            form_field_teams = [form_field_teams]
        }
        let user_field_teams = form_field_teams.some(team => user_teams.includes(team))
        if (form_field_layer_option_team == 'custom' && user_field_teams) {
            layers_status = true;
        }

        return condition_status && layers_status;
    };


    const deleteFieldButton = (form_field) => {
        return true;
    }

    const customFieldDialog = ref(false)
    const addCustomFormField = (type) => {
        customFieldDialog.value = true;
        customFieldDialogTab.value = 0;
        customFormFieldInstance.value = {
            name: '',
            type: type.type,
            meta: {
                layer_option: {
                    users: 'all',
                    branches: 'none',
                    roles: 'none',
                    teams: 'none',
                }
            },
            form_tab_id: customFieldActiveTab.value == 0 ? null : currentTab.value,
            conditions: [],
            options: [],
            users: [],
            branches: [],
            roles: [],
            teams: [],
        }
    }

    const customFieldDialogTab = ref(1)
    const customFormFieldInstance = ref({})
    const saveFormFieldChanges = async () => {

        let url = '/custom_fields/users/create_update_field';
        let loader = $loading.show();

        axios
            .post(url, {
                form_field: customFormFieldInstance.value
            })
            .then((response) => {
                if (response.status == "success") {
                    toast.success(response.message, {
                        autoClose: 5000,
                    });

                    customFieldDialog.value = false;
                    customFieldDialogTab.value = 0;

                    // Fetch both field orders and custom fields again
                    Promise.all([
                        axios.get('/module-fields/users'),
                        axios.get('/custom_fields/users')
                    ]).then(([fieldOrdersResponse, customFieldsResponse]) => {
                        if (fieldOrdersResponse.status === 'success' && customFieldsResponse.status === 'success') {
                            customFormFields.value = customFieldsResponse.data.form_fields;
                            fieldOrders.value = fieldOrdersResponse.data.field_orders;
                            
                            nextTick(() => {
                                reorderFields();
                            });
                        }
                    });

                } else {
                    toast.error(response.message, {
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                loader.hide();
            })
            .finally(() => {
                loader.hide();
            });
    }

    const currentFieldOptionValue = ref('')
    const addFormFieldOption = () => {
        if (currentFieldOptionValue.value != "") {
            let currentFormFieldOptions = customFormFieldInstance.value.options;
            currentFormFieldOptions.push(currentFieldOptionValue.value);
            customFormFieldInstance.value.options = currentFormFieldOptions;
            currentFieldOptionValue.value = "";
        }
    };


    const removeFormFieldOption = (index) => {
        if (customFormFieldInstance.value.options[index].id) {
            axios
                .delete(
                    "/helpdesk/form_field_options/" +
                    `${customFormFieldInstance.value.options[index]
                        .id
                    }`
                )
                .then((response) => {
                    if (response.status == "success") {
                        customFormFieldInstance.value.options.splice(
                            index,
                            1
                        );
                    }
                })
                .catch((error) => { })
                .finally(() => { });
        } else {
            customFormFieldInstance.value.options.splice(
                index,
                1
            );
        }
    };

    const deleteFormFieldDialog = ref(false);
    const deleteFormFieldId = ref('')
    const removeFormField = (form_field_id) => {
        deleteFormFieldId.value = form_field_id
        deleteFormFieldDialog.value = true;
    };

    const removeFormFieldSubmit = () => {
        let loader = $loading.show();

        axios
            .delete(
                "/helpdesk/form_fields/" + `${deleteFormFieldId.value}`
            )
            .then((response) => {
                if (response.status == "success") {
                    toast.success(response.message, {
                        autoClose: 5000,
                    });
                    getCustomFormFields();
                    deleteFormFieldDialog.value = false;
                } else {
                    toast.error(response.message, {
                        autoClose: 2000,
                    });
                }
            })
            .catch((error) => {
                loader.hide();
            })
            .finally(() => {
                loader.hide();
            });
    };

    const editFieldSettings = (form_field) => {
        customFormFieldInstance.value = form_field;
        customFieldDialogTab.value = 0;
        customFieldDialog.value = true;
    }

    const updateUserCustomFields = () => {
        let custom_fields = customFormFields.value;
        let userDataValues = userData.value.values;
        custom_fields.forEach((custom_field) => {
            if (showfield(custom_field)) {
                let exists = false;
                userDataValues.forEach((userDataValue) => {
                    if (userDataValue.form_field_id == custom_field.id) {
                        exists = true;
                    }
                });

                if (!exists) {
                    if(custom_field.type == 'multi_select'){
                        userData.value.values.push({
                            form_field_id: custom_field.id,
                            value: []
                        });
                    }
                    else{
                        userData.value.values.push({
                            form_field_id: custom_field.id,
                            value: ''
                        });
                    }
                }else{
                    if(custom_field.type == 'multi_select'){
                        let allOptionsAreValid = true;
                        let newUserDataValues = userDataValues.map((userDataValue) => {
                            if (userDataValue.form_field_id == custom_field.id) {
                                let selected_options = userDataValue.value.map((item) => item);
                                let available_options = custom_field.options.map((item) => item.id);
                                let valid_selected_options = selected_options.filter(option => available_options.includes(option));
                                if(valid_selected_options.length != selected_options.length){
                                    allOptionsAreValid = false;
                                }
                                return {
                                    form_field_id: userDataValue.form_field_id,
                                    value: valid_selected_options
                                }
                            }
                            return userDataValue;
                        });
                        if(!allOptionsAreValid){
                            userData.value.values = newUserDataValues
                        }
                    }
                }
            }
        });
    };

    watch(
        customFormFieldInstance,
        async (newCustomFormFieldInstance) => {
            searchUsersForFieldOnChange();
            searchBranchesForFieldOnChange();
            searchTeamsForFieldOnChange();
        }
    );

    watch(
        customFormFields,
        async (newCustomFields) => {
            updateUserCustomFields();
        },
        { deep: true }
    );

    watch(
        userData,
        async (newUserData) => {
            updateUserCustomFields();
        },
        { deep: true }
    );
    definePage({ meta: { requiresAuth: true } });

    const swapArrayElements = (arr, fromIndex, toIndex) => {
        // Update parent lookup field indexes
        arr.forEach((field) => {
            if (field.parent_lookup_field_id) {
                const parentIndex = arr.findIndex(f => f.id === field.parent_lookup_field_id);
                if (parentIndex === fromIndex) {
                    field.parent_lookup_field_id = arr[toIndex].id;
                } else if (parentIndex === toIndex) {
                    field.parent_lookup_field_id = arr[fromIndex].id;
                }
            }

            // Update conditions
            if (field.conditions && field.conditions.length > 0) {
                field.conditions.forEach((condition) => {
                    if (condition.parent_form_field_id === arr[fromIndex].id) {
                        condition.parent_form_field_id = arr[toIndex].id;
                    } else if (condition.parent_form_field_id === arr[toIndex].id) {
                        condition.parent_form_field_id = arr[fromIndex].id;
                    }
                });
            }
        });

        // Swap the elements
        const temp = arr[fromIndex];
        arr[fromIndex] = arr[toIndex];
        arr[toIndex] = temp;

        // Update display orders
        let fromDisplayOrder = arr[fromIndex].display_order;
        arr[fromIndex].display_order = arr[toIndex].display_order;
        arr[toIndex].display_order = fromDisplayOrder;

        // Update both fields using existing API
        updateField(arr[fromIndex]);
        updateField(arr[toIndex]);
    };

    const updateField = async (field) => {
        try {
            await axios.post("/custom_fields/users/create_update_field", {
                form_field: field
            });
        } catch (error) {
            // Refresh the fields to ensure correct order
            getCustomFormFields();
        }
    };

    // Add reorderFields function
    const reorderFields = () => {
        // Get all field elements from all tabs
        const defaultTabRow = document.querySelector('.fields_row');
        if (!defaultTabRow) return;

        // Sort fields array by display_order
        const sortedFields = [...fieldOrders.value.fields].sort((a, b) => a.display_order - b.display_order);
        
        // Get all field elements from all tabs
        const allTabRows = document.querySelectorAll('.fields_row');
        
        allTabRows.forEach(formRow => {
            // Get all field elements in this specific tab
            const tabFieldElements = formRow.querySelectorAll('.draggable-col-users-edit');
            
            // Track which elements have been ordered
            const orderedElements = new Set();
            
            // First reorder elements that exist in fieldOrders
            sortedFields.forEach((fieldData) => {
                const fieldElement = formRow.querySelector(`[data-order="${fieldData.field}"]`);
                if (fieldElement && fieldElement.parentElement === formRow) {
                    formRow.appendChild(fieldElement);
                    orderedElements.add(fieldElement);
                }
            });
            
            // Append any remaining elements (new fields) to the end
            tabFieldElements.forEach(element => {
                if (!orderedElements.has(element) && element.parentElement === formRow) {
                    formRow.appendChild(element);
                }
            });
        });
    };

    // Add watch for role changes to trigger reordering
    watch(
        () => userData.value.role,
        () => {
            nextTick(() => {
                reorderFields();
            });
        }
    );

    const dateTimePickerConfig = {
      dateFormat: 'm/d/Y', // MM/DD/YYYY format
      allowInput: true, // Allow manual input
      maxDate: new Date().toLocaleDateString('en-US'),
    };
    </script>

    <template>
        <div>
            <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
                <div class="d-flex flex-column justify-center">
                    <h2>
                        <strong class="oswald">Edit User</strong>
                    </h2>
                    <span class="lato-light">You can edit this user on this page</span>
                </div>

                <div class="d-flex gap-4 align-center flex-wrap">
                    <VBtn @click.prevent="discardChanges" variant="tonal" color="dark">
                        Discard
                    </VBtn>
                    <VBtn @click.prevent="updateUser">Update</VBtn>
                </div>
            </div>

            <VRow>
                <VCol cols="12" :md="canManageCustomFields ? 9 : 12">
                    <VCard class="mb-6 form_fields_dropzone_edit_user " title="User Information">
                        <VCardText>

                            <VForm class="mt-6" @submit.prevent="updateUser()">
                                <VTabs
                                v-model="customFieldActiveTab"
                                class=" "
                            >
                                <VTab @click="updateCurrentTab(0)">Default </VTab>
                                <VTab v-for="formTab in formTabs" :key="formTab.id" @click="updateCurrentTab(formTab.id)" >
                                    {{ formTab.name }}
                                </VTab>
                            </VTabs>

                            <VWindow v-model="customFieldActiveTab">
                                <VWindowItem
                                    :key="0"
                                    :value="`item-${0}`"
                                > 
                                    <div class="py-5 px-3">
                                        <VRow class="fields_row">
                                            <!-- 👉 First Name -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="first_name">
                                                <AppTextField 
                                                    v-model="userData.first_name" 
                                                    label="First Name *" 
                                                    placeholder="John"
                                                    :rules="[requiredValidator]" 
                                                />
                                            </VCol>

                                            <!-- 👉 Last Name -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="last_name">
                                                <AppTextField 
                                                    v-model="userData.last_name" 
                                                    label="Last Name *" 
                                                    placeholder="Doe"
                                                    :rules="[requiredValidator]" 
                                                />
                                            </VCol>

                                            <!-- 👉 Billing Email -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="email">
                                                <AppTextField 
                                                    v-model="userData.email" 
                                                    label="Email *"
                                                    placeholder="johndoe@email.com" 
                                                    :rules="[requiredValidator, emailValidator]" 
                                                />
                                            </VCol>

                                            <!-- 👉   Phone -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="phone">
                                                <AppTextField 
                                                    v-model="userData.phone" 
                                                    label="Phone *" 
                                                    placeholder="1234567890"
                                                    @input="onPhoneInput"
                                                    :rules="[requiredValidator]" 
                                                />
                                            </VCol>

                                            <!-- 👉   Title -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="title">
                                                <AppTextField v-model="userData.title" label="Title *" placeholder="Title"
                                                    :rules="[requiredValidator]" />
                                            </VCol>

                                            <!-- 👉 Password -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="password">
                                                <AppTextField v-model="userData.password" label="Password *"
                                                    :type="isPasswordVisible ? 'text' : 'password'" :append-inner-icon="isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                                                        " placeholder="Password"
                                                    :rules="mode === 'create' ? [requiredValidator] : []" autocomplete="off"
                                                    @click:append-inner="isPasswordVisible = !isPasswordVisible" />
                                            </VCol>

                                            <!-- 👉 Role -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="role">
                                                <AppSelect v-model="userData.role" :items="allowed_roles" item-title="value"
                                                    item-value="key" label="Role *" :rules="[requiredValidator]" />
                                            </VCol>

                                            <!-- 👉   Is Assistant -->
                                            <VCol class="draggable-col-users-edit mt-md-9" cols="12" md="6" v-if="userData.role == 'representative_manager' || userData.role == 'representative'" data-order="is_assistant">
                                                <VSwitch v-model="userData.is_assistant" label="Is Assistant" color="primary" />
                                            </VCol>


                                            <!-- 👉 Branch -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="branch_id">
                                                <AppAutocomplete @keyup="searchBranches" v-model="userData.branch_id" :items="branches"
                                                    label="Select Branch" placeholder="Type to search..." autocomplete="no" />
                                            </VCol>

                                            <!-- 👉 Status -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" data-order="status">
                                                <AppSelect v-model="userData.status" :items="statusList" label="Select Status"
                                                    :rules="[requiredValidator]" />
                                            </VCol>

                                            <!-- 👉   Internal Rep Code -->
                                            <VCol class="draggable-col-users-edit" cols="12" md="6" v-if="userData.role == 'representative_manager' || userData.role == 'representative'" data-order="internal_rep_code">
                                                <AppTextField v-model="userData.internal_rep_code" label="Internal Rep Code *" placeholder="Internal Rep Code *"
                                                    :rules="[requiredValidator, charsBetweenValidator(userData.internal_rep_code, 1, 16)]" />
                                            </VCol>

                                            <VCol cols="12" md="6"
                                                class="draggable-col-users-create"
                                                data-order="social_security_number"
                                                v-if="userCanSeeSSN"
                                            >
                                                <AppTextField v-model="userData.social_security_number"
                                                    @input="onSSNInput"
                                                    :rules="[ssnValidator]"
                                                    label="Social Security Number" placeholder="Social Security Number" />
                                            </VCol>

                                            <VCol cols="12" md="6"
                                                class="draggable-col-users-create"
                                                data-order="date_of_birth"
                                                v-if="userCanSeeDOB"
                                            >
                                                <AppDateTimePicker v-model="userData.date_of_birth" label="Date Of Birth"
                                                    placeholder="Date Of Birth" :config="{
                                                        dateFormat: 'm/d/Y', // MM/DD/YYYY format
                                                        allowInput: true, // Allow manual input
                                                        maxDate: new Date().toLocaleDateString('en-US'),
                                                    }" />
                                            </VCol>

                                            <VCol cols="12" md="6"
                                                class="draggable-col-users-create"
                                                data-order="address"
                                                v-if="userCanSeeAddress"
                                            >
                                                <AppTextField v-model="userData.address"
                                                    label="Address" placeholder="Address" />
                                            </VCol>

                                            <VCol 
                                                v-for="(form_field, index) in formFieldsByTab(0)" 
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-users-edit"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField 
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        @input="(e) => onCustomPhoneInput(e, form_field.id)"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name"
                                                        :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name"
                                                        :rules="form_field.is_required
                                                            ? [requiredValidator, phoneValidator]
                                                            : [phoneValidator]"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect
                                                        @update:modelValue="customFieldPickListUpdated(form_field)"
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        :label="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :items="transformedOptions(form_field)"
                                                        :placeholder="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :rules="form_field.is_required ? [requiredValidator] : []"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        :label="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :items="transformedOptions(form_field)"
                                                        :placeholder="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :rules="form_field.is_required ? [requiredValidator] : []"
                                                        chips multiple closable-chips
                                                    />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>
                                            
                                        </VRow>
                                    </div>
                                </VWindowItem>
                                <VWindowItem
                                    v-for="formTab in formTabs"
                                    :key="formTab.id"
                                    :value="`item-${formTab.id}`"
                                >
                                    <div class="py-5 px-3">
                                        <VRow class="fields_row">
                                            <VCol 
                                                v-for="(form_field, index) in formFieldsByTab(formTab.id)" 
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-users-edit"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField 
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        @input="(e) => onCustomPhoneInput(e, form_field.id)"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name"
                                                        :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name"
                                                        :rules="form_field.is_required
                                                            ? [requiredValidator, phoneValidator]
                                                            : [phoneValidator]"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect
                                                        @update:modelValue="customFieldPickListUpdated(form_field)"
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        :label="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :items="transformedOptions(form_field)"
                                                        :placeholder="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :rules="form_field.is_required ? [requiredValidator] : []"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        :label="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :items="transformedOptions(form_field)"
                                                        :placeholder="
                                                            form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                        "
                                                        :rules="form_field.is_required ? [requiredValidator] : []"
                                                        chips multiple closable-chips
                                                    />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" color="dark" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="dark"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>
                                        </VRow>
                                    </div>
                                </VWindowItem>
                                </VWindow>
                            </VForm>

                        </VCardText>
                    </VCard>
                </VCol>

                <VCol md="3" cols="12" v-if="canManageCustomFields">
                    <div class="system_fields sticky">
                        <div class="d-flex flex-column justify-center mb-5">
                            <h2 class="oswald">
                                <strong>Custom Fields</strong>
                            </h2>
                            <div class="lato-light">Drag & drop fields</div>
                        </div>

                        <div v-for="(
                            system_form_field_type, index
                            ) of system_form_field_types" :key="system_form_field_type.type">
                            <div class="form_field_edit_user bg-primary rounded mb-1 px-2 py-2" :type="system_form_field_type.type" style="-ms-touch-action: none;touch-action: none;" >
                                <div>
                                    <VIcon size="20" :icon="resolveIconForFieldType(system_form_field_type)
                                        " class="mr-3" />
                                    {{ system_form_field_type.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </VCol>
            </VRow>
        </div>

        <VDialog v-model="customFieldDialog" max-width="600">
            <DialogCloseBtn @click="
                customFieldDialog = !customFieldDialog
                " />
            <VCard>
                <VCardText>
                    <VTabs v-model="customFieldDialogTab">
                        <VTab>General Settings</VTab>
                        <VTab
                            v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'">
                            Field
                            Options</VTab>
                        <VTab>Layer Settings</VTab>
                    </VTabs>
                    <VWindow v-model="customFieldDialogTab">
                        <VWindowItem :key="1" :value="`item-${1}`">
                            <div class="py-5">
                                <AppTextField v-model="customFormFieldInstance.name" label="Field Name"
                                    placeholder="Field Name" />
                                <VSwitch class="mt-3" v-model="customFormFieldInstance.is_required" label="Is required" />
                            </div>
                        </VWindowItem>

                        <VWindowItem class="py-5"
                            v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'"
                            :key="2" :value="`item-${2}`">

                            <div class="border rounded pa-2 mt-3 mb-5">
                                <VSwitch
                                  @change="fieldLookupSettingChanged"
                                  v-model="customFormFieldInstance.is_lookup_field"
                                  label="Is Lookup Field"
                                />
                                <AppSelect
                                  class="mt-2"
                                  v-if="customFormFieldInstance.is_lookup_field"
                                  v-model="customFormFieldInstance.table"
                                  :items="modules"
                                  placeholder="Select Module"
                                  label="Module *"
                                  density="compact"
                                />
                                <AppSelect
                                  class="mt-2"
                                  v-if="customFormFieldInstance.is_lookup_field"
                                  v-model="customFormFieldInstance.column"
                                  :items="lookupFieldAttributes"
                                  placeholder="Select Attribute"
                                  label="Attribute *"
                                  density="compact"
                                />
                            </div>

                            <div style="width: 100%; text-align: left" v-if="!customFormFieldInstance.is_lookup_field">
                                <AppTextField v-model="currentFieldOptionValue" label="Option Name"
                                    placeholder="Option Name">
                                    <template #append>
                                        <VBtn @click.prevent="addFormFieldOption" class="mr-1" size="40" icon="tabler-plus"
                                            rounded></VBtn>
                                    </template>
                                </AppTextField>
                                <div class="mt-5 text-sm" v-if="customFormFieldInstance.options.length > 0">
                                    Current Options:
                                </div>
                                <VList style="padding: 0px !important" class="mt-1"
                                    v-if="customFormFieldInstance.options.length > 0">
                                    <template v-for="(form_field_option, index) of customFormFieldInstance.options"
                                        :key="index">
                                        <VListItem class="px-1 ma-1 my-2 rounded" border>
                                            <VListItemTitle class="pl-3">
                                                {{
                                                    form_field_option.id
                                                        ? form_field_option.name
                                                        : form_field_option
                                                }}
                                            </VListItemTitle>
                                            <template #append>
                                                <VBtn color="dark" @click.prevent="removeFormFieldOption(index)" size="30"
                                                    icon="tabler-minus" rounded variant="tonal"></VBtn>
                                            </template>
                                        </VListItem>
                                    </template>
                                </VList>
                            </div>
                        </VWindowItem>

                        <VWindowItem :key="2" :value="`item-${2}`">

                            <div class="">

                                <div class="mb-2 mt-5 border rounded py-3 px-5">
                                    <label for="">Which users this field is for?</label>
                                    <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.users">
                                        <VRadio label="All" value="all" />
                                        <VRadio label="None" value="none" />
                                        <VRadio label="Custom" value="custom" />
                                    </VRadioGroup>

                                    <AppAutocomplete
                                        v-if="customFormFieldInstance.meta.layer_option.users == 'custom'"
                                        @keyup="searchUsersForField"
                                        v-model="customFormFieldInstance.users"
                                        :items="usersForField"
                                        label="Select Users"
                                        placeholder="Type to search.."
                                        :return-object="false"
                                        clearable multiple
                                        chips closable-chips
                                        clear-icon="tabler-x"
                                    />
                                </div>

                                <div class="mb-2 mt-5 border rounded py-3 px-5">
                                    <label for="">Which branch users this field is for?</label>
                                    <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.branches">
                                        <VRadio label="None" value="none" />
                                        <VRadio label="Custom" value="custom" />
                                    </VRadioGroup>

                                    <AppAutocomplete
                                        v-if="customFormFieldInstance.meta.layer_option.branches == 'custom'"
                                        @keyup="searchBranchesForField"
                                        v-model="customFormFieldInstance.branches"
                                        :items="branchesForField"
                                        label="Select Branch"
                                        placeholder="Type to search.."
                                        :return-object="false"
                                        clearable 
                                        clear-icon="tabler-x"
                                    />
                                </div>

                                <div class="mb-2 mt-5 border rounded py-3 px-5">
                                    <label for="">Which role users this field is for?</label>
                                    <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.roles">
                                        <VRadio label="None" value="none" />
                                        <VRadio label="Custom" value="custom" />
                                    </VRadioGroup>

                                    <AppSelect
                                        v-if="customFormFieldInstance.meta.layer_option.roles == 'custom' || customFormFieldInstance.meta.layer_option.roles == 'all_except'"
                                        v-model="customFormFieldInstance.roles" :rules="[requiredValidator]" :items="rolesForField"
                                        label="Select Roles" placeholder="Select Roles" chips closable-chips clearable multiple
                                        clear-icon="tabler-x" />
                                </div>

                                <div class="mb-2 mt-5 border rounded py-3 px-5">
                                    <label for="">Which team users this field is for?</label>
                                    <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.teams">
                                        <VRadio label="None" value="none" />
                                        <VRadio label="Custom" value="custom" />
                                    </VRadioGroup>

                                    <AppAutocomplete
                                        v-if="customFormFieldInstance.meta.layer_option.teams == 'custom'"
                                        @keyup="searchTeamsForField"
                                        v-model="customFormFieldInstance.teams"
                                        :items="teamsForField"
                                        label="Select Team"
                                        placeholder="Type to search.."
                                        :return-object="false"
                                        clearable 
                                        clear-icon="tabler-x" />
                                </div>

                            </div>
                        </VWindowItem>

                    </VWindow>

                    <div class="d-flex flex-wrap justify-center gap-4 mt-5">
                        <VBtn type="button" block @click="saveFormFieldChanges">
                            Save Changes
                        </VBtn>
                    </div>

                </VCardText>
            </VCard>
        </VDialog>


        <DeleteConfirm v-model:deleteDialog="deleteFormFieldDialog" @closeDelete="deleteFormFieldDialog = false"
            @deleteItemConfirm="removeFormFieldSubmit" />


    </template>

    <style scoped>
    .form_field_types_div {
        sup {
            inset-block-start: 9px;
        }
    }

    .form_field_types_div_bg {
        position: relative;
        background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
    }

    .drop-area-bg {
        border: 2px dotted #ccc !important;
        background-color: rgba(var(--v-theme-on-background), var(--v-hover-opacity)) !important;
    }

    .sticky {
        position: -webkit-sticky;
        /* For Safari */
        position: sticky;
        top: 5rem;
        /* Adjust this value as needed */
        z-index: 1;
        /* Ensure it is above other content if needed */

    }

    .draggable-col-users-edit {
        cursor: grab;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .draggable-col-users-edit:active {
        cursor: grabbing;
    }

    .draggable-col-users-edit * {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .form_field_types_div :deep(sup) {
        inset-block-start: 9px;
    }
    </style>