import { setupLayouts } from 'virtual:generated-layouts'
// eslint-disable-next-line import/no-unresolved
import { createRouter, createWebHistory } from 'vue-router/auto'

function recursiveLayouts(route) {
  // console.log('------->', route)
  if (route.children) {
    for (let i = 0; i < route.children.length; i++)
      route.children[i] = recursiveLayouts(route.children[i])
    
    return route
  }
  
  return setupLayouts([route])[0]
}

// Vue Router configuration
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if (to.hash)
      return { el: to.hash, behavior: 'smooth', top: 60 }
    
    return { top: 0 }
  },
  extendRoutes: pages => [
    ...[...pages].map(route => recursiveLayouts(route)),
  ],
})

const isAuthenticated = () => {
  return (localStorage.getItem('token') !== null && localStorage.getItem('token') !== 'null' && useCookie('userData').value !== undefined)
}


// Authentication check navigation guard
router.beforeEach((to, from, next) => {
  console.log('beforeEach ------->', useCookie('userData').value)
  if (to.path !== '/' && to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!isAuthenticated() ) {

      console.log('I am not authenticated move to login')

      // If not authenticated, redirect to login
      next('/login')
    } else {
      // If authenticated, check if the route is the login page
      if (to.path === '/login') {
        console.log('I am authenticated move to dashboard')

        // Redirect to home or another page
        next('/')
      } else {
        console.log('next request else is called')

        // Proceed to the route
        next()
      }
    }
  } else {
    // For routes that do not require authentication or the root route
    next()
  }
})

// Export the router
export { router }
export default function (app) {
  app.use(router)
}
