<script setup>
import { onMounted, computed, ref, toRaw } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";
import CustomDatatable from "@/components/common/CustomDatatable.vue";
import CustomForm from "@/components/common/CustomForm.vue";
import CustomConfirmationDialog from "@/components/common/CustomConfirmationDialog.vue";

const $loading = useLoading(loaderData);
const router = useRouter();
const userData = useCookie("userData").value;
const formData = ref({});
const formTitle = ref("Add Certification");
const addButtonText = ref("Add New Certification");
const formDesc = ref("You can add a new certification here");
const itemForm = ref();
const formSubmitButton = ref(1);
const formSubmitButtonText = ref("Add");
const file = ref(null);
const hasAddPermission = ref(false);
const certifications = ref([]);
const itemDialogVisible = ref(false);
const confirmationDialogVisible = ref(false);
const confirmationDialogTitle = ref(
  "Are you sure you want to delete this certification?"
);
const confirmationDialogDesc = ref("You can not undo this action.");
const confirmationDialogIcon = ref("tabler-exclamation-circle");
const confirmationDialogIconColor = ref("error");
const confirmationDialogConfirmButtonText = ref("Delete");
const confirmationDialogConfirmButtonColor = ref("error");
const confirmationDialogCancelButtonText = ref("Cancel");
const isDragging = ref(false);
const filterInfo = ref({});

const headers = ref([
  { title: "Certification Name", key: "name", sortable: false },
  { title: "Actions", key: "actions", sortable: false },
]);

onMounted(async () => {
  getCertifications();
});

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getCertifications(filterInfo.value);
};
// Page changed from pagination
const refreshCategories = async () => {
  getCertifications(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getCertifications(filterInfo.value);
};

const getCertifications = (payload = {}) => {
  let url = `/certifications?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        certifications.value = response.data.certifications;
      }
    })
    .catch((error) => {
      console.log("error", error);
    })
    .finally(() => {});
};
const submitForm = (type = "") => {
  let url = `certifications`;
  const data = new FormData();
  if (type == "delete") {
    url = `certifications/${formData.value.id}?_method=DELETE`;
  } else {
    if (!itemForm.value.validate()) {
      return;
    }
    if (formData.value.id) {
      url = `certifications/${formData.value.id}?_method=PUT`;
    }
    data.append("name", formData.value.name);
  }

  formSubmitButton.value = 0;
  let loader = $loading.show();

  axios
    .post(url, data)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, 3000);
        formData.value = {};
        formSubmitButtonText.value = "Add";
        itemDialogVisible.value = false;
        confirmationDialogVisible.value = false;
        refreshCategories();
      } else {
        toast.error(response.message, 3000);
      }
    })
    .catch((error) => {
      toast.success("Something went wrong, try again.", 3000);
      console.log("error", error);
    })
    .finally(() => {
      formSubmitButton.value = 1;
      loader.hide();
    });
};
const addItem = () => {
  formData.value = {};
  formSubmitButtonText.value = "Add";
  formTitle.value = "Add Certification";
  formDesc.value = "You can add a add certification here ";

  itemDialogVisible.value = true;
};
const editItem = (item) => {
  formData.value = item;
  formSubmitButtonText.value = "Update";
  formTitle.value = "Update Certification";
  formDesc.value = "You can add a update certification here ";
  file.value = null;
  itemDialogVisible.value = true;
};
const deleteItem = (item) => {
  formData.value = item;
  file.value = null;
  confirmationDialogVisible.value = true;
};
const confirmationCallback = (is_confirmed) => {
  if (is_confirmed) {
    submitForm("delete");
  } else {
    formData.value = {};
    confirmationDialogVisible.value = false;
  }
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <div>
    <div class="pe-3 mb-4">
      <h3 class="text-h3 text-high-emphasis mb-1">Certifications</h3>

      <div class="mb-2 text-wrap" style="max-inline-size: 500px">
        Manage your certifications on this page
      </div>
    </div>
    <VCard class="mt-3">
      <VCardText class="p-0" style="padding: 0 !important">
        <CustomDatatable
          @table-changed="tableChanged"
          @search-value="searchValue"
          :headers="headers"
          :tableData="certifications"
        >
          <template #add_button>
            <VBtn prepend-icon="tabler-plus" @click="addItem()">
              {{ addButtonText }}
            </VBtn>
          </template>

          <template #item.name="{ item }">
            {{ item.name }}
          </template>
          <template #table_actions="{ item }">
            <div class="d-flex gap-1">
              <VBtn
                color="primary"
                size="x-small"
                class="mx-1"
                @click="editItem(item)"
              >
                <VIcon icon="tabler-pencil" />
              </VBtn>
              <VBtn color="primary" size="x-small" @click="deleteItem(item)">
                <VIcon icon="tabler-trash" />
              </VBtn>
            </div>
          </template>
        </CustomDatatable>
      </VCardText>
    </VCard>

    <CustomForm
      :isDialogVisible="itemDialogVisible"
      :title="formTitle"
      :description="formDesc"
      v-model:isDialogVisible="itemDialogVisible"
      :width="550"
    >
      <template #form>
        <VForm ref="itemForm" class="mt-6" @submit.prevent="submitForm()">
          <VRow>
            <VCol cols="12" md="12">
              <AppTextField
                v-model="formData.name"
                label="Certification Name *"
                placeholder="Certification Name"
                :rules="[requiredValidator]"
              />
            </VCol>

            <VCol cols="12" class="d-flex flex-wrap justify-center gap-4">
              <VBtn
                type="submit"
                :disabled="formSubmitButton == 0"
                @click="
                  itemForm?.validate()
                    ? (isValidate = true)
                    : (isValidate = false)
                "
              >
                {{ formSubmitButtonText }}
              </VBtn>

              <VBtn
                color="secondary"
                variant="tonal"
                @click="itemDialogVisible = false"
              >
                Cancel
              </VBtn>
            </VCol>
          </VRow>
        </VForm>
      </template>
    </CustomForm>

    <CustomConfirmationDialog
      @confirmation="confirmationCallback"
      :is-dialog-visible="confirmationDialogVisible"
      :title="confirmationDialogTitle"
      :description="confirmationDialogDesc"
      :icon="confirmationDialogIcon"
      :icon-color="confirmationDialogIconColor"
      :confirm-button-text="confirmationDialogConfirmButtonText"
      :confirm-button-color="confirmationDialogConfirmButtonColor"
      :cancel-button-text="confirmationDialogCancelButtonText"
    />
  </div>
</template>
<style lang="scss">
.dropzone {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone.dragging {
  background-color: #f0f0f0;
}
</style>