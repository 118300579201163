<script setup>
import TicketsDataTable from "@/components/helpdesk/tickets/TicketsDataTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
const $loading = useLoading(loaderData);
import { useRouter } from "vue-router";
const router = useRouter();

const user = useCookie("userData").value;

const errors = ref([]);
const headers = ref([
  {
    title: "Ticket ID",
    key: "ticket_id",
    sortable: true,
  },
  {
    title: "Status",
    key: "status",
    sortable: true,
  },
  {
    title: "Form",
    key: "form",
    sortable: true,
  },
  {
    title: "Created At",
    key: "date",
    sortable: true,
  },
  {
    title: "Created By",
    key: "submitted_by",
    sortable: true,
  },
  {
    title: "",
    key: "actions",
    sortable: false,
  },
]);

const tickets = ref([]);

const openModal = ref(false);
const deleteDialog = ref(false);
const modeModal = ref("create");
const currentTab = ref("allTickets");
const filterInfo = ref({});
const editedItem = ref({});

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const getHelpdeskTickets = async (payload = null) => {
  let url = `/helpdesk/tickets?page=${payload.page}&per_page=${
    payload.perPage
  }&list=${payload.list ? payload.list : "all"}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        tickets.value = response.data.tickets;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getHelpdeskTickets(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getHelpdeskTickets(filterInfo.value);
};

const filterListChanged = async (payload) => {
  filterInfo.value.list = payload;
  getHelpdeskTickets(filterInfo.value);
};

const deleteItemConfirm = async () => {
  axios
    .delete("/helpdesk/tickets/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getHelpdeskTickets(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// load contacts when page loads
onMounted(async () => {
  let valueToInsert = {
    title: "Assigned To",
    key: "assigned_to",
    sortable: false,
  };

  if (user.role == "super_admin") {
    headers.value.splice(2, 0, valueToInsert);
  }
});

watch(
  () => currentTab.value,
  (newValue, oldValue) => {
    filterListChanged(newValue);
  }
);

// expose errors and getContacts
defineExpose({
  errors,
  getHelpdeskTickets,
});

const viewTicket = (item) => {
  router.push({
    name: "helpdesk-ticket-id",
    params: { id: item.id },
  });
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>

  <VTabs v-model="currentTab" class="v-tabs-pill mb-2">
    <VTab key="all" value="all">All Tickets</VTab>
    <VTab key="assigned" value="assigned">Assigned To Me</VTab>
    <VTab key="my" value="my">My Tickets</VTab>
  </VTabs>

  <VCard>

    <VCardText class="p-0" style="padding: 0 !important">
      <VDivider />
      <TicketsDataTable
        :headers="headers"
        :table-data="tickets"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @filterListChanged="filterListChanged"
        @viewTicket="viewTicket"
        :user="user"
      />

    </VCardText>

  </VCard>

  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />
</template>
