<script setup>
import UserBioPanel from "@/components/common/UserBioPanel.vue";
import UserForm from "@/components/users/UserForm.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
const $loading = useLoading(loaderData);

const route = useRoute("user-management-view-id");
const userData = ref({});
const editedItem = ref({});
const openEditModal = ref(false);
const branches = ref([]);
const allowed_roles = ref([]);
const allowed_roles_for_edit_delete = ref([]);
const modeModal = ref("Update");
const status = ref([
  {
    title: "Active",
    value: 1,
  },
  {
    title: "Suspend",
    value: 0,
  },
]);
const getUser = async (id = route.params.id) => {
  let loader = $loading.show();
  axios
    .get(`/users/${id}`)
    .then((response) => {
      if (response.status == "success") {
        userData.value = response.data.user;
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};
onMounted(async () => {
  axios
    .get("users/allowed/roles_list")
    .then((response) => {
      if (response.status == "success") {
        allowed_roles_for_edit_delete.value = response.data.roles;
        allowed_roles.value = Object.keys(response.data.roles).map((key) => ({
          key,
          value: response.data.roles[key],
        }));
      }
    })
    .catch((error) => {})
    .finally(() => {});

  axios
    .get("branches/list/all")
    .then((response) => {
      if (response.status == "success") {
        branches.value = response.data.branches.map((branch) => {
          return {
            title: branch.branch_name,
            value: branch.id,
          };
        });
        // branches.value.unshift({ title: "-- Select Options --", value: "" });
      }
    })
    .catch((error) => {})
    .finally(() => {});
});
const editItem = async (item) => {
  const { branch, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    branch_id: newItem.branch_id !== null ? parseInt(newItem.branch_id) : "", // Convert branch_id from string to integer
  };
  modeModal.value = "Update";
  openEditModal.value = true;
};

onMounted(async () => {
  getUser();
});
definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VRow v-if="userData">
    <VCol cols="12" md="5" lg="4">
      <UserBioPanel
        :user-data="userData"
        @editItem="editItem"
        :allowed_roles_for_edit_delete="allowed_roles_for_edit_delete"
      />
    </VCol>
  </VRow>
  <VCard v-else>
    <VCardTitle class="text-center"> No User Found </VCardTitle>
  </VCard>
  <UserForm
    v-model:isDialogVisible="openEditModal"
    :user-data="editedItem"
    @refreshUsers="getUser"
    :mode="modeModal"
    :allowed_roles="allowed_roles"
    :branches="branches"
    :status="status"
  />
</template>
