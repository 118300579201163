<script setup>
import Form from "@/components/helpdesk/forms/Form.vue";
import FormDataTable from "@/components/helpdesk/forms/FormDataTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import { useRouter } from "vue-router"

const router = useRouter()
const $loading = useLoading(loaderData);
const errors = ref([]);
const headers = ref([
  {
    title: "Name",
    key: "name",
    sortable: true,
  },
  {
    title: "Description",
    key: "description",
    sortable: true,
  },
  {
    title: "Status",
    key: "status",
    sortable: true,
  },
  {
    title: "Fields",
    key: "fields",
    sortable: false,
  },
  {
    title: "Tickets",
    key: "tickets",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const forms = ref([]);
const system_form_field_types = ref([]);
const users = ref([]);
const roles = ref([]);
const teams = ref([]);
const lookup_fields = ref([]);
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const isCanSeeAssignmentRules = ref(false);
const modeModal = ref("create");
const contacts = ref([]);

const filterInfo = ref({});
const editedItem = ref({});

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types?helpdesk=true`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getUsers = (search_query = "") => {
  axios
    .get(`/get-all-users`)
    .then((response) => {
      if (response.status === "success") {
        users.value = Object.entries(response.data.users).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.first_name + " " + value.last_name,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getRoles = (search_query = "") => {
  axios
    .get("get-all-roles")
    .then(async (response) => {
      if (response.status == "success") {
        roles.value = Object.entries(response.data.roles).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getTeams = (search_query = "") => {
  axios
    .get("get-all-teams")
    .then(async (response) => {
      if (response.status == "success") {
        teams.value = Object.entries(response.data.teams).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.name,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getLookupFields = () => {
  axios
    .get("lookup_fields")
    .then(async (response) => {
      if (response.status == "success") {
        lookup_fields.value = response.data.lookup_fields
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Helpdesk Form")
  );
  
  isCanSeeAssignmentRules.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("View Assignment Rules")
  );
};

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const getHelpdeskForms = async (payload = null) => {
  let url = `/helpdesk/forms?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        forms.value = response.data.forms;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getHelpdeskForms(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getHelpdeskForms(filterInfo.value);
};

const refreshForms = async () => {
  getHelpdeskForms(filterInfo.value);
};

const editItem = (item) => {
  item.active = Number(item.active)
  editedItem.value = item

  // const { ...newItem } = item;

  // editedItem.value = {
  //   ...newItem,
  //   active: Number(item.active),
  // };

  modeModal.value = "Update";
  openModal.value = true;
};

const openAssignmentRules = () => {
  router.push({ name: "helpdesk-assignment-rules"})
};

const deleteItem = (item) => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/helpdesk/forms/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getHelpdeskForms(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// load contacts when page loads
onMounted(async () => {
  canSeeCreateBtn();
  getSystemFormFieldTypes();
  getUsers();
  getRoles();
  getTeams();
  getLookupFields();
});

// expose errors and getContacts
defineExpose({
  errors,
  getHelpdeskForms,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">

      <!-- Households Table Section -->
      <FormDataTable
        :headers="headers"
        :table-data="forms"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :canSeeAssignmentRules="isCanSeeAssignmentRules"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @assignmentRules="openAssignmentRules"
        @editItem="editItem"
        @deleteItem="deleteItem"
      />
    </VCardText>
  </VCard>

  <!-- Household Create/Edit Modal -->
  <Form
    v-model:isDialogVisible="openModal"
    :form-data="editedItem"
    :system_form_field_types="system_form_field_types"
    :users="users"
    :roles="roles"
    :teams="teams"
    :lookup_fields="lookup_fields"
    :mode="modeModal"
    @refreshForms="refreshForms"
  />

  <!-- Household Delete Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />
</template>
