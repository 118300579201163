<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";

const router = useRouter();
const $loading = useLoading(loaderData);
const route = useRoute("contacts-view-id");
const currentTab = ref(0);

const workflowData = ref({
    title: "",
    description: "",
    status: "",
});

const resolveUserStatusVariant = status => {
  if (status == 'draft') return "light"
  if (status == 'approved') return "success"
  if (status == 'suspended') return "error"

  return "primary"
}

const getworkflowData = function () {
    let id = route.params.id;
    let loader = $loading.show();
    let url = `/workflows/${id}`;
    axios
        .get(url)
        .then((response) => {
            if (response.status == "success") {
                workflowData.value = response.data.workflow;
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

onMounted(async () => {
    getworkflowData();
});

const discardChanges = () => {
    router.push({ name: "workflows-list" });
}

const editWorkflow = () => {
    router.push({ name: "workflows-id", params: { id: workflowData.id } });
}

definePage({ meta: { requiresAuth: true } });
</script>

<template>
    <div>
        <div>
            <!-- 👉 Header  -->
            <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
                <div>
                    <div class="d-flex gap-2 align-center mb-2 flex-wrap">
                        <h2>
                            <strong class="oswald">Workflow Details</strong>
                        </h2>
                    </div>
                    <div>
                        <span class="lato-light">
                            {{ new Date(workflowData.created_at).toLocaleString('en-US', {
                                dateStyle: 'long', timeStyle:
                                    'short'
                            }) }}
                        </span>
                    </div>
                </div>
                <div class="d-flex gap-4">
                    <VBtn variant="tonal" color="dark" @click="discardChanges">
                        Back
                    </VBtn>

                    <VBtn variant="tonal" color="primary" @click="editWorkflow()">
                        Edit Workflow
                    </VBtn>
                </div>
            </div>
            <!-- 👉 Customer Profile  -->
            <VRow>
                <VCol cols="12" md="5" lg="4">
                    <VRow>
                        <!-- SECTION Customer Details -->
                        <VCol cols="12">
                            <VCard>
                                <VCardText class="text-center pt-15">
                                    <!-- 👉 Avatar -->
                                    <VAvatar rounded :size="100" :color="'primary'" :variant="'tonal'">
                                        <!-- <VImg v-if="props.customerData.avatar" :src="props.customerData.avatar" /> -->
                                        <span class="text-5xl font-weight-medium">
                                            {{ workflowData.title }}
                                            <!-- {{ avatarText(workflowData.name) }} -->
                                        </span>
                                    </VAvatar>

                                    <!-- 👉 Customer fullName -->
                                    <h2 class="mt-4">
                                        <strong class="oswald">{{ workflowData.title }}</strong>
                                    </h2>
                                    <span class="text-sm lato-light">{{ workflowData.description }}</span>
                                    
                                    
                                </VCardText>
                            </VCard>
                        </VCol>
                        <!-- !SECTION -->
                    </VRow>
                </VCol>
                <VCol cols="12" md="7" lg="8">
                    <VTabs class="v-tabs-pill mb-3 disable-tab-transition" v-model="currentTab">
                        <VTab :key="0">
                            <span>General</span>
                        </VTab>
                        <VTab :key="1">
                            <span>Additional</span>
                        </VTab>
                    </VTabs>
                    <VWindow class="disable-tab-transition" :touch="false" v-model="currentTab">
                        <VWindowItem>
                            <VCard class="pa-5">
                                <VRow class="parent">
                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Title
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ workflowData.title }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>


                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Status
                                            </h3>
                                            <div>
                                                <VChip
                                                    :color="resolveUserStatusVariant(workflowData.status)"
                                                    size="small"
                                                    label
                                                    class="text-capitalize"
                                                    >
                                                    <span class="text-capitalize">{{ workflowData.status }}</span>
                                                </VChip>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="12">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Description
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ workflowData.description }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                </VRow>
                            </VCard>
                        </VWindowItem>

                        <VWindowItem>
                            <VCard class="pa-5">
                                <VRow class="parent">
                                    
                                </VRow>
                            </VCard>
                        </VWindowItem>
                    </VWindow>
                </VCol>
            </VRow>
        </div>
    </div>
</template>
