<script setup>
import UserForm from "@/components/users/UserForm.vue"
import InviteUserForm from "@/components/users/InviteUserForm.vue"
import UsersDataTable from "@/components/users/UsersDataTable.vue"
import UserFilters from "@/components/users/UserFilters.vue"
import { onMounted, ref, toRaw } from "vue"
import axios from "@/utils/axios"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { useLoading } from "vue-loading-overlay"
import loaderData from "@/utils/loader-data"
import DeleteConfirm from "@/components/common/DeleteConfirm.vue"

const $loading = useLoading(loaderData)
import { useRouter } from "vue-router"

const router = useRouter()

const errors = ref([])

const headers = ref([
  {
    title: "User",
    key: "first_name",
  },
  {
    title: "Title",
    key: "title",
  },
  {
    title: "Role",
    key: "role",
    sortable: true,
  },
  {
    title: "Phone",
    key: "phone",
    sortable: true,
  },
  {
    title: "Branch",
    key: "branch",
    sortable: true,
  },
  {
    title: "Status",
    key: "status",
    sortable: true,
  },
  {
    title: "2FA Status",
    key: "2fa_status",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
])

const users = ref([])
const branches = ref([])
const openModal = ref(false)
const deleteDialog = ref(false)
const isCanSeeCreateBtn = ref(false)
const isCanSeeInviteBtn = ref(false)
const isCanSeeManageCustomFieldsBtn = ref(false)
const modeModal = ref("create")
const openInviteModal = ref(false)
const isCustomFieldsDialogVisible = ref(false)
const customFormFields = ref([])
const system_form_field_types = ref([])
const filterInfo = ref({})
const editedItem = ref({})
const allowed_roles = ref([])
const allowed_roles_for_edit_delete = ref([])
const usersAll = ref([])
const rolesMapping = ref([])

const status = ref([
  {
    title: "Active",
    value: 1,
  },
  {
    title: "Suspend",
    value: 0,
  },
])

const roles = ref()

const teams = ref([])

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then(response => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch(error => {})
    .finally(() => {})
}

const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/users`)
    .then(response => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch(error => {})
    .finally(() => {})
}

onMounted(async () => {



  const userData = useCookie("userData").value
  if(userData.role == 'master_admin'){
    headers.value = [
      {
        title: "User",
        key: "first_name",
      },
      {
        title: "Title",
        key: "title",
      },
      {
        title: "Role",
        key: "role",
        sortable: true,
      },
      {
        title: "Phone",
        key: "phone",
        sortable: true,
      },
      {
        title: "Branch",
        key: "branch",
        sortable: true,
      },
      {
        title: "Status",
        key: "status",
        sortable: true,
      },
      {
        title: "2FA Status",
        key: "2fa_status",
        sortable: false,
      },
      {
        title: "Actions",
        key: "actions",
        sortable: false,
      },
    ]
  }else{
    headers.value = [
      {
        title: "User",
        key: "first_name",
      },
      {
        title: "Title",
        key: "title",
      },
      {
        title: "Role",
        key: "role",
        sortable: true,
      },
      {
        title: "Phone",
        key: "phone",
        sortable: true,
      },
      {
        title: "Branch",
        key: "branch",
        sortable: true,
      },
      {
        title: "Status",
        key: "status",
        sortable: true,
      },
      {
        title: "Actions",
        key: "actions",
        sortable: false,
      },
    ]
  }


  // allowed roles for current user
  axios
    .get("users/allowed/roles_list")
    .then(async response => {
      if (response.status == "success") {
        allowed_roles_for_edit_delete.value = response.data.roles
        allowed_roles.value = Object.keys(response.data.roles).map(key => ({
          key,
          value: response.data.roles[key],
        }))
        await canSeeCreateBtn()
        await canSeeInviteBtn()
      }
    })
    .catch(error => {})
    .finally(() => {})

  // get all roles list
  axios
    .get("get-all-roles")
    .then(async response => {
      if (response.status == "success") {
        console.log("response", response)
        roles.value = Object.entries(response.data.roles).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            }
          },
        )
      }
    })
    .catch(error => {})
    .finally(() => {})

  // get all branches
  axios
    .get("branches/list/all")
    .then(response => {
      if (response.status == "success") {
        branches.value = response.data.branches.map(branch => {
          return {
            title: branch.branch_name,
            value: branch.id,
          }
        })

        // branches.value.unshift({ title: "-- Select Options --", value: "" });
      }
    })
    .catch(error => {})
    .finally(() => { })


  axios
    .get("teams/list/all")
    .then(response => {
      if (response.status == "success") {
        teams.value = response.data.teams.map(team => {
          return {
            title: team.name,
            value: team.id,
          }
        })
      }
    })
    .catch(error => {})
    .finally(() => { })

  axios
    .get("get-all-users")
    .then(async response => {
      if (response.status == "success") {
        usersAll.value = Object.entries(response.data.users).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.first_name + " " + value.last_name,
            }
          },
        )
      }
    })
    .catch(error => {})
    .finally(() => {})

  axios
    .get("get-all-roles-by-id")
    .then(async response => {
      if (response.status == "success") {
        rolesMapping.value = response.data.roles
      }
    })
    .catch(error => { })
    .finally(() => { })

  getSystemFormFieldTypes()
  canSeeManageCustomFieldsButton()
  getCustomFormFields()
})

const getUsers = async (payload = null) => {
  let url = `/users?page=${payload.page}&per_page=${payload.perPage}`
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`
  }
  if (payload.role && payload.role.trim() !== "") {
    url += `&role=${payload.role}`
  }
  if (payload.status == 1 || payload.status == 0) {
    url += `&status=${payload.status}`
  }
  let loader = $loading.show()
  axios
    .get(url)
    .then(response => {
      if (response.status == "success") {
        users.value = response.data.users
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {
      loader.hide()
    })
    .finally(() => {
      loader.hide()
    })
}

// Page changed from pagination
const tableChanged = async payload => {
  filterInfo.value = { ...filterInfo.value, ...payload }
  getUsers(filterInfo.value)
}

const roleChanged = async payload => {
  filterInfo.value.role = payload
  getUsers(filterInfo.value)
}

const statusChanged = async payload => {
  filterInfo.value.status = payload
  getUsers(filterInfo.value)
}

const searchValue = async payload => {
  filterInfo.value.searchQuery = payload
  getUsers(filterInfo.value)
}

// Page changed from pagination
const refreshUsers = async () => {
  getUsers(filterInfo.value)
}

const customFieldsUpdated = async () => {
  getCustomFormFields()
}

const editItem = async item => {
  router.push({ name: "user-management-id", params: { id: item.id } })


  // const { branch, custom_fields, ...newItem } = item;
  // editedItem.value = {
  //   ...newItem,
  //   branch_id: newItem.branch_id !== null ? parseInt(newItem.branch_id) : "", // Convert branch_id from string to integer
  //   values: toRaw(item?.custom_fields)
  // };
  // console.log('editedItem', editedItem.value);
  // modeModal.value = "Update";
  // openModal.value = true;
}

const deleteItem = item => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
}

const viewItem = item => {
  router.push({ name: "user-management-view-id", params: { id: item.id } })
}

const openCustomFieldsModal = value => {
  isCustomFieldsDialogVisible.value = true
}

const deleteItemConfirm = async () => {
  axios
    .delete("/users/" + `${editedItem.value.id}`)
    .then(response => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        })
        deleteDialog.value = false

        // refresh the users list via API
        getUsers(filterInfo.value)
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {})
    .finally(() => {})
}

const openCreateModel = async () => {
  router.push({ name: "user-management-create" })

  // modeModal.value = "create";
  // editedItem.value = {};
  // openModal.value = true;
}

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value
  const userPermissionsByRoles = userData.permissions_by_roles
  let hasPermission = Object.values(userPermissionsByRoles).some(
    permissions => permissions.includes("Add User"),
  )
  let allowed_roles_available = allowed_roles_for_edit_delete.value.length != 0
  if (hasPermission && allowed_roles_available) {
    isCanSeeCreateBtn.value = true
  } else {
    isCanSeeCreateBtn.value = false
  }
}

// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value
  const userPermissionsByRoles = userData.permissions_by_roles
  let hasPermission = Object.values(userPermissionsByRoles).some(
    permissions => permissions.includes("User Custom Fields"),
  )
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true
  } else {
    isCanSeeManageCustomFieldsBtn.value = false
  }
}


const canSeeInviteBtn = async () => {
  const userData = useCookie("userData").value
  const userPermissionsByRoles = userData.permissions_by_roles
  let hasPermission = Object.values(userPermissionsByRoles).some(
    permissions => permissions.includes("Create User Invite"),
  )
  let allowed_roles_available = allowed_roles_for_edit_delete.value.length != 0
  if (hasPermission && allowed_roles_available) {
    isCanSeeInviteBtn.value = true
  } else {
    isCanSeeInviteBtn.value = false
  }
}


// expose errors and getUsers
defineExpose({
  errors,
  getUsers,
})

definePage({ meta: { requiresAuth: true } })
</script>

<template>
  <VCard
    title=""
    class=""
  >
    <VCardText
      class="p-0"
      style="padding: 0 !important;"
    >
      <!-- User Filters Section -->
      <UserFilters
        :width="6"
        :status="status"
        :roles="roles"
        @role-changed="roleChanged"
        @status-changed="statusChanged"
      />
      <VDivider />

      <!-- Users Table Section -->
      <UsersDataTable
        :headers="headers"
        :table-data="users"
        :can-see-create-btn="isCanSeeCreateBtn"
        :can-see-invite-btn="isCanSeeInviteBtn"
        :allowed_roles_for_edit_delete="allowed_roles_for_edit_delete"
        :allowed_roles="allowed_roles"
        :branches="branches"
        :can-see-manage-custom-fields-btn="isCanSeeManageCustomFieldsBtn"
        @table-changed="tableChanged"
        @search-value="searchValue"
        @is-dialog-visible="openCreateModel"
        @invite-user="openInviteModal = true"
        @edit-item="editItem"
        @delete-item="deleteItem"
        @view-item="viewItem"
        @open-custom-fields-modal="openCustomFieldsModal"
      />
    </VCardText>
  </VCard>

  <!-- Modals Below -->

  <!-- User Edit/Create Modal -->
  <UserForm
    v-model:isDialogVisible="openModal"
    :user-data="editedItem"
    :mode="modeModal"
    :allowed_roles="allowed_roles"
    :branches="branches"
    :status="status"
    :custom_fields="customFormFields"
    @refresh-users="refreshUsers"
  />
  <!-- Invite User Modal -->
  <InviteUserForm
    v-model:isInviteUserDialogVisible="openInviteModal"
    :allowed_roles="allowed_roles"
    @refresh-users="refreshUsers"
  />
  <!-- User Delete Confirmation Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @close-delete="deleteDialog = false"
    @delete-item-confirm="deleteItemConfirm"
  />
  <!-- User Custom Fields Form -->
  <UserCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :users-all="usersAll"
    :branches-all="branches"
    :roles-all="roles"
    :teams-all="teams"
    :roles-mapping="rolesMapping"
    :system_form_field_types="system_form_field_types"
    @custom-fields-updated="customFieldsUpdated"
  />
</template>
