<script setup>
import { onMounted, ref, watch, nextTick, onUnmounted } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";
import interact from "interactjs";
import AppDateTimePicker from "@/@core/components/app-form-elements/AppDateTimePicker.vue";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";

const router = useRouter();
const $loading = useLoading(loaderData);

const organizationData = ref({
    name: "",
    description: "",
    phone: "",
    address_line_1: "",
    address_line_2: "",
    country_id: "",
    state_id: "",
    zip_code: "",
    branch_id: "",
    values: [],
});

const lookup_fields = ref([]);
const modules = ref([])
const getLookupFields = () => {
    axios
    .get("lookup_fields")
    .then(async (response) => {
        if (response.status == "success") {
        lookup_fields.value = response.data.lookup_fields
        modules.value = Object.keys(response.data.lookup_fields).map(key => ({
            title: response.data.lookup_fields[key].name,
            value: key
        }));
        }
    })
    .catch((error) => {})
    .finally(() => {});
};

const lookupFieldAttributes = computed(() => {

    let attributes = [];
        if(customFormFieldInstance.value.table){
        let lookup_field_columns = lookup_fields.value[customFormFieldInstance.value.table].columns
        console.log('lookup_field_columns', lookup_field_columns)
        attributes = Object.keys(lookup_field_columns).map(key => ({
            title: lookup_field_columns[key],
            value: key
        }));
        let attribute_keys = Object.keys(lookup_field_columns);
        if(!attribute_keys.includes(customFormFieldInstance.value.column)){
            customFormFieldInstance.value.column = null
        }
        }else{
        attributes = [];
        }
        return attributes;

})

const fieldLookupSettingChanged = () => {
    customFormFieldInstance.value.options = [];
}

const route = useRoute("organizations-list");
const branches = ref([]);
const submitBtn = ref(1);
const statesDropdown = ref(1);
const countries = ref([]);

const organizationsForField = ref([]);
const searchOrganizationsForFieldOnChange = () => {
    let organizations = [];
    customFormFieldInstance.value.organizations.forEach((org) => {
        if (org.value) {
            organizations.push(org.value)
        } else {
            organizations.push(org)
        }
    })
    fetchOrganizations('', organizations)
}
const customFieldPickListUpdatedExecute = (form_field) => {

    if(form_field.is_lookup_field && (form_field.parent_lookup_field_id == 0 || form_field.act_as_parent)){
        
        let parentField = organizationData.value.values.find((value) => value.form_field_id == form_field.id);
        let parent_value = parentField ? parentField.value : null;
        if(parent_value){
            axios
                .get(
                    "/custom_fields/get_lookup_field_record" + `?table=${form_field.table}&id=${findFormFieldValue(form_field.id).value}`
                )
                .then((response) => {
                    if (response.status == "success") {
                        // if child who act as parent is changed
                        if(form_field.act_as_parent){
                            // update parent field
                            const parentField = customFormFields.value.filter(f => f.id === form_field.parent_lookup_field_id)
                            parentField.forEach(element => {
                                organizationData.value.values.forEach((val) => {
                                    if(val.form_field_id == element.id){
                                        val.value = response.data.model['id']
                                    }
                                })
                            });
                            // update fields that are child of parent
                            const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.parent_lookup_field_id)
                            matchingFields.forEach((field) => {
                                organizationData.value.values.forEach((val) => {
                                    if(val.form_field_id == field.id){
                                        if(field.act_as_parent){
                                            val.value = response.data.model['id']
                                        }else{
                                            val.value = response.data.model[field.column]
                                        }
                                    }
                                })
                            })
                        }else{
                            // if parent is changed
                            const matchingFields = customFormFields.value.filter(f => f.parent_lookup_field_id === form_field.id)
                            matchingFields.forEach((field) => {
                                organizationData.value.values.forEach((val) => {
                                    if(val.form_field_id == field.id){
                                        if(field.act_as_parent){
                                            val.value = response.data.model['id']
                                        }else{
                                            val.value = response.data.model[field.column]
                                        }
                                    }
                                })
                            })
                        }
                    }
                })
                .catch((error) => { })
                .finally(() => { });
        }
        
    }
}
const customFieldPickListUpdated = (form_field) => {
    setTimeout(() => {
        customFieldPickListUpdatedExecute(form_field);
    }, 50);
}

const searchOrganizationsForField = (event) => {
    let organizations = customFormFieldInstance.value.organizations.map(item => item.value ?? item)
    fetchOrganizations(event.target.value, organizations)
}
const fetchOrganizations = (search = '', includes = []) => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/organizations?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                organizationsForField.value = response.data.organizations.data.map((organization) => {
                    return {
                        title: organization.name,
                        value: organization.id,
                    };
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const branchesForField = ref([]);
const searchBranches = (event) => {
    fetchBranches(event.target.value, [organizationData.value.branch_id])
}
const searchBranchesForField = (event) => {
    let branches = customFormFieldInstance.value.branches.map(item => item.value ?? item)
    fetchBranches(event.target.value, branches, 'field_data')
}
const searchBranchesForFieldOnChange = () => {
    let branches = [];
    customFormFieldInstance.value.branches.forEach((branch) => {
        if (branch.value) {
            branches.push(branch.value)
        } else {
            branches.push(branch)
        }
    })

    fetchBranches('', branches, 'field_data')
}
const fetchBranches = (search = '', includes = [], fetch_for = 'organization_data') => {
    let includes_to_arr = includes.join(',');
    axios
        .get(`/branches?search_query=${search}&includes=${includes_to_arr}`)
        .then((response) => {
            if (response.status == "success") {
                if (fetch_for == 'organization_data') {
                    branches.value = response.data.branches.data.map((branch) => {
                        return {
                            title: branch.branch_name,
                            value: branch.id,
                        };
                    });
                }
                else if (fetch_for == 'field_data') {
                    branchesForField.value = response.data.branches.data.map((branch) => {
                        return {
                            title: branch.branch_name,
                            value: branch.id,
                        };
                    });
                }

            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const system_form_field_types = ref([])
const getSystemFormFieldTypes = (search_query = "") => {
    axios
        .get(`/system_form_field_types`)
        .then((response) => {
            if (response.status == "success") {
                system_form_field_types.value = response.data.field_types
            }
        })
        .catch((error) => { })
        .finally(() => { });
};

const makeFieldsDraggable = () => {
    interact(".form_field_edit_organizations").draggable({
        // inertia: true, // Add inertia for a smoother dragging experience
        autoScroll: true,
        onstart: (event) => { },
        onmove: (event) => {
            var target = event.target;
            var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
            var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
            target.style.transform = "translate(" + x + "px, " + y + "px)";
            target.setAttribute("data-x", x);
            target.setAttribute("data-y", y);
        },
        onend: (event) => {
            const target = event.target;
            const dropzone = event.dropzone;

            if (!dropzone) {
                // If not dropped inside a dropzone, move back to original position
                target.style.transform = `translate(0px, 0px)`;
                target.setAttribute("data-x", 0);
                target.setAttribute("data-y", 0);
            }
        },
    });
}

const initializeDropZone = () => {
    interact(".form_fields_dropzone_edit_organization").dropzone({
        accept: ".form_field_edit_organizations",
        overlap: 0.75,
        ondragenter: function (event) {
            event.target.classList.add("drop-area-bg");
        },
        ondragleave: function (event) {
            event.target.classList.remove("drop-area-bg");
        },
        ondrop: (event) => {
            event.target.classList.remove("drop-area-bg");
            let droppedElement = event.relatedTarget;
            let type = droppedElement.getAttribute("type");
            if (type) {
                const field_type = system_form_field_types.value.find(
                    (item) => item.type === type
                );
                if (field_type) {
                    addCustomFormField(field_type);
                }
            }
            droppedElement.style.transform = `translate(0px, 0px)`;
            droppedElement.setAttribute("data-x", "0px");
            droppedElement.setAttribute("data-y", "0px");
        },
    });
}

onUnmounted(() => {
  interact('.form_field_edit_organizations').unset();
  interact('.form_fields_dropzone_edit_organization').unset();
  interact('.draggable-col-organizations-edit').unset();
});


const canManageCustomFields = ref(false)

const getCountries = (search_query = "") => {
  countriesDropdown.value = 0;
  axios
    .get(`/countries?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        countries.value = response.data.countries.map((country) => {
          return {
            title: country.name,
            value: country.id,
          };
        });
      }
    })
    .catch((error) => {})
    .finally(() => {
      countriesDropdown.value = 1;
    });
};

const getOrganizationData = function () {
    let id = route.params.id;
    let loader = $loading.show();
    let url = `/organizations/${id}`;
    axios
        .get(url)
        .then((response) => {
            if (response.status == "success") {
                organizationData.value = response.data.organization;
                organizationData.value.values = response.data.organization.custom_fields;
                fetchBranches('', [organizationData.value.branch_id]);
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}
const isFieldsReady = ref(false)
const fieldOrders = ref([])

const currentTab = ref(0)
const updateCurrentTab = (tabId) => {
  currentTab.value = tabId
}

const formFieldsByTab = (tabId) => {
  if (tabId == 0) {
    return customFormFields.value
      .map((field, index) => ({ ...field, originalIndex: index }))
      .filter(field => field.form_tab_id == null);
  }
  return customFormFields.value
    .map((field, index) => ({ ...field, originalIndex: index }))
    .filter(field => field.form_tab_id === tabId);
}

const formTabs = ref([]);
const customFieldActiveTab = ref(0);

onMounted(async () => {

    try {

        const userData = useCookie("userData").value;
        const userPermissionsByRoles = userData.permissions_by_roles;
        let hasPermission = Object.values(userPermissionsByRoles).some(
            (permissions) => permissions.includes("Organization Custom Fields")
        );
        if (hasPermission) {
            canManageCustomFields.value = true;
        } else {
            canManageCustomFields.value = false;
        }


        // Fetch both field orders and custom fields
        const [fieldOrdersResponse, customFieldsResponse, formTabsResponse] = await Promise.all([
            axios.get('/module-fields/organizations'),
            axios.get('/custom_fields/organizations'),
            axios.get('/form_tabs/organization')
        ])

        if (fieldOrdersResponse.status === 'success' && customFieldsResponse.status === 'success') {
            fieldOrders.value = fieldOrdersResponse.data.field_orders
            customFormFields.value = customFieldsResponse.data.form_fields
            isFieldsReady.value = true

            // Wait for DOM to update before reordering
            await nextTick()
            reorderFields()
        }

        if (formTabsResponse.status === 'success') {
            formTabs.value = formTabsResponse.data
        }
    } catch (error) {
        console.error('Error initializing fields:', error)
    }


    getLookupFields()
    getOrganizationData();
    getSystemFormFieldTypes();

    if(canManageCustomFields.value){
        makeFieldsDraggable();
        initializeDropZone();
    }

    fetchBranches();
    getCountries();

    if(canManageCustomFields.value){

        interact('.draggable-col-organizations-edit')
        .draggable({
            autoScroll: true,
            onstart: (event) => { },
            onmove: (event) => {
                var target = event.target;
                var x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
                var y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;
                target.style.transform = "translate(" + x + "px, " + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
            },
            onend: (event) => {
                const target = event.target;
                const dropzone = event.dropzone;

                if (!dropzone) {
                    target.style.transform = `translate(0px, 0px)`;
                    target.setAttribute("data-x", 0);
                    target.setAttribute("data-y", 0);
                }
            },
        })
        .dropzone({
            accept: '.draggable-col-organizations-edit',
            overlap: 0.1,
            ondragenter: function (event) {
                event.target.classList.add('drop-area-bg');
            },
            ondragleave: function (event) {
                event.target.classList.remove('drop-area-bg');
            },
            ondrop: (event) => {
                event.target.classList.remove('drop-area-bg');
                const draggedElement = event.relatedTarget;
                const targetElement = event.target;

                const draggedOrder = draggedElement.getAttribute('data-order');
                const targetOrder = targetElement.getAttribute('data-order');

                // update the field order
                axios.post('/module-fields/organizations/reorder', {
                    dragged_field: draggedOrder,
                    target_field: targetOrder
                })
                .then((response) => {
                    if (response.status == "success") {
                        console.log('Field order updated successfully');
                        fieldOrders.value = response.data.field_orders
                        nextTick(() => {
                            reorderFields();
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error updating field order:', error);
                });

                draggedElement.style.transform = `translate(0px, 0px)`;
                draggedElement.setAttribute("data-x", "0px");
                draggedElement.setAttribute("data-y", "0px");
           }
        });

    }
    
});

watch(
  () => organizationData.value.country_id,
  (newValue, oldValue) => {
    getStates(newValue);
  }
);

const countriesDropdown = ref(1);
const states = ref([]);

const getStates = (country_id, search_query = "") => {
  if (!country_id || country_id == undefined) {
    return;
  }
  statesDropdown.value = 0;
  axios
    .get(`get/states/${country_id}?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        states.value = response.data.states.map((state) => {
          return {
            title: state.name,
            value: state.id,
          };
        });
        if (response.data.states.length === 0) {
          statesDropdown.value = 0;
          organizationData.value.state_id = '';
          toast.error("No states are available for this country.", {
            autoClose: 3000,
          });
        } else {
          if (!states.value.find(state => state.value === organizationData.value.state_id)) {
            organizationData.value.state_id = '';
          } 
          statesDropdown.value = 1;
        }
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const updateOrganization = async () => {
    let loader = $loading.show();
    submitBtn.value = 0;
    let form_data = organizationData.value;
    form_data.branch_id = form_data?.branch_id?.value ? form_data?.branch_id?.value : form_data?.branch_id;

    axios
        .patch("/organizations/" + `${organizationData.value.id}`, form_data)
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                router.push({ name: "organizations-list" });
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
            submitBtn.value = 1;
        });
};

const discardChanges = () => {
    router.push({ name: "organizations-list" });
}

const customFormFields = ref([])
const getCustomFormFields = () => {
    axios
        .get(`/custom_fields/organizations`)
        .then((response) => {
            if (response.status == "success") {
                customFormFields.value = response.data.form_fields
                nextTick(() => {
                    reorderFields();
                });
            }
        })
        .catch((error) => { })
        .finally(() => { });
}

const resolveIconForFieldType = (system_form_field) => {
    if (system_form_field.type == "single_line") {
        return "tabler-cursor-text";
    } else if (system_form_field.type == "multi_line") {
        return "tabler-forms";
    } else if (system_form_field.type == "integer") {
        return "tabler-123";
    } else if (system_form_field.type == "percent") {
        return "tabler-percentage";
    } else if (system_form_field.type == "decimal") {
        return "tabler-decimal";
    } else if (system_form_field.type == "currency") {
        return "tabler-cash";
    } else if (system_form_field.type == "date") {
        return "tabler-calendar-month";
    } else if (system_form_field.type == "date_time") {
        return "tabler-calendar-clock";
    } else if (system_form_field.type == "email") {
        return "tabler-mail";
    } else if (system_form_field.type == "phone") {
        return "tabler-phone";
    } else if (system_form_field.type == "pick_list") {
        return "tabler-checkup-list";
    } else if (system_form_field.type == "multi_select") {
        return "tabler-list-check";
    } else {
        return "";
    }
};

const findFormFieldValue = (fieldId) => {
    return organizationData.value.values.find(field => field.form_field_id === fieldId) || { value: '' };
}

const transformedOptions = (form_field) => {
    return form_field.options.map((option) => ({
        title: option.name,
        value: option.id,
    }));
};

const showfield = (form_field) => {
    let conditions = form_field.conditions;
    let condition_status = false;
    if (conditions.length > 0) {
        conditions.forEach((condition) => {
            let parentField = organizationData.value.values.find(field => field.form_field_id === condition.parent_form_field_id);
            if (parentField) {
                let parent_value = parentField.value;
                let parent_options = customFormFields.value.find(field => field.id === condition.parent_form_field_id).options;
                if (parent_value) {
                    let parent_selected_option = parent_options.find(option => option.id == parent_value);
                    if (parent_selected_option && parent_selected_option.name == condition.value) {
                        condition_status = true;
                    }
                }
            }
        });
    } else {
        condition_status = true;
    }

    // check for the field layers
    let layers_status = false;
    let selected_organization = organizationData.value.id;
    let form_field_layer_option_organization = form_field.meta.layer_option.organizations;
    if (form_field_layer_option_organization == 'all') {
        layers_status = true;
    }
    else if (form_field_layer_option_organization == 'custom' && form_field.organizations.includes(selected_organization)) {
        layers_status = true;
    }

    let form_field_layer_option_branch = form_field.meta.layer_option.branches;
    let selected_branch = organizationData.value?.branch_id?.value ? organizationData.value?.branch_id.value : organizationData.value?.branch_id;
    let ff_branches = [];
    if(form_field.branches){
        if(!Array.isArray(form_field.branches)){
            ff_branches = [form_field.branches]
        }else{
            ff_branches = form_field.branches
        }
    }else{
        ff_branches = [];
    }

    if (form_field_layer_option_branch == 'all' && selected_branch) {
        layers_status = true;
    }
    else if (form_field_layer_option_branch == 'custom' && ff_branches.includes(selected_branch)) {
        layers_status = true;
    }

    return condition_status && layers_status;
};


const deleteFieldButton = (form_field) => {
    return true;
}

const customFieldDialog = ref(false)
const addCustomFormField = (type) => {
    customFieldDialog.value = true;
    customFieldDialogTab.value = 0;
    customFormFieldInstance.value = {
        name: '',
        type: type.type,
        meta: {
            layer_option: {
                organizations: 'custom',
                branches: 'none',
            },
        },
        form_tab_id: customFieldActiveTab.value == 0 ? null : currentTab.value,
        conditions: [],
        options: [],
        organizations: [],
        branches: [],
    }
}

const customFieldDialogTab = ref(1)
const customFormFieldInstance = ref({})
const saveFormFieldChanges = async () => {

    let url = '/custom_fields/organizations/create_update_field';

    let loader = $loading.show();

    axios
        .post(url, {
            form_field: customFormFieldInstance.value
        })
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });

                customFieldDialog.value = false;
                customFieldDialogTab.value = 0;

                getCustomFormFields();

                nextTick(() => {
                    reorderFields();
                });

            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}


const reorderFields = () => {
    // Get all field elements from all tabs
    const defaultTabRow = document.querySelector('.fields_row');
    if (!defaultTabRow) return;

    // Sort fields array by display_order
    const sortedFields = [...fieldOrders.value.fields].sort((a, b) => a.display_order - b.display_order);
    
    // Get all field elements from all tabs
    const allTabRows = document.querySelectorAll('.fields_row');
    
    allTabRows.forEach(formRow => {
        // Get all field elements in this specific tab
        const tabFieldElements = formRow.querySelectorAll('.draggable-col-organizations-edit');
        
        // Track which elements have been ordered
        const orderedElements = new Set();
        
        // First reorder elements that exist in fieldOrders
        sortedFields.forEach((fieldData) => {
            const fieldElement = formRow.querySelector(`[data-order="${fieldData.field}"]`);
            if (fieldElement && fieldElement.parentElement === formRow) {
                formRow.appendChild(fieldElement);
                orderedElements.add(fieldElement);
            }
        });
        
        // Append any remaining elements (new fields) to the end
        tabFieldElements.forEach(element => {
            if (!orderedElements.has(element) && element.parentElement === formRow) {
                formRow.appendChild(element);
            }
        });
    });
};


// Add a watch for fieldOrders to ensure reordering happens when data changes
watch(
    fieldOrders,
    () => {
        if (isFieldsReady.value) {
            nextTick(() => {
                reorderFields()
            })
        }
    },
    { deep: true }
)

const currentFieldOptionValue = ref('')
const addFormFieldOption = () => {
    if (currentFieldOptionValue.value != "") {
        let currentFormFieldOptions = customFormFieldInstance.value.options;
        currentFormFieldOptions.push(currentFieldOptionValue.value);
        customFormFieldInstance.value.options = currentFormFieldOptions;
        currentFieldOptionValue.value = "";
    }
};


const removeFormFieldOption = (index) => {
    if (customFormFieldInstance.value.options[index].id) {
        axios
            .delete(
                "/helpdesk/form_field_options/" +
                `${customFormFieldInstance.value.options[index]
                    .id
                }`
            )
            .then((response) => {
                if (response.status == "success") {
                    customFormFieldInstance.value.options.splice(
                        index,
                        1
                    );
                }
            })
            .catch((error) => { })
            .finally(() => { });
    } else {
        customFormFieldInstance.value.options.splice(
            index,
            1
        );
    }
};

const deleteFormFieldDialog = ref(false);
const deleteFormFieldId = ref('')
const removeFormField = (form_field_id) => {
    deleteFormFieldId.value = form_field_id
    deleteFormFieldDialog.value = true;
};

const removeFormFieldSubmit = () => {
    let loader = $loading.show();

    axios
        .delete(
            "/helpdesk/form_fields/" + `${deleteFormFieldId.value}`
        )
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                getCustomFormFields();
                deleteFormFieldDialog.value = false;
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
};

const editFieldSettings = (form_field) => {
    customFormFieldInstance.value = form_field;
    customFieldDialogTab.value = 0;
    customFieldDialog.value = true;
}

const OrganizationteUserCustomFields = () => {
    let custom_fields = customFormFields.value;
    let organizationDataValues = organizationData.value.values;
    custom_fields.forEach((custom_field) => {
        // check if field is cleared from layers and conditions checks...
        if (showfield(custom_field)) {
            // check if field already exists in organizationData.values 
            let exists = false;
            organizationDataValues.forEach((dataValue) => {
                if (dataValue.form_field_id == custom_field.id) {
                    exists = true;
                }
            });

            if (!exists) {
                organizationData.value.values.push({
                    form_field_id: custom_field.id,
                    value: ''
                });
            }
        }
    });
};

const updateOrganizationCustomFields = () => {
    let custom_fields = customFormFields.value;
    let organizationDataValues = organizationData.value.values;
    custom_fields.forEach((custom_field) => {
        if (showfield(custom_field)) {
            let exists = false;
            organizationDataValues.forEach((organizationDataValue) => {
                if (organizationDataValue.form_field_id == custom_field.id) {
                    exists = true;
                }
            });

            if (!exists) {
                if(custom_field.type == 'multi_select'){
                    organizationData.value.values.push({
                        form_field_id: custom_field.id,
                        value: []
                    });
                }
                else{
                    organizationData.value.values.push({
                        form_field_id: custom_field.id,
                        value: ''
                    });
                }
            }else{
                if(custom_field.type == 'multi_select'){
                    let allOptionsAreValid = true;
                    let neworganizationDataValues = organizationDataValues.map((organizationDataValue) => {
                        if (organizationDataValue.form_field_id == custom_field.id) {
                            let selected_options = organizationDataValue.value.map((item) => item);
                            let available_options = custom_field.options.map((item) => item.id);
                            let valid_selected_options = selected_options.filter(option => available_options.includes(option));
                            if(valid_selected_options.length != selected_options.length){
                                allOptionsAreValid = false;
                            }
                            return {
                                form_field_id: organizationDataValue.form_field_id,
                                value: valid_selected_options
                            }
                        }
                        return organizationDataValue;
                    });
                    if(!allOptionsAreValid){
                        organizationData.value.values = neworganizationDataValues
                    }
                }
            }
        }
    });
};

const onPhoneInput = (event) => {
  let value = event.target.value.replace(/[^\d]/g, '');
  value = value.slice(0, 10);
  if (value.length >= 3) {
    value = value.slice(0, 3) + '-' + value.slice(3);
    if (value.length >= 7) {
      value = value.slice(0, 7) + '-' + value.slice(7);
    }
  }
  organizationData.value.phone = value;
};

const phoneValidator = (value) => {
  const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
  return phoneRegex.test(value) || !value || 'Please enter a valid phone number (XXX-XXX-XXXX)';
};

const onCustomPhoneInput = (event, fieldId) => {
  let value = event.target.value.replace(/[^\d]/g, '');
  value = value.slice(0, 10);
  if (value.length >= 3) {
    value = value.slice(0, 3) + '-' + value.slice(3);
    if (value.length >= 7) {
      value = value.slice(0, 7) + '-' + value.slice(7);
    }
  }
  const field = organizationData.value.values.find(field => field.form_field_id === fieldId);
  if (field) {
    field.value = value;
  }
};

watch(
    customFormFieldInstance,
    async (newCustomFormFieldInstance) => {
        searchOrganizationsForFieldOnChange();
        searchBranchesForFieldOnChange();
    }
);

watch(
    customFormFields,
    async (newCustomFields) => {
        updateOrganizationCustomFields();
    },
    { deep: true }
);

watch(
    organizationData,
    async (newOrganizationData) => {
        updateOrganizationCustomFields();
    },
    { deep: true }
);


definePage({ meta: { requiresAuth: true } });

const swapArrayElements = (arr, fromIndex, toIndex) => {
    // Update parent lookup field indexes
    arr.forEach((field) => {
        if (field.parent_lookup_field_id) {
            const parentIndex = arr.findIndex(f => f.id === field.parent_lookup_field_id);
            if (parentIndex === fromIndex) {
                field.parent_lookup_field_id = arr[toIndex].id;
            } else if (parentIndex === toIndex) {
                field.parent_lookup_field_id = arr[fromIndex].id;
            }
        }

        // Update conditions
        if (field.conditions && field.conditions.length > 0) {
            field.conditions.forEach((condition) => {
                if (condition.parent_form_field_id === arr[fromIndex].id) {
                    condition.parent_form_field_id = arr[toIndex].id;
                } else if (condition.parent_form_field_id === arr[toIndex].id) {
                    condition.parent_form_field_id = arr[fromIndex].id;
                }
            });
        }
    });

    // Swap the elements
    const temp = arr[fromIndex];
    arr[fromIndex] = arr[toIndex];
    arr[toIndex] = temp;

    // Update display orders
    let fromDisplayOrder = arr[fromIndex].display_order;
    arr[fromIndex].display_order = arr[toIndex].display_order;
    arr[toIndex].display_order = fromDisplayOrder;

    // Update both fields using existing API
    updateField(arr[fromIndex]);
    updateField(arr[toIndex]);
};

const updateField = async (field) => {
    try {
        await axios.post("/custom_fields/organizations/create_update_field", {
            form_field: field
        });
    } catch (error) {
        // Refresh the fields to ensure correct order
        getCustomFormFields();
    }
};

const dateTimePickerConfig = {
  dateFormat: 'm/d/Y', // MM/DD/YYYY format
  allowInput: true, // Allow manual input
  maxDate: new Date().toLocaleDateString('en-US'),
};
</script>

<template>
    <div>
        <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
            <div class="d-flex flex-column justify-center">
                <h2>
                    <strong class="oswald">Edit Organization</strong>
                </h2>
                <span class="lato-light">You can edit organization on this page</span>
            </div>

            <div class="d-flex gap-4 align-center flex-wrap">
                <VBtn @click.prevent="discardChanges" variant="tonal" color="dark">
                    Discard
                </VBtn>
                <VBtn @click.prevent="updateOrganization">Update</VBtn>
            </div>
        </div>

        <VRow>
            <VCol cols="12" :md="canManageCustomFields ? 9 : 12">
                <VCard class="mb-6 form_fields_dropzone_edit_organization " title="Organization Information">
                    <VCardText>

                        <VForm class="mt-6" @submit.prevent="updateOrganization()">

                            <VTabs
                                v-model="customFieldActiveTab"
                                class=" "
                            >
                                <VTab @click="updateCurrentTab(0)">Default </VTab>
                                <VTab v-for="formTab in formTabs" :key="formTab.id" @click="updateCurrentTab(formTab.id)" >
                                    {{ formTab.name }}
                                </VTab>
                            </VTabs>

                            <VWindow v-model="customFieldActiveTab">
                                <VWindowItem
                                    :key="0"
                                    :value="`item-${0}`"
                                > 
                                    <div class="py-5 px-3">

                                        <VRow class="fields_row">
                                
                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="name">
                                                <AppTextField
                                                    v-model="organizationData.name"
                                                    label="Organization Name *"
                                                    placeholder="Organization Name"
                                                    :rules="[requiredValidator]"
                                                />
                                            </VCol>

                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="branch_id">
                                                <AppAutocomplete @keyup="searchBranches" v-model="organizationData.branch_id" :items="branches"
                                                    label="Select Branch" placeholder="Select Branch" autocomplete="no" />
                                            </VCol>

                                            <VCol class="draggable-col-organizations-edit" cols="12" data-order="description">
                                                <AppTextarea
                                                    rows="3"
                                                    v-model="organizationData.description"
                                                    label="Organization Description *"
                                                    placeholder="Organization Description"
                                                    :rules="[requiredValidator]"
                                                />
                                            </VCol>

                                            <VCol class="draggable-col-organizations-edit" cols="12" data-order="address_line_1">
                                                <AppTextField
                                                    v-model="organizationData.address_line_1"
                                                    label="Address Line 1"
                                                    placeholder="Address Line 1"
                                                />
                                            </VCol>

                                            <!-- Address Line 2 -->
                                            <VCol class="draggable-col-organizations-edit" cols="12" data-order="address_line_2">
                                                <AppTextField
                                                    v-model="organizationData.address_line_2"
                                                    label="Address Line 2"
                                                    placeholder="Address Line 2"
                                                />
                                            </VCol>

                                            <!-- Country -->
                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="country_id">
                                                <AppSelect
                                                    :disabled="countriesDropdown == 0"
                                                    v-model="organizationData.country_id"
                                                    :items="countries"
                                                    label="Country"
                                                />
                                            </VCol>

                                            <!-- State -->
                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="state_id">
                                                <AppSelect
                                                    :disabled="!organizationData.country_id || statesDropdown == 0"
                                                    v-model="organizationData.state_id"
                                                    :items="states"
                                                    label="State"
                                                />
                                            </VCol>

                                            <!-- Zip Code -->
                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="zip_code">
                                                <AppTextField
                                                    v-model="organizationData.zip_code"
                                                    label="Zip Code"
                                                    placeholder="Zip Code"
                                                />
                                            </VCol>

                                            <VCol class="draggable-col-organizations-edit" cols="12" md="6" data-order="phone">
                                                <AppTextField 
                                                    v-model="organizationData.phone" 
                                                    label="Phone" 
                                                    placeholder="Phone"
                                                    @input="onPhoneInput"
                                                    :rules="[phoneValidator]"
                                                />
                                            </VCol>
                                            <VCol 
                                                v-for="(form_field) in formFieldsByTab(0)" 
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-organizations-edit"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField 
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        @input="(e) => onCustomPhoneInput(e, form_field.id)"
                                                        :label="form_field.is_required ? form_field.name + ' *' : form_field.name"
                                                        :placeholder="form_field.is_required ? form_field.name + ' *' : form_field.name" 
                                                        :rules="form_field.is_required ? [requiredValidator, phoneValidator] : [phoneValidator]"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect @update:modelValue="customFieldPickListUpdated(form_field)" v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " chips multiple closable-chips />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>
                                        </VRow>

                                    </div>
                                </VWindowItem>

                                <VWindowItem
                                    v-for="formTab in formTabs"
                                    :key="formTab.id"
                                    :value="`item-${formTab.id}`"
                                >
                                    <div class="py-5 px-3">
                                        <VRow class="fields_row">

                                            <VCol 
                                                v-for="(form_field) in formFieldsByTab(formTab.id)" 
                                                :key="form_field.id"
                                                v-show="showfield(form_field)"
                                                :data-order="form_field?.id"
                                                class="draggable-col-organizations-edit"
                                                cols="12" 
                                                :md="form_field?.type == 'multi_line' ? '12' : '6'"
                                            >
                                                <div v-if="form_field.type == 'single_line'" class="rounded pa-3" style="position:relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField variant="plain"
                                                        v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_line'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextarea v-model="findFormFieldValue(form_field.id).value"
                                                        rows="2" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'integer'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, integerValidator]
                                                                    : [integerValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'percent'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' * (%)'
                                                            : form_field.name + ' (%)'
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' * (%)'
                                                                : form_field.name + ' (%)'
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, minValidator, maxValidator]
                                                                    : [minValidator, maxValidator]
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'decimal'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="number" step="0.1" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'currency'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig"
                                                        :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'date_time'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppDateTimePicker v-model="findFormFieldValue(form_field.id).value"
                                                        :config="dateTimePickerConfig" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required ? [requiredValidator] : []
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'email'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField v-model="findFormFieldValue(form_field.id).value"
                                                        type="email" :label="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :placeholder="form_field.is_required
                                                                ? form_field.name + ' *'
                                                                : form_field.name
                                                                " :rules="form_field.is_required
                                                                    ? [requiredValidator, emailValidator]
                                                                    : [emailValidator]
                                                                    " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'phone'" class="rounded pa-3" style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppTextField 
                                                        v-model="findFormFieldValue(form_field.id).value"
                                                        @input="(e) => onCustomPhoneInput(e, form_field.id)"
                                                        :label="form_field.is_required ? form_field.name + ' *' : form_field.name"
                                                        :placeholder="form_field.is_required ? form_field.name + ' *' : form_field.name" 
                                                        :rules="form_field.is_required ? [requiredValidator, phoneValidator] : [phoneValidator]"
                                                    />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'pick_list'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect @update:modelValue="customFieldPickListUpdated(form_field)" v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " />
                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>
                                                </div>
                                                <div v-else-if="form_field.type == 'multi_select'" class="rounded pa-3"
                                                    style="position: relative; background-color: rgba(
                                                    var(--v-theme-on-background),
                                                    var(--v-hover-opacity)
                                                ) !important;">
                                                    <AppSelect v-model="findFormFieldValue(form_field.id).value" :label="form_field.is_required
                                                        ? form_field.name + ' *'
                                                        : form_field.name
                                                        " :items="transformedOptions(form_field)" :placeholder="form_field.is_required
                                                            ? form_field.name + ' *'
                                                            : form_field.name
                                                            " :rules="form_field.is_required ? [requiredValidator] : []
                                                                " chips multiple closable-chips />

                                                    <div style="position: absolute; top: -10px; right: 10px;"
                                                        v-if="canManageCustomFields">

                                                        <VBtn @click.prevent="
                                                            editFieldSettings(form_field)
                                                            " class="mr-1" size="25" icon="tabler-settings" rounded
                                                            variant="tonal"></VBtn>

                                                        <VBtn color="error"
                                                            @click.prevent="removeFormField(form_field.id)"
                                                            v-if="deleteFieldButton(form_field)" size="25"
                                                            icon="tabler-minus" rounded variant="tonal">
                                                        </VBtn>

                                                    </div>

                                                </div>
                                            </VCol>


                                        </VRow>
                                    </div>
                                </VWindowItem>
                            </VWindow>

                            </VForm>

                    </VCardText>
                </VCard>
            </VCol>

            <VCol md="3" cols="12" v-if="canManageCustomFields">
                <div class="system_fields sticky">
                    <div class="d-flex flex-column justify-center mb-5">
                        <h2 class="oswald">
                            <strong>Custom Fields</strong>
                        </h2>
                        <div class="lato-light">Drag & drop fields</div>
                    </div>

                    <div v-for="(
                        system_form_field_type, index
                        ) of system_form_field_types" :key="system_form_field_type.type">
                        <div class="form_field_edit_organizations bg-primary rounded mb-1 px-2 py-2" :type="system_form_field_type.type" style="-ms-touch-action: none;touch-action: none;" >
                            <div>
                                <VIcon size="20" :icon="resolveIconForFieldType(system_form_field_type)
                                    " class="mr-3" />
                                {{ system_form_field_type.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </VCol>
        </VRow>
    </div>

    <VDialog v-model="customFieldDialog" max-width="600">
        <DialogCloseBtn @click="
            customFieldDialog = !customFieldDialog
            " />
        <VCard>
            <VCardText>
                <VTabs v-model="customFieldDialogTab">
                    <VTab>General Settings</VTab>
                    <VTab
                        v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'">
                        Field
                        Options</VTab>
                    <VTab>Layer Settings</VTab>
                </VTabs>
                <VWindow v-model="customFieldDialogTab">
                    <VWindowItem :key="1" :value="`item-${1}`">
                        <div class="py-5">
                            <AppTextField v-model="customFormFieldInstance.name" label="Field Name"
                                placeholder="Field Name" />
                            <VSwitch class="mt-3" v-model="customFormFieldInstance.is_required" label="Is required" />
                        </div>
                    </VWindowItem>

                    <VWindowItem class="py-5"
                        v-if="customFormFieldInstance.type == 'pick_list' || customFormFieldInstance.type == 'multi_select'"
                        :key="2" :value="`item-${2}`">
                        <div class="border rounded pa-2 mt-3 mb-5">
                            <VSwitch
                                @change="fieldLookupSettingChanged"
                                v-model="customFormFieldInstance.is_lookup_field"
                                label="Is Lookup Field"
                            />
                            <AppSelect
                                class="mt-2"
                                v-if="customFormFieldInstance.is_lookup_field"
                                v-model="customFormFieldInstance.table"
                                :items="modules"
                                placeholder="Select Module"
                                label="Module *"
                                density="compact"
                            />
                            <AppSelect
                                class="mt-2"
                                v-if="customFormFieldInstance.is_lookup_field"
                                v-model="customFormFieldInstance.column"
                                :items="lookupFieldAttributes"
                                placeholder="Select Attribute"
                                label="Attribute *"
                                density="compact"
                            />
                        </div>
                        <div style="width: 100%; text-align: left" v-if="!customFormFieldInstance.is_lookup_field">
                            <AppTextField v-model="currentFieldOptionValue" label="Option Name"
                                placeholder="Option Name">
                                <template #append>
                                    <VBtn @click.prevent="addFormFieldOption" class="mr-1" size="40" icon="tabler-plus"
                                        rounded></VBtn>
                                </template>
                            </AppTextField>
                            <div class="mt-5 text-sm" v-if="customFormFieldInstance.options.length > 0">
                                Current Options:
                            </div>
                            <VList style="padding: 0px !important" class="mt-1"
                                v-if="customFormFieldInstance.options.length > 0">
                                <template v-for="(form_field_option, index) of customFormFieldInstance.options"
                                    :key="index">
                                    <VListItem class="px-1 ma-1 my-2 rounded" border>
                                        <VListItemTitle class="pl-3">
                                            {{
                                                form_field_option.id
                                                    ? form_field_option.name
                                                    : form_field_option
                                            }}
                                        </VListItemTitle>
                                        <template #append>
                                            <VBtn color="error" @click.prevent="removeFormFieldOption(index)" size="30"
                                                icon="tabler-minus" rounded variant="tonal"></VBtn>
                                        </template>
                                    </VListItem>
                                </template>
                            </VList>
                        </div>
                    </VWindowItem>
                    <VWindowItem :key="2" :value="`item-${2}`">

                        <div class="">

                            <div class="mb-2 mt-5 border rounded py-3 px-5">
                                <label for="">Which organizations this field is for?</label>
                                <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.organizations">
                                    <VRadio label="All" value="all" />
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.organizations == 'custom'"
                                    @keyup="searchOrganizationsForField"
                                    v-model="customFormFieldInstance.organizations"
                                    :items="organizationsForField"
                                    label="Select Organizations"
                                    placeholder="Type to search.."
                                    :return-object="false"
                                    clearable multiple
                                    chips closable-chips
                                    clear-icon="tabler-x"
                                />
                            </div>

                            <div class="mb-2 mt-5 border rounded py-3 px-5">
                                <label for="">Which branch users this field is for?</label>
                                <VRadioGroup inline v-model="customFormFieldInstance.meta.layer_option.branches">
                                    <VRadio label="None" value="none" />
                                    <VRadio label="Custom" value="custom" />
                                </VRadioGroup>

                                <AppAutocomplete
                                    v-if="customFormFieldInstance.meta.layer_option.branches == 'custom'"
                                    @keyup="searchBranchesForField"
                                    v-model="customFormFieldInstance.branches"
                                    :items="branchesForField"
                                    label="Select Branch"
                                    placeholder="Type to search.."
                                    :return-object="false"
                                    clearable 
                                    clear-icon="tabler-x"
                                />

                            </div>

                        </div>
                        
                    </VWindowItem>

                </VWindow>


                <div class="d-flex flex-wrap justify-center gap-4 mt-5">
                    <VBtn type="button" block @click="saveFormFieldChanges">
                        Save Changes
                    </VBtn>
                </div>

            </VCardText>
        </VCard>
    </VDialog>


    <DeleteConfirm v-model:deleteDialog="deleteFormFieldDialog" @closeDelete="deleteFormFieldDialog = false"
        @deleteItemConfirm="removeFormFieldSubmit" />


</template>

<style scoped>
.form_field_types_div {
    sup {
        inset-block-start: 9px;
    }
}

.form_field_types_div_bg {
    position: relative;
    background-color: rgba(var(--v-theme-on-surface), var(--v-hover-opacity));
}

.drop-area-bg {
    background-color: rgba(var(--v-theme-on-background),
            var(--v-hover-opacity)) !important;
}

.sticky {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 5rem;
    /* Adjust this value as needed */
    z-index: 1;
    /* Ensure it is above other content if needed */

}

.draggable-col-organizations-edit {
    cursor: grab;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.draggable-col-organizations-edit:active {
    cursor: grabbing;
}

.draggable-col-organizations-edit * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.drop-area-bg {
    border: 2px dotted #ccc !important;
    background-color: rgba(var(--v-theme-on-background), var(--v-hover-opacity)) !important;
}
</style>