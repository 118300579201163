<script setup>
import { ref } from "vue";
import axios from "@/utils/axios";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import HelpdeskForm from "@/components/helpdesk/forms/HelpdeskForm.vue";

const $loading = useLoading(loaderData);

const user = ref({});
const helpdesk_forms = ref([]);
const current_helpdesk_form = ref({});
const HelpdeskFormModal = ref(false)

const getUser = async () => {
  let loader = $loading.show();
  axios
    .get("/auth/user")
    .then((response) => {
      if (response.status == "success") {
        console.log(response.data.user);
        user.value = response.data.user;
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

const formSubmitted = (payload) => {
  console.log("payload form submitted", payload);
}

const openHelpdeskForm = (form) => {
  current_helpdesk_form.value = form;
  HelpdeskFormModal.value = true;
}

const getHelpdeskForms = async (payload = null) => {
  let url = `/helpdesk/forms-list`;
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        helpdesk_forms.value = response.data.forms;
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// load contacts when page loads
onMounted(async () => {
  getUser();
  getHelpdeskForms();
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <div>
    <div class="d-flex flex-column justify-center mb-6">
          <h2>
              <strong class="oswald">Welcome to the Helpdesk</strong>
          </h2>
          <span class="lato-light">Submit a form from the list below to create a ticket!</span>
      </div>
      <VRow >
          <VCol
            cols="12"
            md="3"
            v-for="(helpdesk_form, index) of helpdesk_forms"
            :key="helpdesk_form.id"
            class=""
          >
            <VCard border class="card-form col-fixed" @click.prevent="openHelpdeskForm(helpdesk_form)">
              <VCardText class="d-flex justify-space-between align-center">
                <div class="">
                  <div class="mb-auto">
                    <h5 class="text-h6 ">
                      {{ helpdesk_form.name }}
                    </h5>
                    <span class="text-sm">
                      {{
                        helpdesk_form.description
                          ? helpdesk_form.description
                              .split(" ")
                              .slice(0, 4)
                              .join(" ") +
                            (helpdesk_form.description.split(" ").length > 5
                              ? " ..."
                              : "")
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="">
                  <VBtn
                    class=""
                    size="35"
                    disabled=""
                    icon="tabler-arrow-right"
                    rounded
                    variant="tonal"
                  ></VBtn>
                </div>
              </VCardText>
            </VCard>
          </VCol>
        </VRow>

    <HelpdeskForm
      v-model:isDialogVisible="HelpdeskFormModal"
      :form-data="current_helpdesk_form"
      :persistent='true'
      @formSubmitted="formSubmitted"
    />

  </div>
</template>

<style scoped>
.card-form:hover {
  background-color: #71758d17;
  cursor: pointer;
}

.col-fixed{
  height: 100px;
}
</style>
