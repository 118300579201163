<script setup>
import Account from "@/components/account-settings/Account.vue";
import License from "@/components/account-settings/License.vue";
import BusinessDetails from "@/components/account-settings/BusinessDetails.vue";
import { onMounted, ref, toRaw } from "vue"

const route = useRoute("account-settings-tab");

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
});
const isRoleRep = ref(false)
// tabs
const tabs = ref([
  {
    title: "Account",
    icon: "tabler-user-edit",
    tab: "account",
  },
]);
onMounted(async () => {
  const userData = useCookie("userData").value
  if(userData.role == 'representative_manager' || userData.role == 'representative'){
    isRoleRep.value = true;
    tabs.value.push(
    {
      title: "Licenses and Registration",
      icon: "tabler-id",
      tab: "licenses",
    });
    tabs.value.push(
    {
      title: "Business Details",
      icon: "tabler-align-box-left-stretch",
      tab: "business_details",
    });
  }
});


definePage({ meta: { navActiveLink: "account-settings-tab" } });
</script>

<template>
  <div>
    <VTabs v-model="activeTab" class="v-tabs-pill">
      <VTab
        v-for="item in tabs"
        :key="item.icon"
        :value="item.tab"
        :to="{ name: 'account-settings-tab', params: { tab: item.tab } }"
      >
        <VIcon size="20" start :icon="item.icon" />
        {{ item.title }}
      </VTab>
    </VTabs>

    <VWindow
      v-model="activeTab"
      class="mt-6 disable-tab-transition"
      :touch="false"
    >
      
      <VWindowItem value="account">
        <Account />
      </VWindowItem>

      <VWindowItem value="licenses">
        <License />
      </VWindowItem>
      
      <VWindowItem value="business_details">
        <BusinessDetails />
      </VWindowItem>
      
    </VWindow>
  </div>
</template>
