<script setup>
import { onMounted, ref, watch, nextTick } from "vue"
import axios from "@/utils/axios"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { useLoading } from "vue-loading-overlay"
import loaderData from "@/utils/loader-data"
import { useRouter } from "vue-router"

const router = useRouter()
const $loading = useLoading(loaderData)
const route = useRoute("user-management-view-id")
const currentTab = ref(0)
const contacts = ref([])

const itemsPerPage = ref(10)
const currentPage = ref(1)

const userCanSeeSSN = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin'
const userCanSeeDOB = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin' || useCookie("userData").value.role == 'representative_manager'
const userCanSeeAddress = useCookie("userData").value.role == 'admin' || useCookie("userData").value.role == 'super_admin'

const paginatedContacts = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value
  const end = start + itemsPerPage.value
  return contacts.value.slice(start, end)
})

const totalContacts = computed(() => contacts.value.length)


const userData = ref({
  first_name: "",
  last_name: "",
  role: "",
  email: "",
  password: "",
  phone: "",
  title: "",
  branch_id: "",
  status: "",
  internal_rep_code: "",
  values: [],
})

const getUserData = function () {
  let id = route.params.id
  let loader = $loading.show()
  let url = `/users/${id}?show=all`
  axios
    .get(url)
    .then(response => {
      if (response.status == "success") {
        userData.value = response.data.user
        contacts.value = response.data.user.contacts
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {
      loader.hide()
    })
    .finally(() => {
      loader.hide()
    })
}

onMounted(async () => {
  getUserData()
})

const discardChanges = () => {
  router.push({ name: "user-management-list" })
}

const editUser = () => {
  router.push({ name: "user-management-id", params: { id: userData.value.id } })
}

const resolveIconForFieldType = system_form_field => {
  if (system_form_field.type == "single_line") {
    return "tabler-cursor-text"
  } else if (system_form_field.type == "multi_line") {
    return "tabler-forms"
  } else if (system_form_field.type == "integer") {
    return "tabler-123"
  } else if (system_form_field.type == "percent") {
    return "tabler-percentage"
  } else if (system_form_field.type == "decimal") {
    return "tabler-decimal"
  } else if (system_form_field.type == "currency") {
    return "tabler-cash"
  } else if (system_form_field.type == "date") {
    return "tabler-calendar-month"
  } else if (system_form_field.type == "date_time") {
    return "tabler-calendar-clock"
  } else if (system_form_field.type == "email") {
    return "tabler-mail"
  } else if (system_form_field.type == "phone") {
    return "tabler-phone"
  } else if (system_form_field.type == "pick_list") {
    return "tabler-checkup-list"
  } else if (system_form_field.type == "multi_select") {
    return "tabler-list-check"
  } else {
    return ""
  }
}

definePage({ meta: { requiresAuth: true } })
</script>

<template>
  <div>
    <div>
      <!-- 👉 Header  -->
      <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
        <div>
          <div class="d-flex gap-2 align-center mb-2 flex-wrap">
            <h2>
                <strong class="oswald">User Details</strong>
            </h2>
          </div>
          <div>
            <span class="lato-light">
              {{ new Date(userData.created_at).toLocaleString('en-US', {
                dateStyle: 'long', timeStyle:
                  'short'
              }) }}
            </span>
          </div>
        </div>
        <div class="d-flex gap-4">
          <VBtn
            variant="tonal"
            color="dark"
            @click="discardChanges"
          >
            Back
          </VBtn>

          <VBtn
            variant="tonal"
            color="primary"
            @click="editUser"
          >
            Edit User
          </VBtn>
        </div>
      </div>
      <!-- 👉 Customer Profile  -->
      <VRow>
        <VCol
          cols="12"
          md="5"
          lg="4"
        >
          <VRow>
            <!-- SECTION Customer Details -->
            <VCol cols="12">
              <VCard>
                <VCardText class="text-center pt-15">
                  <!-- 👉 Avatar -->
                  <VAvatar
                    rounded
                    :size="100"
                    color="primary"
                    variant="tonal"
                  >
                    <VImg
                      v-if="userData.user_profile"
                      :src="userData.user_profile"
                    />
                    <span
                      v-else
                      class="text-5xl font-weight-medium"
                    >
                      {{ userData.first_name }}
                    </span>
                  </VAvatar>

                  <!-- 👉 Customer fullName -->
                  <h2 class="mt-4">
                    <strong class="oswald">{{ userData.first_name + " " + userData.last_name }}</strong>
                  </h2>
                  <span class="text-sm lato-light">{{ userData.email }}</span>
                  
                </VCardText>
              </VCard>
            </VCol>
            <!-- !SECTION -->
          </VRow>
        </VCol>
        <VCol
          cols="12"
          md="7"
          lg="8"
        >
          <VTabs
            v-model="currentTab"
            class="v-tabs-pill mb-3 disable-tab-transition"
          >
            <VTab :key="0">
              <span>General</span>
            </VTab>
            <VTab :key="1">
              <span>Additional</span>
            </VTab>
            <VTab
              v-if="userData.role == 'representative_manager' || userData.role == 'representative' "
              :key="2"
            >
              <span>Rep Codes</span>
            </VTab>
            <VTab
              :key="3"
            >
              <span>Contacts</span>
            </VTab>
          </VTabs>
          <VWindow
            v-model="currentTab"
            class="disable-tab-transition"
            :touch="false"
          >
            <VWindowItem>
              <VCard class="pa-5">
                <VRow class="parent">
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        First Name
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.first_name }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Last Name
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.last_name }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Title
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.title }}</span>
                      </div>
                    </VCardText>
                  </VCol>


                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Role
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.role_name }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Email
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.email }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Phone
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.phone }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Status
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData.status == '1' ? 'Active' : 'Suspend' }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Branch
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.branch?.branch_name }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    v-if="userData.role == 'representative_manager' || userData.role == 'representative'"
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Internal Rep Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.internal_rep_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    v-if="userData.role == 'representative_manager' || userData.role == 'representative'"
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Is Assistant
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.is_assistant ? 'Yes' : 'No' }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    v-if="userCanSeeSSN"
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Social Security Number
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.social_security_number }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    v-if="userCanSeeDOB"
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Date Of Birth
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.date_of_birth }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    v-if="userCanSeeAddress"
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Address
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ userData?.address }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem>
              <VCard class="pa-5">
                <VRow class="parent">
                  <VCol
                    v-for="ff in userData.custom_fields"
                    :key="ff.form_field.id"
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        {{ ff?.form_field?.name }}
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ Array.isArray(ff.value) ? ff.value.join(', ') : ff.value }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem v-if="userData.role == 'representative_manager' || userData.role == 'representative' ">
              <VCard
                v-for="rep_code in userData.rep_codes"
                :key="rep_code.id"
                class="pa-5 mb-2"
              >
                <VRow class="parent">
                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Rep Code Title
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.title }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Rep Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.rep_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>


                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Branch Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.branch_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem>
              <v-table class="rounded">
                <thead>
                  <tr>
                    <th class="text-left">User</th>
                    <th class="text-left">Phone</th>
                    <th class="text-left">Organization</th>
                    <th class="text-left">Branch</th>
                    <th class="text-left">Household</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="contact in paginatedContacts" :key="contact.id">
                    <td>{{ contact.first_name + ' ' + contact.last_name }}</td>
                    <td>{{ contact.phone }}</td>
                    <td>{{ contact?.organization?.name }}</td>
                    <td>{{ contact?.branch?.branch_name }}</td>
                    <td>{{ contact?.household?.name }}</td>
                  </tr>
                </tbody>
              </v-table>
              <v-pagination
                class="mt-5"
                v-model="currentPage"
                :length="Math.ceil(totalContacts / itemsPerPage)"
                total-visible="7"
                @input="currentPage = $event"
              ></v-pagination>
            </VWindowItem>
            
          </VWindow>
        </VCol>
      </VRow>
    </div>
  </div>
</template>
