<script setup>
// eslint-disable-next-line case-police/string-check
import Ftp from "@/components/system-settings/Ftp.vue"
import { onMounted, ref, toRaw } from "vue"

const route = useRoute("system-settings-tab")

const activeTab = computed({
  get: () => route.params.tab,
  set: () => route.params.tab,
})

// tabs
const tabs = ref([
  {
    title: "FTP Account",
    icon: "tabler-cloud-data-connection",
    tab: "ftp",
  },
])

definePage({ meta: { navActiveLink: "ftp" } })
</script>

<template>
  <div>
    <VTabs
      v-model="activeTab"
      class="v-tabs-pill"
    >
      <VTab
        v-for="item in tabs"
        :key="item.icon"
        :value="item.tab"
        :to="{ name: 'system-settings-tab', params: { tab: item.tab } }"
      >
        <VIcon
          size="20"
          start
          :icon="item.icon"
        />
        {{ item.title }}
      </VTab>
    </VTabs>

    <VWindow
      v-model="activeTab"
      class="mt-6 disable-tab-transition"
      :touch="false"
    >
      <VWindowItem value="ftp">
        <Ftp />
      </VWindowItem>
    </VWindow>
  </div>
</template>
