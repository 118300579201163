<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";

const router = useRouter();
const $loading = useLoading(loaderData);
const route = useRoute("contacts-view-id");
const currentTab = ref(0);

const itemsPerPageBranchReps = ref(10)
const currentPageBranchReps = ref(1)

const paginatedBranchReps = computed(() => {
  const start = (currentPageBranchReps.value - 1) * itemsPerPageBranchReps.value
  const end = start + itemsPerPageBranchReps.value
  return branchReps.value.slice(start, end)
})

const totalBranchReps = computed(() => branchReps.value.length)


const branchData = ref({
  branch_name: "",
  address_line_1: "",
  address_line_2: "",
  country: "",
  state: "",
  zip_code: "",
  phone: "",
  email: "",
  values: [],
});

let branchReps = ref([]);

const getBranchRepsData = function () {
  let id = route.params.id;
  let loader = $loading.show();
  let url = `/branches/${id}/reps`;
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        branchReps.value = response.data.reps;
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

const getBranchData = function () {
  let id = route.params.id;
  let loader = $loading.show();
  let url = `/branches/${id}?show=all`;
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        branchData.value = response.data.branch;
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

onMounted(async () => {
  getBranchData();
  getBranchRepsData();
});

const discardChanges = () => {
  router.push({ name: "branches-list" });
};

const editBranch = () => {
  router.push({ name: "branches-id", params: { id: branchData.id } });
};

const resolveIconForFieldType = (system_form_field) => {
  if (system_form_field.type == "single_line") {
    return "tabler-cursor-text";
  } else if (system_form_field.type == "multi_line") {
    return "tabler-forms";
  } else if (system_form_field.type == "integer") {
    return "tabler-123";
  } else if (system_form_field.type == "percent") {
    return "tabler-percentage";
  } else if (system_form_field.type == "decimal") {
    return "tabler-decimal";
  } else if (system_form_field.type == "currency") {
    return "tabler-cash";
  } else if (system_form_field.type == "date") {
    return "tabler-calendar-month";
  } else if (system_form_field.type == "date_time") {
    return "tabler-calendar-clock";
  } else if (system_form_field.type == "email") {
    return "tabler-mail";
  } else if (system_form_field.type == "phone") {
    return "tabler-phone";
  } else if (system_form_field.type == "pick_list") {
    return "tabler-checkup-list";
  } else if (system_form_field.type == "multi_select") {
    return "tabler-list-check";
  } else {
    return "";
  }
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <div>
    <div>
      <!-- 👉 Header  -->
      <div
        class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6"
      >
        <div>
          <div class="d-flex gap-2 align-center mb-2 flex-wrap">
            <h2>
                <strong class="oswald">Branch Details</strong>
            </h2>
          </div>
          <div>
            <span class="lato-light">
              {{
                new Date(branchData.created_at).toLocaleString("en-US", {
                  dateStyle: "long",
                  timeStyle: "short",
                })
              }}
            </span>
          </div>
        </div>
        <div class="d-flex gap-4">
          <VBtn variant="tonal" color="dark" @click="discardChanges">
            Back
          </VBtn>

          <VBtn variant="tonal" color="primary" @click="editBranch()">
            Edit Branch
          </VBtn>
        </div>
      </div>
      <!-- 👉 Customer Profile  -->
      <VRow>
        <VCol cols="12" md="5" lg="4">
          <VRow>
            <!-- SECTION Customer Details -->
            <VCol cols="12">
              <VCard>
                <VCardText class="text-center pt-15">
                  <!-- 👉 Avatar -->
                  <VAvatar
                    rounded
                    :size="100"
                    :color="'primary'"
                    :variant="'tonal'"
                  >
                    <!-- <VImg v-if="props.customerData.avatar" :src="props.customerData.avatar" /> -->
                    <span class="text-5xl font-weight-medium">
                      {{ branchData.branch_name }}
                      <!-- {{ avatarText(branchData.name) }} -->
                    </span>
                  </VAvatar>

                  <h2 class="mt-4">
                    <strong class="oswald">{{ branchData.branch_name }}</strong>
                  </h2>
                  <span class="text-sm lato-light">{{ branchData.email }}</span>


                </VCardText>
              </VCard>
            </VCol>
            <!-- !SECTION -->
          </VRow>
        </VCol>
        <VCol cols="12" md="7" lg="8">
          <VTabs
            class="v-tabs-pill mb-3 disable-tab-transition"
            v-model="currentTab"
          >
            <VTab :key="0">
              <span>General</span>
            </VTab>
            <VTab :key="1">
              <span>Additional</span>
            </VTab>
            <VTab :key="2">
              <span>Representatives</span>
            </VTab>
          </VTabs>
          <VWindow
            class="disable-tab-transition"
            :touch="false"
            v-model="currentTab"
          >
            <VWindowItem>
              <VCard class="pa-5">
                <VRow class="parent">
                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Branch Name</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.branch_name
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Email</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.email
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Phone</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.phone
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Address Line 1</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.address_line_1
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Address Line 2</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.address_line_2
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Country</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData?.country_object?.name
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">State</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData?.state_object?.name
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Zip Code</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.zip_code
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Internal Branch Code</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData.internal_branch_code
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Branch CRD#</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData?.branch_crd_number
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Branch Website</h3>
                      <div>
                        <span class="text-primary lato-light">{{
                          branchData?.branch_website
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Branch Manager</h3>
                      <div>
                        <span class="text-primary lato-light">
                          <div>{{ branchData?.branch_manager?.first_name }} {{ branchData?.branch_manager?.last_name }} </div>
                          <small>{{ branchData?.branch_manager?.email }}</small>
                        </span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol class="child" cols="12" md="4" v-if="branchData?.logo_file">
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">Branch Logo File</h3>
                      <div>
                        <span class="text-primary lato-light">
                          <div>
                            <img style="max-height: 100px;" v-if="branchData.logo_file" :src="branchData.logo_file" class="rounded shadow ma-2" />
                          </div>
                          <a :href="branchData?.logo_file" download><i class="tabler tabler-download"></i> Download</a>
                        </span>
                      </div>
                    </VCardText>
                  </VCol>

                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem>
              <VCard class="pa-5">
                <VRow class="parent">
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                    v-for="ff in branchData.custom_fields"
                    :key="ff.form_field.id"
                  >
                    <VCardText
                      class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1"
                    >
                      <h3 class="oswald">
                        {{ ff?.form_field?.name }}
                      </h3>
                      <div>
                        <!-- {{ branchData }} -->
                        <span class="text-primary lato-light">{{
                          Array.isArray(ff.value)
                            ? ff.value.join(", ")
                            : ff.value
                        }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem>
      <v-table class="rounded">
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Title</th>
            <th class="text-left">Role</th>
            <th class="text-left">Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in paginatedBranchReps" :key="user.id">
            <td>
              <div class="d-flex align-center">
                <VAvatar
                  size="34"
                  :variant="!user.user_profile ? 'tonal' : undefined"
                  :color="!user.user_profile
                    ? resolveUserRoleVariant(user.role).color
                    : undefined"
                  class="me-3"
                >
                  <VImg v-if="user.user_profile" :src="user.user_profile" />
                  <span v-else>{{ avatarText(user.first_name) }}</span>
                </VAvatar>
                <div class="d-flex flex-column">
                  <h4 class="oswald">
                    <RouterLink
                      :to="{
                        name: 'user-management-view-id',
                        params: { id: user.id },
                      }"
                      class="font-weight-medium text-link"
                    >
                      {{ user.first_name }} {{ user.last_name }}
                    </RouterLink>
                  </h4>
                  <span class="text-sm text-medium-emphasis lato-light">{{ user.email }}</span>
                </div>
              </div>
            </td>
            <td>{{ user.title }}</td>
            <td>
              <div class="d-flex align-center gap-4">
                <span class="text-capitalize">{{ user.role_name }}</span>
              </div>
            </td>
            <td>{{ user.phone }}</td>
          </tr>
        </tbody>
      </v-table>

      <!-- Pagination Controls -->
      <v-pagination
        class="mt-5"
        v-model="currentPageBranchReps"
        :length="Math.ceil(totalBranchReps / itemsPerPageBranchReps)"
        total-visible="7"
        @input="currentPageBranchReps = $event"
      ></v-pagination>
    </VWindowItem>
          </VWindow>
        </VCol>
      </VRow>
    </div>
  </div>
</template>
