<script setup>
import { onMounted, ref, onUnmounted } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";

const router = useRouter();
const $loading = useLoading(loaderData);

const isStepDialogVisible = ref(false)

const workflowData = ref({
    title: "",
    description: "",
    status: "",
});

onUnmounted(() => {
});


const route = useRoute("workflows-id");

const submitBtn = ref(1);

const getWorkflowData = function () {
    let id = route.params.id;
    let loader = $loading.show();
    let url = `/workflows/${id}`;
    axios
        .get(url)
        .then((response) => {
            if (response.status == "success") {
                workflowData.value = response.data.workflow;
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

const getWorkflowStepsData = () => {
    let loader = $loading.show();
    axios
        .get(`/workflow_steps?workflow_id=${route.params.id}`)
        .then((response) => {
            if (response.status == "success") {
                steps.value = response.data.workflow_steps  
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

onMounted(async () => {
    getWorkflowData();
    getWorkflowStepsData();
    getSystemFormFieldTypes();
    if(route.query.addSteps){
        nextTick(() => {
            isStepDialogVisible.value = true;
        });
    }
});
const statusOptions = [
    {
        title: "Draft",
        value: "draft",
    },
    {
        title: "Approved",
        value: "approved",
    },
    {
        title: "Suspended",
        value: "suspended",
    },  
];

const typeOptions = [
    {
        title: "Client",
        value: "client",
    },
    {
        title: "Operations",
        value: "operations",
    },  
    {
        title: "Rep",
        value: "rep",
    },    
    {
        title: "Branch Office",
        value: "branch_office",
    },      
];

const updateWorkflow = async () => {
    let loader = $loading.show();
    submitBtn.value = 0;
    axios
        .patch("/workflows/" + `${workflowData.value.id}`, workflowData.value)
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                router.push({ name: "workflows-list" });
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
            submitBtn.value = 1;
        });
};


const steps = ref([])
const currentStep = ref({
    title: '',
    description: '',
    parent_id: null,
    optional: false,
    forms: []
})

// make sure workflow is created before adding steps
const newStep = () => {
    mode.value = 'add'
    currentStep.value = {
        title: '',
        description: '',
        parent_id: null,
        optional: false,
        forms: []
    }
    isStepDialogVisible.value = true;
}

const systemFormFieldTypes = ref([])
const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        systemFormFieldTypes.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const mode = ref('add')
const updateSteps = (step) => {
    if(mode.value == 'edit'){
        steps.value[editStepIndex.value] = toRaw(step)

    }else{
        steps.value.push(toRaw(step))
    }
    isStepDialogVisible.value = false;
}
const editStepIndex = ref(0)
const editStep = (step, index) => {
    mode.value = 'edit'
    editStepIndex.value = index
    currentStep.value = step
    isStepDialogVisible.value = true;
}

const deleteStep = (step, index) => {
    let loader = $loading.show();
    axios
        .delete(`/workflow_steps/${step.id}`)
        .then((response) => {
            if(response.status == "success"){
                steps.value.splice(index, 1);
                toast.success(response.message, {
                    autoClose: 2000,
                });
            }else{
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

const discardChanges = () => {
    router.push({ name: "workflows-list" });
}

definePage({ meta: { requiresAuth: true } });
</script>

<template>
    <div>
        <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">

            <div class="d-flex flex-column justify-center">
                <h2>
                    <strong class="oswald">Edit Workflow</strong>
                </h2>
                <span class="lato-light">You can edit this workflow on this page</span>
            </div>


            <div class="d-flex gap-4 align-center flex-wrap">
                <VBtn @click.prevent="discardChanges" variant="tonal" color="dark">
                    Discard
                </VBtn>
                <VBtn @click.prevent="updateWorkflow">Update</VBtn>
            </div>
        </div>

        <VRow>
            <VCol cols="12" :md="12">
                <VCard class="mb-6" title="Workflow Information">
                    <VCardText>

                        <VForm class="mt-6" @submit.prevent="updateWorkflow()">
                            <VRow>
                                <VCol cols="12" md="6">
                                    <AppTextField v-model="workflowData.title" label="Title *"
                                        placeholder="Title" :rules="[requiredValidator]" />
                                </VCol>
                                <VCol cols="12" md="3">
                                    <AppSelect v-model="workflowData.status" :items="statusOptions" label="Status *"
                                        :rules="[requiredValidator]" />
                                </VCol>

                                <VCol cols="12" md="3">
                                    <AppSelect v-model="workflowData.type" :items="typeOptions" label="Type" clearable
                                        placeholder="Type" />
                                </VCol>

                                <VCol cols="12" md="12">
                                    <AppTextarea rows="3" v-model="workflowData.description" label="Description"
                                        placeholder="Description" />
                                </VCol>

                            </VRow>

                        </VForm>

                    </VCardText>
                </VCard>

                <div class="mt-6 mb-4 d-flex justify-space-between align-center">
                    <div class="d-flex flex-column justify-center">
                        <h2>
                            <strong class="oswald">Workflow Steps</strong>
                        </h2>
                        <span class="lato-light">You can add new workflow steps here</span>
                    </div>
                    <VBtn
                        icon
                        size="small"
                        color="primary" variant="tonal"
                        @click="newStep"
                    >
                        <VIcon
                            size="22"
                            icon="tabler-plus"
                        />
                    </VBtn>
                </div>

                <div class="step mt-5" v-for="(step, index) in steps" :key="index">
                    <VCard class="mb-3 pb-3 pt-3 px-3 ">

                        <div class="d-flex justify-end gap-1 step_action_buttons">
                            <VBtn
                                icon
                                size="x-small"
                                color="primary" variant="tonal"
                                @click="editStep(step, index)"
                            >
                                <VIcon size="20" icon="tabler-pencil" />
                            </VBtn>
                            <VBtn
                                icon
                                size="x-small"
                                color="primary" variant="tonal"
                                @click="deleteStep(step, index)"
                            >
                                <VIcon size="20" icon="tabler-trash" />
                            </VBtn>
                        </div>
                    
                        <VRow class="">
                            <VCol
                                cols="12"
                                md="3"
                            >
                                <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                    <h3 class="oswald">
                                        Step Name
                                    </h3>
                                    <div>
                                        <span class="text-primary lato-light">{{ step.title }}</span>
                                    </div>
                                </VCardText>
                            </VCol>
                            <VCol
                                cols="12"
                                md="9"
                            >
                                <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                    <h3 class="oswald">
                                        Step Description
                                    </h3>
                                    <div>
                                        <span class="text-primary lato-light">{{ step.description }}</span>
                                    </div>
                                </VCardText>
                            </VCol>
                        </VRow>

                        <div class="d-flex justify-space-between mt-5 mx-1 align-center">
                            <div >
                                <VChip
                                    v-if="step.optional"
                                    :label="false"
                                    color="primary"
                                >
                                    Optional
                                </VChip>
                            </div>
                            <div class="d-flex gap-1">

                                <VChip
                                    v-if="step.forms && step.forms.length > 0"
                                    :label="false"
                                    color="primary"
                                >
                                    <VIcon size="20" class="" icon="tabler-forms" />
                                    Forms
                                </VChip>

                                <VChip
                                    v-if="step.documents && step.documents.length > 0"
                                    :label="false"
                                    color="primary"
                                >
                                    <VIcon size="20" class="" icon="tabler-file-text" />
                                    Documents
                                </VChip>

                                <VChip
                                    v-if="step.approvers && step.approvers.length > 0"
                                    :label="false"
                                    color="primary"
                                >
                                    <VIcon size="20" class="" icon="tabler-checklist" />
                                    Approvers
                                </VChip>

                            </div>
                        </div>

                    </VCard>
                </div>

            </VCol>
        </VRow>

        <StepDialog
            v-model:isDialogVisible="isStepDialogVisible"
            :system-form-field-types="systemFormFieldTypes"
            :step="currentStep"
            :steps="steps"
            :mode="mode"
            :workflow_id="workflowData.id"
            @updateSteps="updateSteps"
        />

    </div>
</template>

<style scoped>
    .step{
    }
    .step_action_buttons{
        position: absolute;
        top:10px;
        right:20px;
    }
</style>