<script setup>
import { onMounted, ref, watch, nextTick, onUnmounted } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";
import StepDialog from "@/components/workflows/StepDialog.vue";
const router = useRouter();
const $loading = useLoading(loaderData);

const workflowData = ref({
    title: "",
    description: "",
    status: "draft",
    type: null,
});

const statusOptions = [
    {
        title: "Draft",
        value: "draft",
    },
    {
        title: "Approved",
        value: "approved",
    },
    {
        title: "Suspended",
        value: "suspended",
    },  
];

const typeOptions = [
    {
        title: "Client",
        value: "client",
    },
    {
        title: "Operations",
        value: "operations",
    },
    {
        title: "Rep",
        value: "rep",
    },
    {
        title: "Branch Office",
        value: "branch_office",
    },  
];

const submitBtn = ref(1);

onMounted(async () => {
});

const createWorkflow = async () => {
    let loader = $loading.show();
    submitBtn.value = 0;
    axios
        .post("/workflows", workflowData.value)
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                router.push({ name: "workflows-list"});
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
            submitBtn.value = 1;
        });
};

const addSteps = () => {
    let loader = $loading.show();
    submitBtn.value = 0;
    axios
        .post("/workflows", workflowData.value)
        .then((response) => {
            if (response.status == "success") {
                toast.success(response.message, {
                    autoClose: 5000,
                });
                router.push({ name: "workflows-id", params: { id: response.data.workflow.id }, query: { addSteps: true } });
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
            submitBtn.value = 1;
        });
}

const discardChanges = () => {
    router.push({ name: "workflows-list" });
}

definePage({ meta: { requiresAuth: true } });
</script>

<template>
    <div>
        <div class="d-flex flex-wrap justify-start justify-sm-space-between gap-y-4 gap-x-6 mb-6">
            
            <div class="d-flex flex-column justify-center">
                <h2>
                    <strong class="oswald">Add New Workflow</strong>
                </h2>
                <span class="lato-light">You can add new workflow on this page</span>
            </div>

            <div class="d-flex gap-4 align-center flex-wrap">
                <VBtn @click.prevent="discardChanges" variant="tonal" color="dark">
                    Discard
                </VBtn>
                <VBtn @click.prevent="createWorkflow">Create</VBtn>
            </div>
        </div>

        <VRow>
            <VCol cols="12" :md="12" >
                <VCard class="mb-6 form_fields_dropzone_create_workflow " title="Workflow Information">
                    <VCardText>

                        <VForm class="mt-6" @submit.prevent="createWorkflow()">
                            <VRow>
                                
                                <VCol cols="12" md="6">
                                    <AppTextField v-model="workflowData.title" label="Title *"
                                        placeholder="Title" :rules="[requiredValidator]" />
                                </VCol>

                                <VCol cols="12" md="3">
                                    <AppSelect v-model="workflowData.status" :items="statusOptions" label="Status *"
                                        placeholder="Status" :rules="[requiredValidator]" />
                                </VCol>

                                <VCol cols="12" md="3">
                                    <AppSelect v-model="workflowData.type" :items="typeOptions" label="Type" clearable
                                        placeholder="Type" />
                                </VCol>
                                
                                <VCol cols="12" md="12">
                                    <AppTextarea rows="3" v-model="workflowData.description" label="Description"
                                        placeholder="Description" />

                                </VCol>

                            </VRow>
                            
                        </VForm>

                        <!-- show a button in right corder to add steps and take to edit page after creating the workflow -->
                        <VRow class="justify-end mt-4">
                            <VBtn @click.prevent="addSteps" color="primary" variant="tonal">
                                Add Steps
                                <VIcon icon="tabler-arrow-right" />
                            </VBtn>
                        </VRow>

                    </VCardText>
                </VCard>

            </VCol>

        </VRow>

    </div>
</template>

<style scoped>
.step_action_buttons {
    position: absolute;
    right: 20px;
    top: -15px;
    z-index: 200;
}
.step{
    position: relative;
}
</style>