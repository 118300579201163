<script setup>
import { useGenerateImageVariant } from "@core/composable/useGenerateImageVariant";
import authV2ForgotPasswordIllustrationDark from "@images/pages/auth-v2-forgot-password-illustration-dark.png";
import authV2ForgotPasswordIllustrationLight from "@images/pages/auth-v2-forgot-password-illustration-light.png";
import authV2MaskDark from "@images/pages/misc-mask-dark.png";
import authV2MaskLight from "@images/pages/misc-mask-light.png";
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { themeConfig } from "@themeConfig";
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
const $loading = useLoading(loaderData);

const form = ref({
  invite_token: "",
  password: "",
  password_confirmation: "",
});
const router = useRouter();

const submitBtn = ref(1);
const success = ref("");
const error = ref("");

const authThemeImg = useGenerateImageVariant(
  authV2ForgotPasswordIllustrationLight,
  authV2ForgotPasswordIllustrationDark
);
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark);

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

const mounted = function () {
  axios
    .get("/auth/user")
    .then((response) => {
      if (response.status == "success") {
        router.push("/dashboard");
      }
    })
    .catch((error) => {
    })
    .finally(() => {
    });

  const route = useRoute();
  const invite_token = route.query.invite_token;
  form.value = {
    invite_token: invite_token,
    password: "",
    password_confirmation: "",
  };
};

onMounted(mounted);

const acceptInvite = async () => {
  submitBtn.value = 0;
  let loader = $loading.show();

  axios
    .post("/auth/accept_invite", form.value)
      .then((response) => {
      if (response.status == "success") {
        success.value = response.message;
      } else {
        submitBtn.value = 1;
        error.value = response.message;
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      submitBtn.value = 1;
      loader.hide();
    });
};
</script>

<template>
  <VRow class="auth-wrapper bg-surface" no-gutters>
    <VCol lg="8" class="d-none d-lg-flex">
      <div class="position-relative bg-background rounded-lg w-100 ma-8 me-0">
        <div class="d-flex align-center justify-center w-100 h-100">
          <VImg
            max-width="368"
            :src="authThemeImg"
            class="auth-illustration mt-16 mb-2"
          />
        </div>

        <VImg class="auth-footer-mask" :src="authThemeMask" />
      </div>
    </VCol>

    <VCol cols="12" lg="4" class="d-flex align-center justify-center">
      <VCard
        flat
        :max-width="500"
        class="mt-12 mt-sm-0 pa-4"
        style="width: 100%"
      >
        <VCardText>
          <VNodeRenderer :nodes="themeConfig.app.logo" class="mb-6" />
          <h4 class="text-h4 mb-1">Accept Invitation</h4>
          <p class="mb-0">Set your password to continue</p>
        </VCardText>

        <VCardText>
          <div v-if="success">
            <VAlert color="light-success" class="text-success">
              <span class="text-lg font-weight-medium"
                >Invitation Accepted!</span
              >
              <p class="mb-0">
                {{ success }}
              </p>
            </VAlert>
            <RouterLink
              class="d-flex align-center justify-center mt-5"
              :to="{ name: 'login' }"
            >
              <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
              <span>Back to login</span>
            </RouterLink>
          </div>

          <VForm @submit.prevent="acceptInvite" v-else>
            <VRow>
              <!-- email -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  autofocus
                  label="Password"
                  type="password"
                  :rules="[requiredValidator]"
                />
              </VCol>

              <VCol cols="12">
                <AppTextField
                  v-model="form.password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  :rules="[requiredValidator]"
                />
              </VCol>

              <VCol cols="12" v-if="error">
                <VAlert color="light-warning" class="text-warning">
                  <span class="text-lg font-weight-medium"
                    >Invalid Request</span
                  >
                  <p class="mb-0">
                    {{ error }}
                  </p>
                </VAlert>
              </VCol>

              <!-- Reset link -->
              <VCol cols="12">
                <VBtn block :disabled="submitBtn == 0" type="submit">
                  Submit
                </VBtn>
              </VCol>
            </VRow>
            <RouterLink
              v-if="error"
              class="d-flex align-center justify-center mt-5"
              :to="{ name: 'login' }"
            >
              <VIcon icon="tabler-chevron-left" class="flip-in-rtl" />
              <span>Back to login</span>
            </RouterLink>
          </VForm>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<style lang="scss">
@use "@core-scss/template/pages/page-auth.scss";
</style>
