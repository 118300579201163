<script setup>
import BranchForm from "@/components/branches/BranchForm.vue";
import BranchesDataTable from "@/components/branches/BranchesDataTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import BranchFilters from "@/components/branches/BranchFilters.vue";
import BranchCustomFieldsForm from "@/components/branches/BranchCustomFieldsForm.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const $loading = useLoading(loaderData);
const errors = ref([]);
const headers = ref([
  {
    title: "Branch Name",
    key: "branch_name",
  },
  {
    title: "Address Line 1",
    key: "address_line_1",
  },
  {
    title: "Address Line 2",
    key: "address_line_2",
  },
  {
    title: "Country",
    key: "country",
  },
  {
    title: "State",
    key: "state",
  },
  {
    title: "Zip Code",
    key: "zip_code",
    sortable: true,
  },
  {
    title: "Email",
    key: "email",
  },
  {
    title: "Phone",
    key: "phone",
    sortable: true,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);
const branches = ref([]);
const states = ref([]);
const countries = ref([]);
const openModal = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");
const editedItem = ref({});
const deleteDialog = ref(false);
const filterInfo = ref({});

const isCustomFieldsDialogVisible = ref(false)
const customFormFields = ref([])
const isCanSeeManageCustomFieldsBtn = ref(false);
const system_form_field_types = ref([]);
const viewItem = (item) => {
    router.push({ name: "branches-view-id", params: { id: item.id } });
};
const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/branches`)
    .then((response) => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch((error) => {})
    .finally(() => {});
}

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const customFieldsUpdated = async () => {
  getCustomFormFields();
}
const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};
// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Contact Custom Fields")
  );
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true;
  } else {
    isCanSeeManageCustomFieldsBtn.value = false;
  }
};

const getCountries = (search_query = "") => {
  axios
    .get(`/countries?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        countries.value = response.data.countries.map((country) => {
          return {
            title: country.name,
            value: country.id,
          };
        });
      }
    })
    .catch((error) => {})
    .finally(() => {
    });
};

const getStates = (country_id, search_query = "") => {
  axios
    .get(`get/states/${country_id}?search_query=${search_query}`)
    .then((response) => {
      if (response.status == "success") {
        states.value = response.data.states.map((state) => {
          return {
            title: state.name,
            value: state.id,
          };
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const canSeeCreateBtn = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Branches")
  );
};

const getBranches = async (payload = null) => {
  let url = `/branches?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  if (
    payload?.country != "" &&
    (payload?.country != undefined || payload?.country != null)
  ) {
    url += `&country=${payload.country}`;
  }
  if (
    payload?.state != "" &&
    (payload?.state != undefined || payload?.state != null)
  ) {
    url += `&state=${payload.state}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        branches.value = response.data.branches;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getBranches(filterInfo.value);
};

const countryChanged = async (payload) => {
  filterInfo.value.country = payload;
  getBranches(filterInfo.value);
  getStates(payload)
};

const stateChanged = async (payload) => {
  filterInfo.value.state = payload;
  getBranches(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getBranches(filterInfo.value);
};

const editItem = (item) => {
  router.push({ name: "branches-id", params: { id: item.id } });
};

const deleteItem = (item) => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/branches/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        getBranches(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// Page changed from pagination
const refreshBranches = async () => {
  getBranches(filterInfo.value);
};

// load branches when page loads
onMounted(async () => {
  getCountries();
  canSeeCreateBtn();

  getSystemFormFieldTypes();
  canSeeManageCustomFieldsButton();
  getCustomFormFields();

});

const openCreatePage = async () => {
  router.push({ name: "branches-create"});
  // modeModal.value = "create";
  // editedItem.value = {};
  // openModal.value = true;
};
// expose errors and getBranches
defineExpose({
  errors,
  getBranches,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">
      <!-- Branch Filters Section -->
      <BranchFilters
        :countries="countries"
        :states="states"
        :width="6"
        @countryChanged="countryChanged"
        @stateChanged="stateChanged"
      />
      <VDivider />

      <!-- Branch Table Section -->
      <BranchesDataTable
        :headers="headers"
        :table-data="branches"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :canSeeManageCustomFieldsBtn="isCanSeeManageCustomFieldsBtn"
        @isDialogVisible="openCreatePage"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @editItem="editItem"
        @viewItem="viewItem"
        @deleteItem="deleteItem"
        @openCustomFieldsModal="openCustomFieldsModal"
      />
    </VCardText>
  </VCard>

  <!-- Branch Create/Edit Modal -->
  <BranchForm
    v-model:isDialogVisible="openModal"
    :states="states"
    :branch-data="editedItem"
    :mode="modeModal"
    :custom_fields="customFormFields"
    @refreshBranches="refreshBranches"
  />
  <!-- Branch Delete Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />

  <BranchCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :system_form_field_types="system_form_field_types"
    @customFieldsUpdated="customFieldsUpdated"
  />

</template>
