<script setup>
import { ref } from "vue";
import axios from "@/utils/axios";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";


const $loading = useLoading(loaderData);
definePage({ meta: { requiresAuth: true } });
</script>

<template>
  
</template>

<style scoped>
.card-form:hover {
  background-color: #71758d17;
  cursor: pointer;
}

.col-fixed{
  height: 100px;
}
</style>
