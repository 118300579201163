<script setup>
import { onMounted, ref, watch, nextTick } from "vue"
import axios from "@/utils/axios"
import { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import { useLoading } from "vue-loading-overlay"
import loaderData from "@/utils/loader-data"
import { useRouter } from "vue-router"

const router = useRouter()
const $loading = useLoading(loaderData)
const route = useRoute("investment-companies-id")
const currentTab = ref(0)

const companyData = ref({
  title: "",
  description: "",
  notes: "",
  address: "",
  address_2: "",
  contact_name: "",
  contact_phone: "",
  contact_email: "",
  general_phone: "",
  general_email: "",
  created_at: "",
  updated_at: "",
  branches: [],
  branch_codes: [],
  rep_codes: [],
})

const getCompanyData = function () {
  let id = route.params.id
  let loader = $loading.show()
  let url = `/investment_companies/${id}?show=all`
  axios
    .get(url)
    .then(response => {
      if (response.status == "success") {
        companyData.value = response.data.investment_company
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        })
      }
    })
    .catch(error => {
      loader.hide()
    })
    .finally(() => {
      loader.hide()
    })
}

onMounted(async () => {
  getCompanyData()
})

const discardChanges = () => {
  router.push({ name: "investment-companies-list" })
}

const editInvestmentCompany = () => {
  router.push({ name: "investment-companies-id", params: { id: companyData.value.id } })
}

definePage({ meta: { requiresAuth: true } })
</script>

<template>
  <div>
    <div>
      <!-- 👉 Header  -->
      <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
        <div>
          <div class="d-flex gap-2 align-center mb-2 flex-wrap">
            <h2>
                <strong class="oswald">Investment Company Details</strong>
            </h2>
          </div>

          <div>
            <span class="lato-light">
              {{ new Date(companyData.created_at).toLocaleString('en-US', {
                dateStyle: 'long', timeStyle:
                  'short'
              }) }}
            </span>
          </div>
        </div>
        <div class="d-flex gap-4">
          <VBtn
            variant="tonal"
            color="dark"
            @click="discardChanges"
          >
            Back
          </VBtn>

          <VBtn
            variant="tonal"
            color="primary"
            @click="editInvestmentCompany"
          >
            Edit Investment Company
          </VBtn>
        </div>
      </div>
      <!-- 👉 Customer Profile  -->
      <VRow>
        <VCol
          cols="12"
          md="5"
          lg="4"
        >
          <VRow>
            <!-- SECTION Customer Details -->
            <VCol cols="12">
              <VCard>
                <VCardText class="text-center pt-15">
                  <!-- 👉 Avatar -->
                  <VAvatar
                    rounded
                    :size="100"
                    color="primary"
                    variant="tonal"
                  >
                    <VImg
                      v-if="companyData.user_profile"
                      :src="companyData.user_profile"
                    />
                    <span
                      v-else
                      class="text-5xl font-weight-medium"
                    >
                      {{ companyData.title }}
                    </span>
                  </VAvatar>

                  <h2 class="mt-4">
                    <strong class="oswald">{{ companyData.title }}</strong>
                  </h2>
                  <span class="text-sm lato-light">{{ companyData.general_email }}</span>

                </VCardText>
              </VCard>
            </VCol>
            <!-- !SECTION -->
          </VRow>
        </VCol>
        <VCol
          cols="12"
          md="7"
          lg="8"
        >
          <VTabs
            v-model="currentTab"
            class="v-tabs-pill mb-3 disable-tab-transition"
          >
            <VTab :key="0">
              <span>General</span>
            </VTab>
            <VTab :key="1">
              <span>Branch Codes</span>
            </VTab>
            <VTab :key="2">
              <span>Rep Codes</span>
            </VTab>
          </VTabs>
          <VWindow
            v-model="currentTab"
            class="disable-tab-transition"
            :touch="false"
          >
            <VWindowItem>
              <VCard class="pa-5">
                <VRow class="parent">
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Title
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.title }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="8"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Description
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.description }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="6"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Address Line 1
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.address }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="6"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Address Line 2
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.address_2 }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Contact Name
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.contact_name }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Contact Phone
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.contact_phone }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Contact Email
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.contact_email }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        General Phone
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.general_phone }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        General Email
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.general_email }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                  <VCol
                    class="child"
                    cols="12"
                    md="12"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Notes
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ companyData.notes }}</span>
                      </div>
                    </VCardText>
                  </VCol>
                </VRow>
              </VCard>
            </VWindowItem>

            <VWindowItem>
              <VCard class="pa-5 mb-2" v-for="branch_code in companyData.branch_codes"
                    :key="branch_code.id" >
                <VRow class="parent">
                  <VCol
                    class="child" 
                    cols="12"
                    md="6"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Title
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ branch_code.title }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child" 
                    cols="12"
                    md="6"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Branch Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ branch_code.branch_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child" 
                    cols="12"
                    md="12"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Description
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ branch_code.description }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    class="child" 
                    cols="12"
                    md="12"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Branches
                      </h3>
                      <div>
                        <VChip
                          v-for="(item, index) in branch_code.branches"
                          :key="index"
                          :label="false"
                          color="primary"
                          class="ma-1"
                        >
                          {{ item }}
                        </VChip>
                      </div>
                    </VCardText>
                  </VCol>

                  

                </VRow>
              </VCard>
            </VWindowItem>


            <VWindowItem>
              <VCard
                v-for="rep_code in companyData.rep_codes"
                :key="rep_code.id"
                class="pa-5 mb-2"
              >
                <VRow class="parent">
                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Rep Code Title
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.title }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Rep Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.rep_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>


                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="4"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Branch Code
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.branch_code }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="12"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Description
                      </h3>
                      <div>
                        <span class="text-primary lato-light">{{ rep_code.description }}</span>
                      </div>
                    </VCardText>
                  </VCol>

                  <VCol
                    
                    class="child" 
                    cols="12"
                    md="8"
                  >
                    <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                      <h3 class="oswald">
                        Users
                      </h3>
                      <div>
                        <VChip
                          v-for="item in rep_code.users"
                          :key="item.id"
                          :label="false"
                          color="primary"
                          class="ma-1"
                        >
                          {{ item.first_name + ' ' + item.last_name }}
                        </VChip>
                      </div>
                    </VCardText>
                  </VCol>
                </VRow>
              </VCard>
            </VWindowItem>


          </VWindow>
        </VCol>
      </VRow>
    </div>
  </div>
</template>
