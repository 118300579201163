<script setup>
import { onMounted, ref } from "vue";
import axios from "@/utils/axios";
import { debounce } from "lodash";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
const $loading = useLoading(loaderData);
import ActivitiesTimeline from "@/components/activities/ActivitiesTimeline.vue";
import ActivityFilters from "@/components/activities/ActivityFilters.vue";

const errors = ref([]);
const activities = ref([]);
const activity_types = ref([]);
const selected_activity_type = ref("");
const users = ref([]);
const selected_user = ref("");
const user_roles = ref([]);
const selected_user_role = ref("");
const perPage = ref(10);
const page = ref(1);
const searchQuery = ref("");

const getActivities = async (page = 1, perPage = 10, searchQuery = "") => {
  let loader = $loading.show();
  let selected_activity_type_v = selected_activity_type.value !== null ? selected_activity_type.value : '';
  let selected_user_v = selected_user.value !== null ? selected_user.value : '';
  let selected_user_role_v = selected_user_role.value !== null ? selected_user_role.value : '';

  axios
    .get(
      `/activity_logs?page=${page}&per_page=${perPage}&search_query=${searchQuery}&activity_type=${selected_activity_type_v}&user=${selected_user_v}&user_role=${selected_user_role_v}`
    )
    .then((response) => {
      if (response.status === "success") {
        activities.value = response.data.activities;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

const getUserRoles = async () => {
  axios
    .get(`/get-all-roles`)
    .then((response) => {
      if (response.status === "success") {
        user_roles.value = Object.entries(response.data.roles).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getAllUsers = async () => {
  axios
    .get(`/get-all-users`)
    .then((response) => {
      if (response.status === "success") {
        users.value = Object.entries(response.data.users).map(
          ([index, value]) => {
            return {
              value: value.id,
              title: value.first_name + " " + value.last_name,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const getActivityTypes = async () => {
  axios
    .get(`/get-activity-types`)
    .then((response) => {
      if (response.status === "success") {
        activity_types.value = Object.entries(response.data.activity_types).map(
          ([index, value]) => {
            return {
              value: index,
              title: value,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// Page changed from pagination
const pageChanged = async (payload) => {
  page.value = payload;
  getActivities(payload, perPage.value, searchQuery.value);
};

// Get branches after 500 ms delay
const debouncedGetActivities = debounce(
  (page = 1, perPage = 10, searchQuery = "") => {
    getActivities(page, perPage, searchQuery);
  },
  500
);

const loadData = () => {
  getActivities();

  getUserRoles();
  getAllUsers();
  getActivityTypes();
};

// load users when page loads
onMounted(loadData);

// watch value of per page, if changed, make the API call
watch(perPage, (newValue, oldValue) => {
  getActivities(1, newValue, searchQuery.value);
});

watch(searchQuery, (newValue, oldValue) => {
  debouncedGetActivities(1, perPage.value, newValue);
});

watch(selected_activity_type, (newValue, oldValue) => {
  debouncedGetActivities(1, perPage.value, "");
});

watch(selected_user, (newValue, oldValue) => {
  debouncedGetActivities(1, perPage.value, "");
});

watch(selected_user_role, (newValue, oldValue) => {
  debouncedGetActivities(1, perPage.value, "");
});

// expose errors and getActivities
defineExpose({
  errors,
  getActivities,
});

const activityTypeChanged = async (payload) => {
  selected_activity_type.value = payload;
};
const userChanged = async (payload) => {
  selected_user.value = payload;
};
const userTypeChanged = async (payload) => {
  selected_user_role.value = payload;
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <section>
    <VCard title="" class="">
      <VCardText class="p-0" style="padding: 0 !important">
        <!-- Activity Filters Section -->
        <ActivityFilters
          :activity_types="activity_types"
          :users="users"
          :user_types="user_roles"
          :width="4"
          @activityTypeChanged="activityTypeChanged"
          @userChanged="userChanged"
          @userTypeChanged="userTypeChanged"
        />
        <VDivider />

        <div class="">
          <div class="d-flex flex-wrap py-3 px-5 gap-4">
            <div class="me-3 d-flex gap-3">
              <AppSelect
                :model-value="perPage"
                :items="[10, 25, 50, 100]"
                style="inline-size: 6.25rem"
                @update:model-value="perPage = parseInt($event, 10)"
                item-title="value"
                item-value="key"
              />
            </div>
            <VSpacer />
            <div
              class="app-user-search-filter d-flex align-center flex-wrap gap-4"
            >
              <!-- 👉 Search  -->
              <div style="inline-size: 14rem">
                <AppTextField
                  v-model="searchQuery"
                  placeholder="Search"
                  density="compact"
                />
              </div>
            </div>
          </div>
        <VDivider />
          <ActivitiesTimeline
            :table-data="activities"
            @page-changed="pageChanged"
            :page="page"
            :perPage="perPage"
          />
        </div>
      </VCardText>
    </VCard>
  </section>
</template>
