<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import { useRouter } from "vue-router";

const router = useRouter();
const $loading = useLoading(loaderData);
const route = useRoute("contacts-view-id");
const currentTab = ref(0);

const contactData = ref({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    notes: "",
    organization_id: "",
    branch_id: "",
    household_id: "",
    social_security_number: "",
    date_of_birth: '',
    values: [],
});

const getContactData = function () {
    let id = route.params.id;
    let loader = $loading.show();
    let url = `/contacts/${id}?show=all`;
    axios
        .get(url)
        .then((response) => {
            if (response.status == "success") {
                contactData.value = response.data.contact;
            } else {
                toast.error(response.message, {
                    autoClose: 2000,
                });
            }
        })
        .catch((error) => {
            loader.hide();
        })
        .finally(() => {
            loader.hide();
        });
}

onMounted(async () => {
    getContactData();
});

const discardChanges = () => {
    router.push({ name: "contacts-list" });
}

const editContact = () => {
    router.push({ name: "contacts-id", params: { id: contactData.id } });
}

const resolveIconForFieldType = (system_form_field) => {
    if (system_form_field.type == "single_line") {
        return "tabler-cursor-text";
    } else if (system_form_field.type == "multi_line") {
        return "tabler-forms";
    } else if (system_form_field.type == "integer") {
        return "tabler-123";
    } else if (system_form_field.type == "percent") {
        return "tabler-percentage";
    } else if (system_form_field.type == "decimal") {
        return "tabler-decimal";
    } else if (system_form_field.type == "currency") {
        return "tabler-cash";
    } else if (system_form_field.type == "date") {
        return "tabler-calendar-month";
    } else if (system_form_field.type == "date_time") {
        return "tabler-calendar-clock";
    } else if (system_form_field.type == "email") {
        return "tabler-mail";
    } else if (system_form_field.type == "phone") {
        return "tabler-phone";
    } else if (system_form_field.type == "pick_list") {
        return "tabler-checkup-list";
    } else if (system_form_field.type == "multi_select") {
        return "tabler-list-check";
    } else {
        return "";
    }
};

definePage({ meta: { requiresAuth: true } });
</script>

<template>
    <div>
        <div>
            <!-- 👉 Header  -->
            <div class="d-flex justify-space-between align-center flex-wrap gap-y-4 mb-6">
                <div>
                    <div class="d-flex gap-2 align-center mb-2 flex-wrap">
                        <h2>
                            <strong class="oswald">Contact Details</strong>
                        </h2>
                    </div>
                    <div>
                        <span class="lato-light">
                            {{ new Date(contactData.created_at).toLocaleString('en-US', {
                                dateStyle: 'long', timeStyle:
                                    'short'
                            }) }}
                        </span>
                    </div>
                </div>
                <div class="d-flex gap-4">
                    <VBtn variant="tonal" color="dark" @click="discardChanges">
                        Back
                    </VBtn>

                    <VBtn variant="tonal" color="primary" @click="editContact()">
                        Edit Contact
                    </VBtn>
                </div>
            </div>
            <!-- 👉 Customer Profile  -->
            <VRow>
                <VCol cols="12" md="5" lg="4">
                    <VRow>
                        <!-- SECTION Customer Details -->
                        <VCol cols="12">
                            <VCard>
                                <VCardText class="text-center pt-15">
                                    <!-- 👉 Avatar -->
                                    <VAvatar rounded :size="100" :color="'primary'" :variant="'tonal'">
                                        <!-- <VImg v-if="props.customerData.avatar" :src="props.customerData.avatar" /> -->
                                        <span class="text-5xl font-weight-medium">
                                            {{ contactData.first_name }}
                                            <!-- {{ avatarText(contactData.name) }} -->
                                        </span>
                                    </VAvatar>

                                    <!-- 👉 Customer fullName -->
                                    <h2 class="mt-4">
                                        <strong class="oswald">{{ contactData.first_name + " " + contactData.last_name }}</strong>
                                    </h2>
                                    <span class="text-sm lato-light">{{ contactData.email }}</span>
                                    
                                    
                                </VCardText>
                            </VCard>
                        </VCol>
                        <!-- !SECTION -->
                    </VRow>
                </VCol>
                <VCol cols="12" md="7" lg="8">
                    <VTabs class="v-tabs-pill mb-3 disable-tab-transition" v-model="currentTab">
                        <VTab :key="0">
                            <span>General</span>
                        </VTab>
                        <VTab :key="1">
                            <span>Additional</span>
                        </VTab>
                    </VTabs>
                    <VWindow class="disable-tab-transition" :touch="false" v-model="currentTab">
                        <VWindowItem>
                            <VCard class="pa-5">
                                <VRow class="parent">
                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Contact Type
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.type ? contactData.type.charAt(0).toUpperCase() + contactData.type.slice(1) : 'Individual' }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                First Name
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.first_name }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Last Name
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.last_name }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Phone
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.phone }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Email
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.email }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Social Security Number
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.social_security_number }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Date Of Birth
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.date_of_birth ? new Date(contactData.date_of_birth).toLocaleDateString('en-US', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                }) : '' }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Branch
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData?.branch?.branch_name }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Organization
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData?.organization?.name }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol class="child" cols="12" md="4">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Household
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData?.household?.name }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>
                                    <VCol class="child" cols="12" md="6">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Rep User
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData?.user?.first_name }} {{  contactData?.user?.last_name }}</span>
                                                <div>
                                                    <small>
                                                        <span class="text-primary lato-light">{{ contactData?.user?.email }}</span>
                                                    </small>
                                                </div>
                                            </div>
                                        </VCardText>
                                    </VCol>

                                    <VCol cols="12" md="12">
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                Notes
                                            </h3>
                                            <div>
                                                <span class="text-primary lato-light">{{ contactData.notes }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>
                                </VRow>
                            </VCard>
                        </VWindowItem>

                        <VWindowItem>
                            <VCard class="pa-5">
                                <VRow class="parent">
                                    <VCol class="child" cols="12" md="4" 
                                        v-for="ff in contactData.custom_fields"
                                        :key="ff.form_field.id"
                                    >
                                        <VCardText class="d-flex gap-y-2 flex-column ma-0 pa-2 text-body-1 ">
                                            <h3 class="oswald">
                                                {{ ff?.form_field?.name }}
                                            </h3>
                                            <div>
                                                <!-- {{ contactData }} -->
                                                <span class="text-primary lato-light">{{ Array.isArray(ff.value) ? ff.value.join(', ') : ff.value }}</span>
                                            </div>
                                        </VCardText>
                                    </VCol>
                                </VRow>
                            </VCard>
                        </VWindowItem>
                    </VWindow>
                </VCol>
            </VRow>
        </div>
    </div>
</template>
