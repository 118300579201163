<script setup>
import HouseholdForm from "@/components/households/HouseholdForm.vue";
import HouseholdsDataTable from "@/components/households/HouseholdsDataTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "@/utils/axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useLoading } from "vue-loading-overlay";
import loaderData from "@/utils/loader-data";
import DeleteConfirm from "@/components/common/DeleteConfirm.vue";
import HouseholdCustomFieldsForm from "@/components/households/HouseholdCustomFieldsForm.vue";

const $loading = useLoading(loaderData);

const errors = ref([]);
const headers = ref([
  {
    title: "Name",
    key: "name",
  },
  {
    title: "Description",
    key: "description",
    sortable: true,
  },
  {
    title: "Contacts",
    key: "contact",
    sortable: false,
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
  },
]);

const households = ref([]);
const openModal = ref(false);
const deleteDialog = ref(false);
const isCanSeeCreateBtn = ref(false);
const modeModal = ref("create");
const contacts = ref([]);

const filterInfo = ref({});
const editedItem = ref({});

const isCustomFieldsDialogVisible = ref(false)
const customFormFields = ref([])
const isCanSeeManageCustomFieldsBtn = ref(false);
const system_form_field_types = ref([])

const getCustomFormFields = () => {
  axios
    .get(`/custom_fields/households`)
    .then((response) => {
      if (response.status == "success") {
        customFormFields.value = response.data
      }
    })
    .catch((error) => {})
    .finally(() => {});
}

const getSystemFormFieldTypes = (search_query = "") => {
  axios
    .get(`/system_form_field_types`)
    .then((response) => {
      if (response.status == "success") {
        system_form_field_types.value = response.data.field_types
      }
    })
    .catch((error) => {})
    .finally(() => {});
};
const customFieldsUpdated = async () => {
  getCustomFormFields();
}
const openCustomFieldsModal = (value) => {
  isCustomFieldsDialogVisible.value = true;
};
// const canSeeManageCustomFieldsButton = 
const canSeeManageCustomFieldsButton = () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  let hasPermission = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Household Custom Fields")
  );
  if (hasPermission) {
    isCanSeeManageCustomFieldsBtn.value = true;
  } else {
    isCanSeeManageCustomFieldsBtn.value = false;
  }
};


const getContacts = (search_query = "") => {
  axios
    .get(`/contacts?search_query=${search_query}&all=1`)
    .then((response) => {
      if (response.status == "success") {
        contacts.value = response.data.contacts.map(
          (contact) => {
            return {
              title: contact.first_name + ' ' + contact.last_name,
              value: contact.id,
            };
          }
        );
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

const canSeeCreateBtn = async () => {
  const userData = useCookie("userData").value;
  const userPermissionsByRoles = userData.permissions_by_roles;
  isCanSeeCreateBtn.value = Object.values(userPermissionsByRoles).some(
    (permissions) => permissions.includes("Create Household")
  );
};

const openCreateModel = async () => {
  modeModal.value = "create";
  editedItem.value = {};
  openModal.value = true;
};

const getHouseholds = async (payload = null) => {
  let url = `/households?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload.searchQuery && payload.searchQuery.trim() !== "") {
    url += `&search_query=${encodeURIComponent(payload.searchQuery.trim())}`;
  }
  if (payload.sortBy && payload.orderBy) {
    url += `&sort_by=${payload.sortBy}&order_by=${payload.orderBy}`;
  }
  let loader = $loading.show();
  axios
    .get(url)
    .then((response) => {
      if (response.status == "success") {
        households.value = response.data.households;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {
      loader.hide();
    })
    .finally(() => {
      loader.hide();
    });
};

// Page changed from pagination
const tableChanged = async (payload) => {
  filterInfo.value = { ...filterInfo.value, ...payload };
  getHouseholds(filterInfo.value);
};

const searchValue = async (payload) => {
  filterInfo.value.searchQuery = payload;
  getHouseholds(filterInfo.value);
};

const refreshHouseholds = async () => {
  getHouseholds(filterInfo.value);
};

const editItem = (item) => {
  console.log('item', item)
  const { contacts, custom_fields, ...newItem } = item;
  editedItem.value = {
    ...newItem,
    contacts: contacts.map((itemTemp) => {
      return itemTemp.id
    }),
    values: toRaw(item?.custom_fields)
  }
  modeModal.value = "Update";
  openModal.value = true;
};

const deleteItem = (item) => {
  editedItem.value.id = item.id;
  deleteDialog.value = true;
};

const deleteItemConfirm = async () => {
  axios
    .delete("/households/" + `${editedItem.value.id}`)
    .then((response) => {
      if (response.status == "success") {
        toast.success(response.message, {
          autoClose: 2000,
        });
        deleteDialog.value = false;
        // refresh the users list via API
        getHouseholds(filterInfo.value);
      } else {
        toast.error(response.message, {
          autoClose: 2000,
        });
      }
    })
    .catch((error) => {})
    .finally(() => {});
};

// load contacts when page loads
onMounted(async () => {
  canSeeCreateBtn();
  getContacts();

  getSystemFormFieldTypes();
  canSeeManageCustomFieldsButton();
  getCustomFormFields();
});

// expose errors and getContacts
defineExpose({
  errors,
  getHouseholds,
});

definePage({ meta: { requiresAuth: true } });
</script>

<template>
  <VCard title="" class="">
    <VCardText class="p-0" style="padding: 0 !important">
      <VDivider />

      <!-- Households Table Section -->
      <HouseholdsDataTable
        :headers="headers"
        :table-data="households"
        :canSeeCreateBtn="isCanSeeCreateBtn"
        :canSeeManageCustomFieldsBtn="isCanSeeManageCustomFieldsBtn"
        @openCustomFieldsModal="openCustomFieldsModal"
        @tableChanged="tableChanged"
        @searchValue="searchValue"
        @isDialogVisible="openCreateModel"
        @editItem="editItem"
        @deleteItem="deleteItem"
      />
    </VCardText>
  </VCard>

  <!-- Household Create/Edit Modal -->
  <HouseholdForm
    v-model:isDialogVisible="openModal"
    :household-data="editedItem"
    :contacts="contacts"
    :mode="modeModal"
    :custom_fields="customFormFields"
    @refreshHouseholds="refreshHouseholds"
  />

  <!-- Household Delete Modal -->
  <DeleteConfirm
    v-model:deleteDialog="deleteDialog"
    @closeDelete="deleteDialog = false"
    @deleteItemConfirm="deleteItemConfirm"
  />

  <HouseholdCustomFieldsForm
    v-model:isDialogVisible="isCustomFieldsDialogVisible"
    :form-data="customFormFields"
    :system_form_field_types="system_form_field_types"
    @customFieldsUpdated="customFieldsUpdated"
  />


</template>
